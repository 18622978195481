import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalTemplate from "./modalTemplate";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase-config";
import { functions } from "../../firebase-config";
import { httpsCallable, getFunctions } from "firebase/functions";
import FileUpload from "../idVerification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faEdit,
  faSave,
  faBank,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";

const EnquiryModal = ({
  isModalOpen,
  closeModal,
  listingId,
  enquiryId,
  listingDetails,
  enquiryDetails,
  userDetails,
}) => {
  const ModalContent = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const totalPages = 5;
    const [stripeAccountId, setStripeAccountId] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [tosAccepted, setTosAccepted] = useState(false);
    const [rent, setRent] = useState(listingDetails?.rent || "");
    const [roomType, setRoomType] = useState(listingDetails?.roomType || "");
    const [startDate, setStartDate] = useState(
      new Date(enquiryDetails.startDate),
    );
    const [endDate, setEndDate] = useState(new Date(enquiryDetails.endDate));
    const [isError, setIsError] = useState(false);
    const [uploadedImage, setUploadedImage] = useState("");
    const [userData, setUserData] = useState({
      firstName: "",
      lastName: "",
      age: "",
      university: "",
      gender: "",
      instagram: "",
      nationality: "",
      email: "",
      phone: "",
    });
    const [stripeAccountData, setStripeAccountData] = useState({
      day: "",
      month: "",
      year: "",
      city: "",
      line1: "",
      postcode: "",
      state: "",
    });
    const [externalAccountData, setExternalAccountData] = useState({
      accountHolderName: "",
      accountNumber: "",
    });

    const toggleEditMode = () => {
      setIsEditMode(!isEditMode);
      setIsError(false);
    };

    const handleImageUpload = (base64String) => {
      setUploadedImage(base64String); // Update the state with the base64 string of the uploaded image
    };

    const isFirstFormValid = () => {
      return (
        dateOfBirth &&
        stripeAccountData.line1 &&
        stripeAccountData.city &&
        stripeAccountData.state &&
        stripeAccountData.postcode &&
        externalAccountData.accountHolderName &&
        externalAccountData.accountNumber &&
        externalAccountData.accountNumber.length === 22 &&
        tosAccepted
      );
    };

    const canSubmit = () => {
      return isFirstFormValid();
    };
    const isSecondFormValid = () => {
      return (
        externalAccountData.accountHolderName &&
        externalAccountData.accountNumber &&
        externalAccountData.accountNumber.length === 22
      );
    };
    const canSubmit2 = () => {
      return isSecondFormValid();
    };
    const sendEmail = async () => {
      console.log(userDetails.email);
      const functions = getFunctions();
      const sendOfferSentEmail = httpsCallable(functions, "sendOfferSentEmail");

      try {
        const result = await sendOfferSentEmail({
          email: userDetails.email,
          receivingUser: userDetails.firstName,
          sendingUser: userData.firstName + " " + userData.lastName,
        });
        console.log(result.data);
        console.log(result.data);
      } catch (error) {
        console.error(error);
      }
    };

    const handleModalSave = async () => {
      if (listingId && enquiryId) {
        setIsError(false);
        setIsLoading(true);
        // Format the dates to ISO string (YYYY-MM-DD)
        const formattedStartDate = startDate.toISOString().split("T")[0];
        const formattedEndDate = endDate.toISOString().split("T")[0];
        const accountIdToUse = stripeAccountId || userData?.accountId;

        // Update the enquiry document in the listings collection
        const enquiryDocRef = doc(
          db,
          "Listings",
          listingId,
          "enquiries",
          enquiryId,
        );
        await updateDoc(enquiryDocRef, {
          accountId: accountIdToUse, // Add accountId to the enquiry document
        });
        // Prepare the data for the Firebase Function
        const functionData = {
          startingDate: formattedStartDate,
          endingDate: formattedEndDate,
          mthRnt: parseFloat(rent), // Ensure rent is a number
          listingId,
          enquiryId,
        };
        // Call the function and handle the response
        const calculateSchedule = httpsCallable(functions, "calculateschdule");
        try {
          const response = await calculateSchedule(functionData);
          console.log("Rent schedule calculated:", response.data);

          // Update the enquiry in Firestore
          const enquiryDocRef = doc(
            db,
            "Listings",
            listingId,
            "enquiries",
            enquiryId,
          );
          await updateDoc(enquiryDocRef, {
            rentSchedule: response.data.schdule, // Update with the calculated schedule
            rent: rent,
            roomType: roomType,
            isActive: true,
            startDate: formattedStartDate, // Update the startDate
            endDate: formattedEndDate, // Update the endDate
            offerSent: true, // Mark the offer as sent
          });
          sendEmail();
        } catch (error) {
          console.error("Error calculating rent schedule:", error);
        }
        // Introduce a delay before reloading the window
        setTimeout(() => {
          window.location.reload();
          closeModal();
          setIsLoading(false); // Stop loading
        }, 3000); // Delay in milliseconds (2000 milliseconds = 2 seconds)
      } else {
        // Handle any errors or validations here
        setIsLoading(false); // Make sure to stop loading if there's an error or validation fails
      }
    };

    const handleStripeAccountSubmit = async (event) => {
      event.preventDefault();
      if (!tosAccepted) {
        alert("You must accept the Terms of Service.");
        return;
      }
      setIsLoading(true); // Start loading
      const combinedData = {
        ...stripeAccountData,
        ...userData,
      };
      try {
        // Create Stripe Account
        const createStripeAccount = httpsCallable(
          functions,
          "createStripeAccount",
        );
        const accountResult = await createStripeAccount(combinedData);

        if (accountResult.data.error) {
          console.error("Error from function:", accountResult.data.error);
        } else {
          setStripeAccountId(accountResult.data.accountId);

          // Submit ToS Acceptance
          const ipResponse = await fetch("https://api.ipify.org?format=json");
          const ipData = await ipResponse.json();
          const tosDate = Math.floor(Date.now() / 1000);

          const updateTosAcceptance = httpsCallable(
            functions,
            "updateTosAcceptance",
          );
          await updateTosAcceptance({
            accountId: accountResult.data.accountId,
            tosDate: tosDate,
            ipAddress: ipData.ip,
          });

          const createExternalAccount = httpsCallable(
            functions,
            "createExternalAccount",
          );
          await createExternalAccount({
            ...externalAccountData,
            accountId: accountResult.data.accountId, // Assuming this is the ID of the Stripe account
          });

          const currentUser = auth.currentUser;
          if (currentUser) {
            const userDocRef = doc(db, "users", currentUser.uid);
            await updateDoc(userDocRef, {
              accountId: accountResult.data.accountId,
            });
          }
          nextPage();
          console.log(
            "Stripe Account created and ToS accepted successfully and external account added.",
          );
        }
      } catch (error) {
        console.error("Function call failed:", error);
      }
      setIsLoading(false); // Stop loading in case of error
    };

    const handleExternalAccountSubmit = async (event) => {
      event.preventDefault();
      setIsLoading(true); // Start loading
      const combinedData = {
        ...stripeAccountData,
        ...userData,
      };
      try {
        const createExternalAccount = httpsCallable(
          functions,
          "createExternalAccount",
        );
        await createExternalAccount({
          ...externalAccountData,
          accountId: combinedData.accountId, // Assuming this is the ID of the Stripe account
        });
        if (listingId && enquiryId) {
          // Update the enquiry document in the listings collection
          const enquiryDocRef = doc(
            db,
            "Listings",
            listingId,
            "enquiries",
            enquiryId,
          );
          await updateDoc(enquiryDocRef, {
            accountId: combinedData.accountId, // Add accountId to the enquiry document
          });
        }
        setCurrentPage(3);
        console.log("external account added.");
      } catch (error) {
        console.error("Function call failed:", error);
      }
      setIsLoading(false); // Stop loading in case of error
    };

    const handleDateOfBirthChange = (e) => {
      const newDate = e.target.value; // format: "YYYY-MM-DD"
      setDateOfBirth(newDate);

      const [year, month, day] = newDate.split("-");
      setStripeAccountData({
        ...stripeAccountData,
        day: parseInt(day, 10),
        month: parseInt(month, 10),
        year: parseInt(year, 10),
      });
    };

    useEffect(() => {
      // Fetch user data from Firestore when the user is logged in
      const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
        if (currentUser) {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            setUserData(userDocSnap.data());
          } else {
            console.log("No user data found!");
          }
        }
      });
      return () => unsubscribe();
    }, []);

    const handleExternalAccountInputChange = (e) => {
      setExternalAccountData({
        ...externalAccountData,
        [e.target.name]: e.target.value,
      });
    };

    const handleStripeAccountInputChange = (e) => {
      const { name, value } = e.target;
      const isNumberField =
        name === "day" || name === "month" || name === "year";
      const updatedValue = isNumberField && value ? parseInt(value, 10) : value;

      setStripeAccountData({ ...stripeAccountData, [name]: updatedValue });
    };

    const prevPage = () => {
      setCurrentPage(currentPage - 1);
    };

    const nextPage = () => {
      if (isEditMode) {
        setIsError(true);
        return; // Prevents saving when in edit mode
      }
      setCurrentPage(currentPage + 1);
    };

    const renderProgressBar = () => {
      let pageHeader = "";
      switch (currentPage) {
        case 0:
          pageHeader = "Edit Offer";
          break;
        case 1:
          pageHeader = "Details to get Paid";
          break;
        case 2:
          pageHeader = "Id Verification";
          break;
        case 3:
          pageHeader = "Send Offer";
          break;
        default:
          pageHeader = "";
          break;
      }
      return (
        <div className="mb-4">
          <div className="mb-1 text-center text-lg">{pageHeader}</div>
          <div className="h-2 w-full rounded-full bg-gray-200">
            <div
              className="h-2 rounded-full bg-purple-600"
              style={{ width: `${((currentPage + 1) / totalPages) * 100}%` }}
            ></div>
          </div>
        </div>
      );
    };

    const renderPage = () => {
      switch (currentPage) {
        case 0:
          return (
            <div>
              <div className="mb-4">
                <div className="mb-4 flex flex-row">
                  <div className="text-[15px]">
                    <p>
                      This is the offer you are sending. Once you send the
                      offer, the other person will be able to accept the offer
                      and give payment details to allow the rent to be
                      transferred to you!
                    </p>
                  </div>
                </div>
              </div>
              <div className="rounded-lg border border-gray-200 p-2 shadow-md md:p-4">
                {isError && (
                  <p className="flex items-center justify-center text-red-500">
                    Please save your changes
                  </p>
                )}
                <div className="flex items-start justify-between">
                  <div className="flex-grow">
                    {" "}
                    {/* This div is to take up space and push the button to the right */}
                    {/* Maybe some other content here, if necessary */}
                  </div>{" "}
                  <button
                    onClick={toggleEditMode}
                    className={`text-sm ${
                      isEditMode
                        ? "bg-purple-600 text-white"
                        : "bg-gray-200 text-gray-700"
                    } rounded p-1 md:p-2`}
                  >
                    {isEditMode ? (
                      <FontAwesomeIcon icon={faSave} />
                    ) : (
                      <FontAwesomeIcon icon={faEdit} />
                    )}
                    {isEditMode ? " Save Changes" : " Edit"}
                  </button>
                </div>

                <div className="grid grid-cols-2 md:mx-10">
                  <div className="items-left flex flex-col">
                    <p className="text-[12px] font-semibold text-gray-700 md:text-[15px]">
                      Room
                    </p>

                    <span className="mb-2 font-semibold md:text-xl">
                      {roomType}
                    </span>
                  </div>
                  <div className="items-left ml-4 flex  flex-col">
                    <p className="text-[12px] font-semibold text-gray-700 md:text-[15px]">
                      Monthly Rent
                    </p>
                    {isEditMode ? (
                      <input
                        type="text"
                        value={rent}
                        onChange={(e) => setRent(e.target.value)}
                        className="cursor-pointer rounded-md border border-gray-300 p-2"
                      />
                    ) : (
                      <span className="mb-2 font-semibold md:text-xl">
                        €{rent}
                      </span>
                    )}
                  </div>

                  <div className="items-left flex flex-col">
                    <p className="text-[12px] font-semibold text-gray-700 md:text-[15px]">
                      Move in
                    </p>
                    {isEditMode ? (
                      <DatePicker
                        minDate={new Date()}
                        showPopperArrow={false}
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          if (date > endDate) {
                            setEndDate(date); // Set end date to match start date if it's earlier
                          }
                        }}
                        className="form-input w-full cursor-pointer rounded-md border border-gray-300 p-2"
                        dateFormat="MMM d, yyyy"
                      />
                    ) : (
                      <span className="mb-2 font-semibold md:text-xl">
                        {startDate.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    )}
                  </div>
                  <div className="items-left ml-4 flex flex-col">
                    <p className="text-[12px] font-semibold text-gray-700 md:text-[15px]">
                      Move out
                    </p>
                    {isEditMode ? (
                      <DatePicker
                        minDate={new Date()}
                        showPopperArrow={false}
                        selected={endDate}
                        onChange={(date) => {
                          setEndDate(date);
                          if (date < startDate) {
                            setStartDate(date); // Set start date to match end date if it's later
                          }
                        }}
                        className="form-input w-full cursor-pointer rounded-md border border-gray-300 p-2"
                        dateFormat="MMM d, yyyy"
                      />
                    ) : (
                      <span className="mb-2 font-semibold md:text-xl">
                        {endDate.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        case 1:
          if (userData?.accountId) {
            return (
              <>
                <div
                  style={{
                    boxShadow: "inset 0 -6px 6px -1px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div className="h-[300px] overflow-y-auto">
                    <p className="ml-2 text-[14px]">
                      You already have a payments account. If you want to change
                      your bank details, update them below, if not just press
                      next.
                    </p>
                    <p className="text-md mt-2 flex justify-center font-bold text-gray-700">
                      Bank Account Details
                    </p>
                    <div className="mb-2 rounded border border-gray-300 bg-gray-100 p-4 shadow-md">
                      <div className="items-left flex flex-col">
                        <p className="text-sm font-semibold text-gray-700">
                          Account Holder Name*
                        </p>
                        <input
                          type="text"
                          name="accountHolderName"
                          value={externalAccountData.accountHolderName}
                          onChange={handleExternalAccountInputChange}
                          placeholder="Account Holder Name"
                          className="mb-3 w-full rounded border border-gray-300 px-2 py-1"
                        />
                      </div>
                      <div className="items-left flex flex-col">
                        <p className="text-sm font-semibold text-gray-700">
                          IBAN*
                        </p>
                        <input
                          type="text"
                          name="accountNumber"
                          value={externalAccountData.accountNumber}
                          onChange={handleExternalAccountInputChange}
                          placeholder="Enter your IBAN"
                          className="mb-1 w-full rounded border border-gray-300 px-2 py-1"
                          maxLength={22}
                        />
                      </div>{" "}
                    </div>
                    <button
                      type="button"
                      onClick={handleExternalAccountSubmit}
                      className={`${
                        canSubmit2()
                          ? "bg-purple-500 hover:bg-purple-600"
                          : "cursor-not-allowed bg-gray-300"
                      } rounded px-4 py-2 text-white`}
                      disabled={!canSubmit2()}
                    >
                      {isLoading ? (
                        <>
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="mr-2 animate-spin"
                          />{" "}
                          Updating...
                        </>
                      ) : (
                        "Update Bank Details"
                      )}
                    </button>
                  </div>
                </div>
              </>
            );
          } else {
            return (
              <>
                <div
                  style={{
                    boxShadow: "inset 0 -6px 6px -1px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div className="h-[300px] overflow-y-auto md:w-[474px]">
                    <div className="mx-2">
                      <form className="">
                        <p>
                          The following information is required so you can get
                          paid!
                        </p>
                        <div className="items-left flex flex-col">
                          <p className="mt-2 text-sm font-semibold text-gray-700">
                            Date of Birth*
                          </p>
                          <input
                            required
                            type="date"
                            name="dateOfBirth"
                            value={dateOfBirth}
                            onChange={handleDateOfBirthChange}
                            className="w-full rounded border border-gray-300 bg-white px-3 py-1"
                            placeholder="Date of Birth"
                          />
                        </div>
                        <div className="items-left flex flex-col">
                          <p className="mt-2 text-sm font-semibold text-gray-700">
                            Street Address*
                          </p>
                          <input
                            required
                            type="text"
                            name="line1"
                            value={stripeAccountData.line1}
                            onChange={handleStripeAccountInputChange}
                            placeholder="Street Address"
                            className="w-full rounded border border-gray-300 px-3 py-1"
                          />
                        </div>
                        <div className="items-left flex flex-col">
                          <p className="mt-2 text-sm font-semibold text-gray-700">
                            Town/City*
                          </p>
                          <input
                            required
                            type="text"
                            name="city"
                            value={stripeAccountData.city}
                            onChange={handleStripeAccountInputChange}
                            placeholder="City"
                            className="w-full rounded border border-gray-300 px-3 py-1"
                          />
                        </div>
                        <div className="items-left flex flex-col">
                          <p className="mt-2 text-sm font-semibold text-gray-700">
                            County*
                          </p>
                          <select
                            required
                            name="state"
                            className="w-full cursor-pointer rounded border border-gray-300 bg-white px-3 py-1"
                            value={stripeAccountData.state}
                            onChange={handleStripeAccountInputChange}
                          >
                            <option value="">Select County</option>
                            <option value="Co. Antrim">Antrim</option>
                            <option value="Co. Armagh">Armagh</option>
                            <option value="Co. Carlow">Carlow</option>
                            <option value="Co. Cavan">Cavan</option>
                            <option value="Co. Clare">Clare</option>
                            <option value="Co. Cork">Cork</option>
                            <option value="Co. Derry">Derry</option>
                            <option value="Co. Donegal">Donegal</option>
                            <option value="Co. Down">Down</option>
                            <option value="Co. Dublin">Dublin</option>
                            <option value="Co. Fermanagh">Fermanagh</option>
                            <option value="Co. Galway">Galway</option>
                            <option value="Co. Kerry">Kerry</option>
                            <option value="Co. Kildare">Kildare</option>
                            <option value="Co. Kilkenny">Kilkenny</option>
                            <option value="Co. Laois">Laois</option>
                            <option value="Co. Leitrim">Leitrim</option>
                            <option value="Co. Limerick">Limerick</option>
                            <option value="Co. Longford">Longford</option>
                            <option value="Co. Louth">Louth</option>
                            <option value="Co. Mayo">Mayo</option>
                            <option value="Co. Meath">Meath</option>
                            <option value="Co. Monaghan">Monaghan</option>
                            <option value="Co. Offaly">Offaly</option>
                            <option value="Co. Roscommon">Roscommon</option>
                            <option value="Co. Sligo">Sligo</option>
                            <option value="Co. Tipperary">Tipperary</option>
                            <option value="Co. Tyrone">Tyrone</option>
                            <option value="Co. Waterford">Waterford</option>
                            <option value="Co. Westmeath">Westmeath</option>
                            <option value="Co. Wexford">Wexford</option>
                            <option value="Co. Wicklow">Wicklow</option>
                          </select>
                        </div>
                        <div className="items-left flex flex-col">
                          <p className="mt-2 text-sm font-semibold text-gray-700">
                            Eircode*
                          </p>
                          <input
                            required
                            type="text"
                            name="postcode"
                            value={stripeAccountData.postcode}
                            onChange={handleStripeAccountInputChange}
                            placeholder="Eircode"
                            className="w-full rounded border border-gray-300 px-3 py-1"
                          />
                        </div>
                        <p className="text-md mt-2 flex justify-center font-bold text-gray-700">
                          Bank Account Details
                        </p>
                        <div className="mb-2 rounded border border-gray-300 bg-gray-100 p-4 shadow-md">
                          <div className="items-left flex flex-col">
                            <p className="text-sm font-semibold text-gray-700">
                              Account Holder Name*
                            </p>
                            <input
                              type="text"
                              name="accountHolderName"
                              value={externalAccountData.accountHolderName}
                              onChange={handleExternalAccountInputChange}
                              placeholder="Account Holder Name"
                              className="mb-3 w-full rounded border border-gray-300 px-2 py-1"
                            />
                          </div>
                          <div className="items-left flex flex-col">
                            <p className="text-sm font-semibold text-gray-700">
                              IBAN*
                            </p>
                            <input
                              type="text"
                              name="accountNumber"
                              value={externalAccountData.accountNumber}
                              onChange={handleExternalAccountInputChange}
                              placeholder="Enter your IBAN"
                              className="mb-3 w-full rounded border border-gray-300 px-2 py-1"
                              maxLength={22}
                            />
                          </div>
                          <div className="mb-2 ml-2 flex items-center">
                            <input
                              type="checkbox"
                              checked={tosAccepted}
                              onChange={(e) => setTosAccepted(e.target.checked)}
                              className="mr-2 cursor-pointer"
                            />
                            <span>
                              I accept the{" "}
                              <a
                                href="https://stripe.com/connect-account/legal"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:text-blue-800"
                              >
                                Terms of Service
                              </a>
                              *
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            );
          }

        case 2:
          return (
            <>
              <div className="relative pb-24">
                <p className="text-lg">
                  Upload a photo of your ID here so we can verify your identity.
                </p>
                <FileUpload
                  stripeAccountId={stripeAccountId}
                  nextPage={nextPage}
                />
              </div>
            </>
          );
        case 3:
          return (
            <>
              <p className="ml-2 text-green-500">
                Congratulations! You have successfully set up your account to
                accept rent payments.
              </p>
              <p className="ml-2">
                To send the following offer click the button below
              </p>
              <div className="mt-8 rounded-lg border border-gray-200 p-2 shadow-md md:p-4">
                <div className="grid grid-cols-2 md:mx-10">
                  <div className="items-left flex flex-col">
                    <p className="md:text-md mt-2 text-sm font-semibold text-gray-700">
                      Room
                    </p>

                    <span className="mb-2 font-semibold md:text-xl">
                      {roomType}
                    </span>
                  </div>
                  <div className="items-left flex flex-col">
                    <p className="md:text-md mt-2 text-sm font-semibold text-gray-700">
                      Monthly Rent
                    </p>

                    <span className="mb-2 font-semibold md:text-xl">
                      €{rent}
                    </span>
                  </div>
                  <div className="items-left flex flex-col">
                    <p className="md:text-md text-sm font-semibold text-gray-700">
                      Move in
                    </p>

                    <span className="mb-2 font-semibold md:text-xl">
                      {startDate.toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                  <div className="items-left flex flex-col">
                    <p className="md:text-md text-sm font-semibold text-gray-700">
                      Move out
                    </p>

                    <span className="mb-2 font-semibold md:text-xl">
                      {endDate.toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                </div>
              </div>
            </>
          );
      }
    };

    return (
      <div className="m-4 h-[420px] md:w-[480px]">
        {" "}
        {/* Tailwind classes for fixed height and scrollable content */}
        <div className="">
          {renderProgressBar()}
          <div className="h-[300px] px-1 md:w-[474px]">{renderPage()}</div>
          <div className="mt-6 flex items-center justify-between">
            {currentPage === 1 ? (
              <button
                type="button"
                onClick={prevPage}
                className="rounded bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300"
              >
                Previous
              </button>
            ) : (
              <div className="px-4 py-2"></div>
            )}
            {currentPage < 1 && (
              <button
                type="button"
                onClick={nextPage}
                className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
              >
                Next
              </button>
            )}
            {currentPage === 1 && userData?.accountId ? (
              // Button for users who already have an accountId
              <button
                type="button"
                onClick={() => setCurrentPage(3)} // Assuming you want to move them forward
                className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
              >
                Next
              </button>
            ) : currentPage === 1 ? (
              // Original button for users who need to submit details
              <button
                type="button"
                onClick={handleStripeAccountSubmit}
                className={`${
                  canSubmit()
                    ? "bg-purple-500 hover:bg-purple-600"
                    : "cursor-not-allowed bg-gray-300"
                } rounded px-4 py-2 text-white`}
                disabled={!canSubmit() || isLoading} // Disable the button if cannot submit or is loading
              >
                {isLoading ? (
                  <>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="mr-2 animate-spin"
                    />{" "}
                    Submitting...
                  </>
                ) : (
                  "Submit Details"
                )}
              </button>
            ) : null}

            {currentPage === 3 && (
              <button
                disabled={isLoading} // Disable the button if isLoading is true
                type="button"
                onClick={() => {
                  setIsLoading(true); // Set isLoading to true right when the button is clicked
                  handleModalSave(); // Call your handleModalSave function
                }}
                className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
              >
                {isLoading ? (
                  <>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="mr-2 animate-spin"
                    />{" "}
                    Sending Offer...
                  </>
                ) : (
                  "Send Offer"
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalTemplate
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      content={<ModalContent />}
      width="md:w-auto w-[95%]"
    />
  );
};
export default EnquiryModal;
