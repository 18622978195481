import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../firebase-config";
import {
  doc,
  getDoc,
  addDoc,
  collection,
  writeBatch,
  getDocs,
  updateDoc,
  query,
  where,
  runTransaction,
} from "firebase/firestore";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const UpdateCustomerCard = ({}) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [errors, setErrors] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [failedPaymentData, setFailedPaymentData] = useState(null); // New state variable for storing the failed payment data
  const [hasFailedPayment, setHasFailedPayment] = useState(false); // State to track if there's a failed payment

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && db) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            console.log("User data:", userData); // Debugging
            setUserData(userData);
            const failedPaymentsRef = collection(db, "paymentsFailed");
            const querySnapshot = await getDocs(
              query(
                failedPaymentsRef,
                where("customerId", "==", userData.customerId),
              ),
            );

            querySnapshot.forEach((doc) => {
              // Store both the document data and its reference
              setFailedPaymentData({ id: doc.id, ...doc.data() });
              console.log("Failed payment data:", doc.data());
            });
            setHasFailedPayment(!querySnapshot.empty);
          } else {
            console.log("No user data found for this userID");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      }
    };

    fetchUserData();
  }, [user, db]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    setErrors(""); // Reset errors when moving to next page
    console.log("this is user data", userData.email);
  };

  console.log(failedPaymentData);
  const updatePaymentStatus = async (subletId, paymentIndex) => {
    const subletDocRef = doc(db, "Sublets", subletId);

    try {
      await runTransaction(db, async (transaction) => {
        const subletDoc = await transaction.get(subletDocRef);
        if (!subletDoc.exists()) {
          throw new Error("Document does not exist!");
        }

        const subletData = subletDoc.data();
        const updatedRentSchedule = subletData.rentSchedule.map((payment) => {
          if (payment.index === paymentIndex) {
            return { ...payment, status: "completed" }; // Update the status to "completed"
          }
          return payment;
        });

        transaction.update(subletDocRef, { rentSchedule: updatedRentSchedule });
      });
      console.log("Rent schedule status updated successfully.");
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors("");
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: userData.email,
      },
    });

    if (error) {
      console.log("[error]", error);
      setIsLoading(false);
      setErrors("error try again");
      return;
    }

    // Construct the data object for the function call
    let functionData = {
      email: userData.email,
      paymentMethodId: paymentMethod.id,
      name: userData.firstName + " " + userData.lastName,
      failedPayment: hasFailedPayment,
      customerId: userData.customerId, // Use customerId if available, else default to ''
    };

    // Add conditional parameters only if failedPaymentData exists
    if (failedPaymentData) {
      functionData.amount = failedPaymentData.rent || 0; // Use rent if available, else default to 0
      functionData.accountId = failedPaymentData.accountId || ""; // Use accountId if available, else default to ''
    }

    // Call Firebase function
    const updateCardDetails = httpsCallable(functions, "updateCardDetails");
    updateCardDetails(functionData)
      .then(async (result) => {
        console.log(result);
        setIsLoading(false);
        setErrors("");

        // Check if we have failed payment data and its ID
        if (failedPaymentData && failedPaymentData.id) {
          const batch = writeBatch(db);

          // Add to paymentsCompleted collection
          const completedPaymentRef = doc(collection(db, "paymentsCompleted"));
          batch.set(completedPaymentRef, { ...failedPaymentData });

          // Delete from paymentsFailed collection
          const failedPaymentRef = doc(
            db,
            "paymentsFailed",
            failedPaymentData.id,
          );
          batch.delete(failedPaymentRef);

          // Commit the batch
          await batch.commit();
          console.log(
            "Payment moved to paymentsCompleted and removed from paymentsFailed.",
          );

          const subletId = failedPaymentData.subletId; // Example retrieval from failedPaymentData
          const paymentIndex = failedPaymentData.index; // Example retrieval

          // Call updatePaymentStatus after successfully processing the payment
          await updatePaymentStatus(subletId, paymentIndex);
        }

        nextPage();
      })
      .catch((error) => {
        // Handle errors
        setErrors("error try again");
        console.error("Error processing payment", error);
        setIsLoading(false);
      });
  };

  const renderPage = () => {
    switch (currentPage) {
      case 0:
        let paymentDetails = null;
        if (
          hasFailedPayment &&
          failedPaymentData &&
          failedPaymentData.originalDate
        ) {
          const formattedMonth = new Date(
            failedPaymentData.originalDate,
          ).toLocaleString("default", { month: "long" });
          paymentDetails = (
            <>
              <p>
                The card saved will also be used to pay the rent due as shown
                below
              </p>
              <p>
                Payment Details: Month <strong>{formattedMonth}</strong> Amount{" "}
                <strong>€{failedPaymentData?.rent}</strong>
              </p>
            </>
          );
        }

        return (
          <>
            <div>
              <p className="text-center text-3xl font-extrabold text-gray-900">
                Update Card
              </p>
              <div className="mb-4 flex flex-row">
                <div className="ml-4 text-[14px]">
                  <p>
                    The card details you enter will be saved as the default card
                    for future payments
                  </p>
                  {paymentDetails}
                </div>
              </div>
              <form className="rounded-md border border-gray-300 p-4 shadow-md">
                {errors && (
                  <p className="text-center text-sm text-red-500">{errors}</p>
                )}
                <div className="items-left flex flex-col">
                  <p className="text-sm font-semibold text-gray-700">
                    Card Number
                  </p>
                  <div className="rounded-md border border-gray-300 bg-white p-2">
                    <CardNumberElement />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <div className="items-left flex flex-col">
                    <p className="text-sm font-semibold text-gray-700">
                      Expiry Date
                    </p>
                    <div className="rounded-md border border-gray-300 bg-white p-2">
                      <CardExpiryElement />
                    </div>
                  </div>
                  <div className="items-left flex flex-col">
                    <p className="text-sm font-semibold text-gray-700">CVC</p>
                    <div className="rounded-md border border-gray-300 bg-white p-2">
                      <CardCvcElement />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h2 className="text-center text-3xl font-extrabold text-green-500">
              Succesful!
            </h2>
            <p className="text-center">
              You're card has been successfully updated and the rent due has
              been paid in full.
            </p>
          </>
        );
    }
  };

  return (
    <div className=" flex items-center justify-center ">
      <div className="mb-48 mt-16 h-auto w-[90%] rounded-lg border border-gray-300 bg-white p-6 shadow-lg md:w-[500px]">
        <div className="relative pb-16">
          <div className="space-y-4 ">
            {renderPage()}
            <div className="absolute bottom-0 w-full">
              <div className="mt-6 flex items-center justify-end">
                {currentPage === 0 && (
                  <button
                    disabled={!stripe || isLoading} // Disable the button if stripe hasn't loaded or if isLoading is true
                    type="button"
                    onClick={handleSubmit}
                    className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
                  >
                    {isLoading ? (
                      <>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="mr-2 animate-spin"
                        />{" "}
                        Submitting...
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                )}
                {currentPage === 1 && (
                  <button
                    type="button"
                    onClick={() => navigate("/profile")}
                    className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
                  >
                    {isLoading ? (
                      <>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="mr-2 animate-spin"
                        />{" "}
                        Returning to profile...
                      </>
                    ) : (
                      "Return to profile"
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCustomerCard;
