import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import PersonalProfile from "../components/profilecard";
import Chat from "../components/chat";
import "react-datepicker/dist/react-datepicker.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";
import EnquiryModal from "../components/modals/sendfinaloffermodal";
import Tooltip from "../components/tooltip";

const EnquirerDetailsComponent = () => {
  const { userId, listingId, enquiryId } = useParams();
  const [user] = useAuthState(auth);
  const [enquiryDetails, setEnquiryDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rent, setRent] = useState("");
  const [listingDetails, setListingDetails] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isError, setIsError] = useState(false);

  // Fetch enquiry and user data
  useEffect(() => {
    const fetchEnquiryDetails = async () => {
      if (listingId && enquiryId) {
        // Fetch enquiry data
        const enquiryDocRef = doc(
          db,
          "Listings",
          listingId,
          "enquiries",
          enquiryId,
        );
        const enquirySnapshot = await getDoc(enquiryDocRef);

        if (enquirySnapshot.exists()) {
          setEnquiryDetails(enquirySnapshot.data());
        } else {
          console.log("No such enquiry!");
        }
      }
      if (listingId) {
        const listingDocRef = doc(db, "Listings", listingId);
        const listingSnapshot = await getDoc(listingDocRef);
        if (listingSnapshot.exists()) {
          setListingDetails(listingSnapshot.data());
        } else {
          console.log("No such listing!");
        }
      }
      if (userId) {
        // Fetch user data
        const userDocRef = doc(db, "users", userId);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          setUserDetails({ id: userSnapshot.id, ...userSnapshot.data() });
        } else {
          console.log("No such user!");
        }
      }
    };

    fetchEnquiryDetails();
  }, [userId, listingId, enquiryId]);

  const handleModalToggle = () => {
    if (!isModalOpen) {
      // Set rent to the values from listingDetails when opening the modal
      setRent(listingDetails?.rent || "");
      setStartDate(new Date(listingDetails.startDate));
      setEndDate(new Date(listingDetails.endDate));
    }
    setIsError(false);
    setIsModalOpen(!isModalOpen);
  };

  if (!userDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-auto mb-24 mt-16 flex h-full w-[90%] flex-col md:p-5">
      <h1 className="mb-1 text-3xl font-semibold md:mb-4">Request Details</h1>
      <div className="mb-5 flex flex-col gap-5 sm:flex-row">
        <div className="sm:w-1/2">
          <PersonalProfile user={userDetails} />
        </div>

        <div className="relative flex-1 rounded-lg border border-gray-400 bg-white p-3 pb-14 shadow-md sm:w-1/2 md:pb-12">
          {enquiryDetails?.offerSent && (
            <div className="absolute right-2 top-2 rounded bg-green-500 px-3 py-1 text-white">
              Offer Sent
            </div>
          )}{" "}
          <div className="flex items-center space-x-2">
            <p className="text-2xl font-extrabold">Request </p>
            <Tooltip tooltipText="These are the dates that the tenant has requested stay. Before you send the final offer you can edit the dates and the rent" />
          </div>
          <div className="flex-2">
            <div className="mx-2 mb-2 grid grid-cols-2 gap-2 md:mx-8">
              <div>
                <p className="mt-2 text-[15px] font-semibold text-gray-700">
                  Room
                </p>
                <p className="text-xl font-semibold">
                  {" "}
                  {listingDetails?.roomType}
                </p>
              </div>
              <div className="ml-4">
                <p className="mt-2 text-[15px] font-semibold text-gray-700">
                  Monthly Rent
                </p>
                <p className="text-xl font-semibold">
                  {" "}
                  €{listingDetails?.rent}
                </p>
              </div>

              <div>
                <p className="text-[15px] font-semibold text-gray-700">
                  Move in
                </p>
                <p className="mb-2 text-xl font-semibold">
                  {" "}
                  {enquiryDetails?.startDate &&
                    new Date(enquiryDetails.startDate).toLocaleDateString(
                      "en-US",
                      { month: "short", day: "2-digit", year: "numeric" },
                    )}
                </p>
              </div>
              <div className="ml-4">
                <p className="text-[15px] font-semibold text-gray-700">
                  Move out
                </p>
                <p className="mb-2 text-xl font-semibold">
                  {" "}
                  {enquiryDetails?.endDate &&
                    new Date(enquiryDetails.endDate).toLocaleDateString(
                      "en-US",
                      { month: "short", day: "2-digit", year: "numeric" },
                    )}
                </p>
              </div>
            </div>

            {/* Button at the bottom-right */}
            <div className="absolute bottom-4 right-4">
              <button
                className="flex rounded bg-purple-500 px-4 py-2 font-semibold text-white hover:bg-purple-600"
                onClick={handleModalToggle}
              >
                Edit and send final offer
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Chat Box */}
      <div>
        <Chat
          listingId={listingId}
          enquiryId={enquiryId}
          currentUser={user.uid}
          otherUser={userDetails}
        />
        {/* Chat functionality can be expanded here */}
      </div>
      {/* Modal */}
      <EnquiryModal
        isModalOpen={isModalOpen}
        closeModal={handleModalToggle}
        rent={rent}
        setRent={setRent}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        isError={isError}
        enquiryId={enquiryId}
        listingId={listingId}
        listingDetails={listingDetails}
        enquiryDetails={enquiryDetails}
        userDetails={userDetails}
      />
    </div>
  );
};

export default EnquirerDetailsComponent;
