import React, { useState, useEffect } from "react";
import { db } from "../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";
import ImageCarousel from "../components/imagecarousel";
import PersonalProfile from "../components/profilecard";
import Chat from "../components/chat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faHouse,
  faBed,
  faMoneyBill,
  faCalendar,
  faChevronDown,
  faUser,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { Timestamp } from "firebase/firestore"; // Import Timestamp from Firestore

const CurrentLet = () => {
  const [user, loading, error] = useAuthState(auth);
  const [subletDetails, setSubletDetails] = useState(null);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { subletId, listerId, userId } = useParams();

  console.log(subletId, listerId, userId);

  useEffect(() => {
    const fetchSubletAndTenantDetails = async () => {
      setIsLoading(true);
      if (user) {
        // Fetch sublet details using subletId from URL params
        const subletDocRef = doc(db, "Sublets", subletId);
        const subletDocSnap = await getDoc(subletDocRef);

        if (subletDocSnap.exists()) {
          const subletData = { id: subletDocSnap.id, ...subletDocSnap.data() };
          setSubletDetails(subletData);

          // Fetch tenant details using tenantId from sublet document
          if (subletData.userId) {
            const tenantDocRef = doc(db, "users", subletData.userId);
            const tenantDocSnap = await getDoc(tenantDocRef);
            if (tenantDocSnap.exists()) {
              setTenantDetails(tenantDocSnap.data());
            } else {
              console.log("Tenant details not found.");
            }
          }
        } else {
          console.log("No sublet found with the given ID.");
        }
      }
      setIsLoading(false);
    };
    fetchSubletAndTenantDetails();
  }, [user, subletId]);

  console.log(userId);
  console.log(tenantDetails);

  // Add CSS classes for different status backgrounds
  const getStatusColor = (status) => {
    switch (status) {
      case "upcoming":
        return "bg-gray-400 rounded text-white font-semibold"; // Green background for 'upcoming'
      case "pending":
        return "bg-yellow-500 text-white font-semibold"; // Yellow background for 'pending'
      case "completed":
        return "bg-green-500 text-white font-semibold"; // Green background for 'completed'
      default:
        return ""; // Default background color
    }
  };

  const formatDate = (dateInput) => {
    let date;
    if (typeof dateInput === "string") {
      // If the date is a string, directly parse it
      date = new Date(dateInput);
    } else if (dateInput instanceof Timestamp) {
      // If the date is a Firestore Timestamp, convert to JavaScript Date object
      date = dateInput.toDate();
    } else {
      // If the date is already a JavaScript Date object or another type, use it directly
      date = dateInput;
    }

    // Now format the date into a readable string
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const Accordion = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div className="accordion">
        <div
          className="accordion-header mt-4 cursor-pointer text-center"
          onClick={toggleAccordion}
        >
          <h3>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="mr-2 mt-2 w-6 text-gray-500"
            />
          </h3>
        </div>
        {isOpen && <div className="accordion-content">{children}</div>}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="mt-40 py-6 text-center text-2xl font-semibold text-gray-700">
        Loading...
      </div>
    ); // Show loading state
  }

  if (!user) {
    return (
      <div className="container mx-auto mb-64 mt-16 w-[90%] md:mt-24 md:w-[80%]">
        {" "}
        <h2 className="text-3xl font-semibold">My Current Stay</h2>
        <div className="mt-24 py-6 text-center text-2xl font-semibold text-gray-700">
          Please log in to be able to post and see your current stay.
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto mb-24 mt-16 flex h-full w-[90%] flex-col md:mt-24 md:w-[80%]">
      <h1 className="mb-4 text-xl font-semibold md:text-3xl">My Current Let</h1>{" "}
      {/* Display sublet details */}
      {isLoading ? (
        <div className="py-6 text-center text-2xl font-semibold text-gray-700">
          Loading...
        </div> // Your loading icon or component
      ) : subletDetails === null ? (
        <div className="text-center text-lg">
          <p>You currently have no Sublet</p>
          <p>Accept an offer from the enquiries page to start your sublet.</p>
        </div>
      ) : (
        <div>
          <div className="card rounded-lg border border-gray-200 bg-white shadow-md">
            <div className="hidden md:block">
              <div className="flex">
                <div className=" mx-2 mt-2 w-1/3 items-center overflow-hidden p-2">
                  <ImageCarousel images={subletDetails?.imageUrls || []} />
                </div>
                <div className="mx-8 w-2/3 p-4">
                  <h3 className="mb-4 text-3xl font-bold">
                    {subletDetails?.title}
                  </h3>
                  <div className="">
                    <p className="text-xl">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="mr-2 mt-2 w-6 text-xl text-gray-500"
                      />{" "}
                      {subletDetails?.streetAddress}, {subletDetails.city},{" "}
                      {subletDetails.county}, {subletDetails.postalCode}
                    </p>
                    <div className="mx-8 mt-2 grid grid-cols-2 text-lg">
                      <p className="mb-2">
                        <FontAwesomeIcon
                          icon={faHouse}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        {subletDetails.propertyType}
                      </p>
                      <p className="mb-2">
                        {" "}
                        <FontAwesomeIcon
                          icon={faBed}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        {subletDetails.roomType}
                      </p>
                      <p className="mb-2">
                        <FontAwesomeIcon
                          icon={faMoneyBill}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        €{subletDetails.rent}/month
                      </p>
                      <p className="mb-2">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        {subletDetails?.startDate &&
                          new Date(subletDetails.startDate).toLocaleDateString(
                            "en-US",
                            { month: "short", day: "2-digit" },
                          )}{" "}
                        -{" "}
                        {subletDetails?.endDate &&
                          new Date(subletDetails.endDate).toLocaleDateString(
                            "en-US",
                            { month: "short", day: "2-digit" },
                          )}
                      </p>
                    </div>
                  </div>
                  <Accordion>
                    <div className="mx-8 my-4 grid grid-cols-2 text-lg">
                      <p>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        {subletDetails?.maleTenants} males
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        {subletDetails?.femaleTenants} females
                      </p>
                    </div>
                    <p className="mx-8 text-lg">
                      <FontAwesomeIcon
                        icon={faFileLines}
                        className="mr-2 mt-2 w-6 text-gray-500"
                      />{" "}
                      {subletDetails?.description}
                    </p>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="md:hidden">
              <div className="flex flex-col">
                <h3 className="m-2 text-xl font-bold">
                  {subletDetails?.title}
                </h3>
                <div className="w-full items-center overflow-hidden px-2 pb-2">
                  <ImageCarousel images={subletDetails?.imageUrls || []} />
                </div>
                <div className="w-full p-2">
                  <div className="">
                    <p className="text-lg">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="mr-2 w-6 text-lg text-gray-500"
                      />{" "}
                      {subletDetails?.streetAddress}, {subletDetails.city},{" "}
                      {subletDetails.county}, {subletDetails.postalCode}
                    </p>
                    <div className="ml-4 mt-2 grid grid-cols-2">
                      <p className="mb-2">
                        <FontAwesomeIcon
                          icon={faHouse}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        {subletDetails.propertyType}
                      </p>
                      <p className="mb-2">
                        {" "}
                        <FontAwesomeIcon
                          icon={faBed}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        {subletDetails.roomType}
                      </p>
                      <p className="mb-2">
                        <FontAwesomeIcon
                          icon={faMoneyBill}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        €{subletDetails.rent}/month
                      </p>
                      <p className="mb-2">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />{" "}
                        {subletDetails?.startDate &&
                          new Date(subletDetails.startDate).toLocaleDateString(
                            "en-US",
                            { month: "short", day: "2-digit" },
                          )}{" "}
                        -{" "}
                        {subletDetails?.endDate &&
                          new Date(subletDetails.endDate).toLocaleDateString(
                            "en-US",
                            { month: "short", day: "2-digit" },
                          )}
                      </p>
                    </div>
                  </div>
                  <Accordion>
                    <div className="my-4 ml-4 grid grid-cols-2">
                      <p>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />
                        {subletDetails?.maleTenants} males{" "}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="mr-2 mt-2 w-6 text-gray-500"
                        />
                        {subletDetails?.femaleTenants} females
                      </p>
                    </div>
                    <p className="ml-4">
                      <FontAwesomeIcon
                        icon={faFileLines}
                        className="mr-2 mt-2 w-6 text-gray-500"
                      />
                      {subletDetails?.description}
                    </p>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 grid gap-4 md:grid-cols-2">
            <div className="rounded-lg border border-gray-200 p-4 shadow-md">
              <p className="mb-3 text-xl font-semibold">Notifications</p>
              {subletDetails &&
              subletDetails.notifications &&
              subletDetails.notifications.length > 0 ? (
                <ul className="max-h-32 space-y-2 overflow-auto">
                  {subletDetails.notifications.map((notification, index) => (
                    <li
                      key={index}
                      className="mr-2 rounded-md border border-gray-200 bg-gray-50 p-3 shadow"
                    >
                      <p className="text-sm text-gray-500">
                        {formatDate(notification.date)}
                      </p>
                      <p className="text-gray-700">{notification.message}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-center text-lg text-gray-500">
                  No notifications yet
                </p>
              )}
            </div>

            {tenantDetails && <PersonalProfile user={tenantDetails} />}
          </div>
          <div className="mt-4 rounded-lg border border-gray-300 shadow">
            <table className="w-full table-fixed overflow-hidden rounded-lg text-left rtl:text-right">
              <thead>
                <tr className="border-b bg-purple-500 text-xl text-white">
                  <th className="pl-8">Date</th>
                  <th className="py-4">Amount</th>
                  <th className="py-4">Status</th>
                </tr>
              </thead>
              <tbody>
                {subletDetails.rentSchedule.map((item, index) => (
                  <tr key={index} className="border-b bg-white text-lg">
                    <td className="pl-8">
                      <p className="">
                        {item?.date &&
                          new Date(item.date).toLocaleDateString("en-US", {
                            month: "short",
                            day: "2-digit",
                          })}
                      </p>
                    </td>
                    <td className="py-4">
                      <p className="">€{item.rent.toFixed(2)}</p>
                    </td>
                    <td className="text-center">
                      {" "}
                      {/* Apply dynamic CSS class */}
                      <div
                        className={`w-24 rounded py-1 ${getStatusColor(
                          item.status,
                        )}`}
                      >
                        {item.status}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            <Chat
              currentUser={listerId}
              subletId={subletDetails.id}
              otherUser={tenantDetails}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentLet;
