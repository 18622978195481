import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { auth, db } from "../firebase-config";
import { functions } from "../firebase-config";
import { httpsCallable } from "firebase/functions";
import ListCustomers from "../components/unused/listcustomers";
import PaymentComponent from "../components/unused/chargecustomer";
import { getFunctions } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import FileUpload from "../components/idVerification";
import PaymentForm from "../components/unused/transferfunds";
import SinglePageModal from "../components/modals/modalTemplate";
console.log(functions);

const stripePromise = loadStripe(
  "pk_test_51O3czXKxgc2qiaBtAHRNuIK0QjCUYFkXCU1MlYZi2jmXgCXWdOWs5u19Ix0QA8Qql4KMWQR8VReJOUBLzFAyzwjI00C8Txxqk4",
);

export default function Practicepage() {
  const [editMode, setEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationComplete, setVerificationComplete] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState(null);
  const [file, setFile] = useState(null);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    university: "",
    gender: "",
    instagram: "",
    nationality: "",
    email: "",
  });
  const [stripeAccountData, setStripeAccountData] = useState({
    phone: "",
    day: "",
    month: "",
    year: "",
    city: "",
    line1: "",
    postcode: "",
    state: "",
  });
  const [externalAccountData, setExternalAccountData] = useState({
    accountHolderName: "",
    accountNumber: "",
  });

  const modalContent = (
    <div>
      <h2>Modal Title</h2>
      <p>This is the content of the modal.</p>
      {/* You can include any JSX content here */}
    </div>
  );
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleExternalAccountInputChange = (e) => {
    setExternalAccountData({
      ...externalAccountData,
      [e.target.name]: e.target.value,
    });
  };

  const handleStripeAccountInputChange = (e) => {
    const { name, value } = e.target;
    const isNumberField = name === "day" || name === "month" || name === "year";
    const updatedValue = isNumberField && value ? parseInt(value, 10) : value;

    setStripeAccountData({ ...stripeAccountData, [name]: updatedValue });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("file", file);

    // Replace with the URL of your `uploadFileToStripe` function
    const response = await fetch("/uploadFileToStripe", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    return data.fileId;
  };

  const verifyIdentity = async () => {
    setIsVerifying(true);

    try {
      const createVerificationSession = httpsCallable(
        functions,
        "createVerificationSession",
      );
      const result = await createVerificationSession({
        connectedAccountId: "acct_1OYATJ4Kp1nUb9Mt",
        accountId: "acct_1OYATJ4Kp1nUb9Mt",
      });
      const sessionId = result.data.sessionId;
      const accountId = result.data.accountId;

      const stripe = await stripePromise;
      console.log("Session ID:", sessionId);
      console.log("account ID", accountId);
      await stripe.verifyIdentity(sessionId);

      setVerificationComplete(true);
    } catch (error) {
      console.error("Error during identity verification:", error);
    } finally {
      setIsVerifying(false);
    }
  };

  const fetchTodayPayments = async () => {
    const today = new Date();
    // Format the date in 'YYYY-MM-DD' to match the format stored in Firestore
    const formattedToday = today.toISOString().split("T")[0];

    try {
      const paymentsCollectionRef = collection(db, "payments");
      const q = query(
        paymentsCollectionRef,
        where("date", "==", formattedToday),
      );
      const querySnapshot = await getDocs(q);

      const paymentsToday = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        paymentsToday.push({ id: doc.id, ...doc.data() });
      });
      console.log(formattedToday);
      console.log("payments", paymentsToday);
      return paymentsToday;
    } catch (error) {
      console.error("Error fetching payments: ", error);
    }
  };
  fetchTodayPayments();

  useEffect(() => {
    // Fetch user data from Firestore when the user is logged in
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
        } else {
          console.log("No user data found!");
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const toggleEditMode = async () => {
    if (editMode && auth.currentUser) {
      // Save the edited data back to Firestore
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userDocRef, userData);
    }
    setEditMode(!editMode);
  };

  const tosSubmit = async () => {
    try {
      // Fetch the user's IP address
      const ipResponse = await fetch("https://api.ipify.org?format=json");
      const ipData = await ipResponse.json();

      // Call the Firebase function
      const updateTosAcceptance = httpsCallable(
        functions,
        "updateTosAcceptance",
      );
      const tosDate = Math.floor(Date.now() / 1000); // Current Unix timestamp

      await updateTosAcceptance({
        accountId: "acct_1OYATJ4Kp1nUb9Mt",
        tosDate: tosDate,
        ipAddress: ipData.ip,
      });

      alert("Terms of Service accepted successfully.");
    } catch (error) {
      console.error("Error updating ToS acceptance:", error);
      alert("Failed to update ToS acceptance.");
    }
  };

  const handleAddBankAccountSubmit = async (event) => {
    event.preventDefault();
    try {
      const createExternalAccount = httpsCallable(
        functions,
        "createExternalAccount",
      );
      const result = await createExternalAccount({
        ...externalAccountData,
        accountId: stripeAccountId, // Assuming this is the ID of the Stripe account
      });
      console.log("External Account Added:", result.data);
      alert("Bank account added successfully.");
    } catch (error) {
      console.error("Error adding bank account:", error);
      alert("Failed to add bank account.");
    }
  };
  const handleStripeAccountSubmit = async (event) => {
    event.preventDefault();
    if (!tosAccepted) {
      alert("You must accept the Terms of Service.");
      return;
    }
    const combinedData = {
      ...stripeAccountData,
      ...userData,
      phone: stripeAccountData.phone || userData.phone,

      // Overwrite any overlapping fields from userData to stripeAccountData if needed
      // For example:
      // email: userData.email || stripeAccountData.email,
    };
    // Call the createStripeAccount function with stripeAccountData
    try {
      // Create Stripe Account
      const createStripeAccount = httpsCallable(
        functions,
        "createStripeAccount",
      );
      const accountResult = await createStripeAccount(combinedData);

      if (accountResult.data.error) {
        console.error("Error from function:", accountResult.data.error);
      } else {
        setStripeAccountId(accountResult.data.accountId);

        // Submit ToS Acceptance
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        const tosDate = Math.floor(Date.now() / 1000);

        const updateTosAcceptance = httpsCallable(
          functions,
          "updateTosAcceptance",
        );
        await updateTosAcceptance({
          accountId: accountResult.data.accountId,
          tosDate: tosDate,
          ipAddress: ipData.ip,
        });

        const createExternalAccount = httpsCallable(
          functions,
          "createExternalAccount",
        );
        await createExternalAccount({
          ...externalAccountData,
          accountId: accountResult.data.accountId, // Assuming this is the ID of the Stripe account
        });
        alert(
          "Stripe Account created and ToS accepted successfully and external account added.",
        );
      }
    } catch (error) {
      console.error("Function call failed:", error);
    }
  };

  const handleDateOfBirthChange = (e) => {
    const newDate = e.target.value; // format: "YYYY-MM-DD"
    setDateOfBirth(newDate);

    const [year, month, day] = newDate.split("-");
    setStripeAccountData({
      ...stripeAccountData,
      day: parseInt(day, 10),
      month: parseInt(month, 10),
      year: parseInt(year, 10),
    });
  };

  return (
    <section className=" bg-gray-100 py-5">
      <div className="container mx-32 mb-24 mt-24 px-4">
        <h1 className="mb-5 text-3xl font-bold">Profile Page</h1>
        <div className="-mx-4 flex flex-wrap">
          {/* Left Column */}
          <div className="mb-4 w-1/4 px-4">
            {/* User Card */}
            <div className="rounded-lg bg-white px-5 py-6 text-center shadow">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                alt="avatar"
                className="mx-auto mb-4 w-32 rounded-full"
              />
              <h5 className="mb-2 text-xl font-medium leading-tight">
                John Doe
              </h5>
              <p className="text-gray-500">Full Stack Developer</p>
            </div>
          </div>
          {/* Right Column */}
          <div className="w-2/4 px-4">
            <div className="mb-4 rounded-lg bg-white px-5 py-6 shadow">
              <div className="flex items-center justify-between">
                <h5 className="mb-4 text-xl font-medium leading-tight">
                  About
                </h5>
                <button
                  onClick={toggleEditMode}
                  className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                >
                  {editMode ? "Save" : "Edit"}
                </button>
              </div>
              <div className="space-y-3">
                {editMode ? (
                  <>
                    {/* Editable fields */}
                    <input
                      type="text"
                      name="firstName"
                      value={userData.firstName}
                      onChange={handleInputChange}
                      className="w-full rounded border border-gray-300 px-2 py-1"
                      placeholder="First Name"
                    />
                    <input
                      type="text"
                      name="lastName"
                      value={userData.lastName}
                      onChange={handleInputChange}
                      className="w-full rounded border border-gray-300 px-2 py-1"
                      placeholder="Last Name"
                    />
                    <input
                      type="text"
                      name="gender"
                      value={userData.gender}
                      onChange={handleInputChange}
                      className="w-full rounded border border-gray-300 px-2 py-1"
                      placeholder="gender"
                    />
                    <input
                      type="text"
                      name="age"
                      value={userData.age}
                      onChange={handleInputChange}
                      className="w-full rounded border border-gray-300 px-2 py-1"
                      placeholder="age"
                    />
                    <input
                      type="text"
                      name="university"
                      value={userData.university}
                      onChange={handleInputChange}
                      className="w-full rounded border border-gray-300 px-2 py-1"
                      placeholder="university"
                    />
                    <input
                      type="text"
                      name="nationality"
                      value={userData.nationality}
                      onChange={handleInputChange}
                      className="w-full rounded border border-gray-300 px-2 py-1"
                      placeholder="Nationality"
                    />
                    <input
                      type="text"
                      name="instagram"
                      value={userData.instagram}
                      onChange={handleInputChange}
                      className="w-full rounded border border-gray-300 px-2 py-1"
                      placeholder="Instagram"
                    />
                    {/* Repeat for other fields */}
                  </>
                ) : (
                  <>
                    {/* Display fields */}
                    <p>
                      <strong>First Name:</strong> {userData.firstName}
                    </p>
                    <p>
                      <strong>Last Name:</strong> {userData.lastName}
                    </p>
                    <p>
                      <strong>Gender:</strong> {userData.gender}
                    </p>
                    <p>
                      <strong>Age:</strong> {userData.age}
                    </p>
                    <p>
                      <strong>university:</strong> {userData.university}
                    </p>
                    <p>
                      <strong>Nationality:</strong> {userData.nationality}
                    </p>
                    <p>
                      <strong>Instagram:</strong> {userData.instagram}
                    </p>
                    <div>
                      {/* Your sign-up form here */}
                      <form
                        onSubmit={handleStripeAccountSubmit}
                        className="rounded border border-gray-200 bg-white p-6 shadow-md"
                      >
                        <div className="mb-4 flex flex-row items-center space-x-4">
                          <label>Phone</label>
                          <input
                            type="text"
                            name="phone"
                            value={stripeAccountData.phone}
                            onChange={handleStripeAccountInputChange}
                            placeholder="Phone"
                            className="w-full rounded border border-gray-300 px-3 py-2"
                          />
                        </div>
                        <div className="mb-4 flex flex-row items-center space-x-4">
                          <label>Date of Birth</label>
                          <input
                            type="date"
                            name="dateOfBirth"
                            value={dateOfBirth}
                            onChange={handleDateOfBirthChange}
                            className="w-full rounded border border-gray-300 px-3 py-2"
                            placeholder="Date of Birth"
                          />
                        </div>
                        <div className="mb-4 flex flex-row items-center space-x-4">
                          <label>Street Address</label>
                          <input
                            type="text"
                            name="line1"
                            value={stripeAccountData.line1}
                            onChange={handleStripeAccountInputChange}
                            placeholder="Street Address"
                            className="w-full rounded border border-gray-300 px-3 py-2"
                          />
                        </div>
                        <div className="mb-4 flex flex-row items-center space-x-4">
                          <label>City</label>
                          <input
                            type="text"
                            name="city"
                            value={stripeAccountData.city}
                            onChange={handleStripeAccountInputChange}
                            placeholder="City"
                            className="w-full rounded border border-gray-300 px-3 py-2"
                          />
                        </div>
                        <div className="mb-4 flex flex-row items-center space-x-4">
                          <label>County</label>
                          <input
                            type="text"
                            name="state"
                            value={stripeAccountData.state}
                            onChange={handleStripeAccountInputChange}
                            placeholder="County"
                            className="w-full rounded border border-gray-300 px-3 py-2"
                          />
                        </div>
                        <div className="mb-4 flex flex-row items-center space-x-4">
                          <label>Eircode</label>
                          <input
                            type="text"
                            name="postcode"
                            value={stripeAccountData.postcode}
                            onChange={handleStripeAccountInputChange}
                            placeholder="Eircode"
                            className="w-full rounded border border-gray-300 px-3 py-2"
                          />
                        </div>
                        <div className="mb-4 flex flex-row items-center space-x-4">
                          <label>Account Holder Name</label>
                          <input
                            type="text"
                            name="accountHolderName"
                            value={externalAccountData.accountHolderName}
                            onChange={handleExternalAccountInputChange}
                            placeholder="Account Holder Name"
                            className="mb-3 w-full rounded border border-gray-300 px-2 py-1"
                          />
                        </div>{" "}
                        <div className="mb-4 flex flex-row items-center space-x-4">
                          <label>Account No.</label>
                          <input
                            type="text"
                            name="accountNumber"
                            value={externalAccountData.accountNumber}
                            onChange={handleExternalAccountInputChange}
                            placeholder="Account Number"
                            className="mb-3 w-full rounded border border-gray-300 px-2 py-1"
                          />
                        </div>
                        <div className="mb-4 flex items-center">
                          <input
                            type="checkbox"
                            checked={tosAccepted}
                            onChange={(e) => setTosAccepted(e.target.checked)}
                            className="mr-2"
                          />
                          <label>I accept the Terms of Service</label>
                        </div>
                        <button
                          type="submit"
                          className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                        >
                          Create Stripe Account
                        </button>
                      </form>

                      {stripeAccountId && (
                        <p>Stripe Account ID: {stripeAccountId}</p>
                      )}
                    </div>

                    <FileUpload stripeAccountId={stripeAccountId} />

                    <div>
                      <p className="my-24">p</p>
                    </div>
                    {/* Repeat for other fields */}
                    <ListCustomers />
                    <PaymentComponent />

                    <button onClick={tosSubmit}>Submit TOS</button>
                    <button
                      onClick={verifyIdentity}
                      disabled={isVerifying || verificationComplete}
                    >
                      {isVerifying ? "Verifying..." : "Verify Identity"}
                    </button>
                    {verificationComplete && (
                      <p>Verification complete. Thank you!</p>
                    )}

                    <PaymentForm />
                    <button onClick={() => setIsModalOpen(true)}>
                      Open Modal
                    </button>

                    <SinglePageModal
                      isOpen={isModalOpen}
                      onClose={handleModalClose}
                      content={modalContent}
                    />
                  </>
                )}
              </div>
            </div>

            {/* ... (Rest of the Right Column) ... */}
          </div>
        </div>
      </div>
      <div className="mx-auto w-[90%] rounded border border-gray-300 px-2 py-1">
        <h2>You have a new message!</h2>
        <p>Hi [yourname]</p>
        <p>
          [name] just sent you a new message. Please log in to your account to
          view the message
        </p>
        <a href="https://lettz.ie">
          <button className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600">
            Visit Lettz
          </button>
        </a>
      </div>
    </section>
  );
}
