import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const PaymentForm = () => {
  const [customerId, setCustomerId] = useState("");
  const [connectedAccountId, setConnectedAccountId] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const createDestinationCharge = httpsCallable(
      functions,
      "createDestinationCharge",
    );
    try {
      const result = await createDestinationCharge({
        customerId: customerId,
        connectedAccountId: connectedAccountId,
        amount: parseInt(amount, 10) * 100, // Convert to cents
      });

      console.log("Charge successful:", result);
      setStatus("Charge created successfully!");
    } catch (error) {
      console.error("Error creating destination charge:", error);
      setStatus("Error creating charge.");
    }
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-md">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <input
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
            type="text"
            placeholder="Customer ID"
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value)}
          />
        </div>
        <div>
          <input
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500"
            type="text"
            placeholder="Connected Account ID"
            value={connectedAccountId}
            onChange={(e) => setConnectedAccountId(e.target.value)}
          />
        </div>
        <div>
          <input
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            type="number"
            placeholder="Amount (in Euros)"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="focus:shadow-outline w-full rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-600 focus:outline-none"
        >
          Create Charge
        </button>
      </form>
      {status && <p className="mt-4 text-center font-medium">{status}</p>}
    </div>
  );
};

export default PaymentForm;
