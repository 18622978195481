import React from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";

const DateDropdown = ({
  startDate,
  endDate,
  startFlexibility,
  endFlexibility,
  setStartDate,
  setStartFlexibility,
  setEndDate,
  setEndFlexibility,
  handleDateChange,
  resetDates,
  closeDropdown
}) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 text-xs md:text-[15px]">
      <div className="grid grid-cols-4 items-center mb-4">
        <label className="font-semibold text-gray-700">Start Date</label>
        <div className="relative">
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
          <DatePicker
            minDate={new Date()}
            showPopperArrow={false}
            selected={startDate}
            onChange={(date) => handleDateChange(setStartDate, date)}
            dateFormat="MMM d"
            className="form-input w-full pl-10 pr-3 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-400 focus:border-transparent"
          />
        </div>
        <p className="ml-8 flex items-center">
          Flexibility ±
          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 text-gray-500" />
        </p>
        <input
          type="number"
          value={startFlexibility}
          onChange={(e) => handleDateChange(setStartFlexibility, Number(e.target.value))}
          className="form-input w-16 rounded-md border border-gray-300 px-2 py-1"
          min="0"
          max="52"
        />
      </div>

      <div className="grid grid-cols-4 items-center mb-4">
        <label className="font-semibold text-gray-700">End Date</label>
        <div className="relative">
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
          <DatePicker
            minDate={new Date()}
            showPopperArrow={false}
            selected={endDate}
            onChange={(date) => handleDateChange(setEndDate, date)}
            dateFormat="MMM d"
            className="form-input w-full pl-10 pr-3 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-400 focus:border-transparent"
          />
        </div>
        <p className="ml-8 flex items-center">
          Flexibility ±
          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 text-gray-500" />
        </p>
        <input
          type="number"
          value={endFlexibility}
          onChange={(e) => handleDateChange(setEndFlexibility, Number(e.target.value))}
          className="form-input w-16 rounded-md border border-gray-300 px-2 py-1"
          min="0"
          max="52"
        />
      </div>

      <div className="flex justify-between space-x-2">
        <button
          onClick={resetDates}
          className="w-[100%] rounded-md bg-gray-200 p-2 text-gray-700 hover:bg-gray-300 transition-all duration-200"
        >
          Clear Dates
        </button>
        <button
          onClick={closeDropdown}
          className="w-[100%] rounded-md bg-purple-500 p-2 text-white hover:bg-purple-600 transition-all duration-200"
        >
          Search Dates
        </button>
      </div>
    </div>
  );
};

export default DateDropdown;
