import React from "react";
import ModalTemplate from "../modalTemplate";

const UserModal = ({ isModalOpen, closeModal, user }) => {
  const modalContent = (
    <div className="h-[500px] overflow-y-scroll">
      <pre className="scroll overflow-y-auto">
        {JSON.stringify(user, null, 2)}
      </pre>
      <button
        onClick={closeModal}
        className="mt-4 rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
      >
        Close
      </button>
    </div>
  );

  return (
    <ModalTemplate
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      content={modalContent}
      width="w-[90%] md:w-[800px]"
    />
  );
};

export default UserModal;
