import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";
import { Link } from "react-router-dom";
import ImageCarousel from "../components/imagecarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faHouse,
  faBed,
  faMoneyBill,
  faCalendar,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const UserEnquiries = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [user, loading, error] = useAuthState(auth); // Modified to include loading and error
  const [isLoading, setIsLoading] = useState(true);
  const [isEnquiryDeleteModalOpen, setIsEnquiryDeleteModalOpen] =
    useState(false);
  const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
  const [selectedListingIdForEnquiry, setSelectedListingIdForEnquiry] =
    useState(null);

  // Fetch the enquiries relevant to the user along with the listing details relevant to this enquiry and unread messages
  useEffect(() => {
    const fetchEnquiries = async () => {
      setIsLoading(true);
      if (!user) return;

      try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        const userEnquiries = userDoc.exists()
          ? userDoc.data().enquiries || []
          : [];

        const validEnquiries = [];
        for (const enquiry of userEnquiries) {
          const enquiryDocRef = doc(
            db,
            "Listings",
            enquiry.listingId,
            "enquiries",
            enquiry.enquiryId,
          );
          const enquiryDoc = await getDoc(enquiryDocRef);

          if (enquiryDoc.exists()) {
            const listingDocRef = doc(db, "Listings", enquiry.listingId);
            const listingDoc = await getDoc(listingDocRef);

            if (listingDoc.exists()) {
              const enquiryData = {
                enquiryId: enquiry.enquiryId,
                listingId: enquiry.listingId,
                enquiryData: enquiryDoc.data(),
                listingData: listingDoc.data(),
              };
              const messagesSnapshot = await getDocs(
                collection(
                  db,
                  "Listings",
                  enquiry.listingId,
                  "enquiries",
                  enquiry.enquiryId,
                  "messages",
                ),
              );
              const unreadCount = messagesSnapshot.docs.filter(
                (doc) => !doc.data().read && doc.data().userId !== user.uid,
              ).length;
              enquiryData.unreadMessagesCount = unreadCount;
              validEnquiries.push(enquiryData);
            }
          }
        }

        setEnquiries(validEnquiries);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching enquiries: ", error);
        setIsLoading(false);
      }
    };

    fetchEnquiries();
  }, [user]);

  const handleEnquiryDeleteClick = (listingId, enquiryId) => {
    setSelectedListingIdForEnquiry(listingId);
    setSelectedEnquiryId(enquiryId);
    window.scrollTo(0, 0);
    setIsEnquiryDeleteModalOpen(true);
  };

  const deleteEnquiry = async () => {
    setIsLoading(true);

    try {
      // Assuming `user` is the state holding the logged-in user's info, and it has been set correctly elsewhere in your component.
      const userId = user.uid; // Get the logged-in user's ID

      // Delete all messages in the enquiry
      const messagesSnapshot = await getDocs(
        collection(
          db,
          "Listings",
          selectedListingIdForEnquiry,
          "enquiries",
          selectedEnquiryId,
          "messages",
        ),
      );
      for (const messageDoc of messagesSnapshot.docs) {
        await deleteDoc(
          doc(
            db,
            "Listings",
            selectedListingIdForEnquiry,
            "enquiries",
            selectedEnquiryId,
            "messages",
            messageDoc.id,
          ),
        );
      }

      // Delete the enquiry document
      await deleteDoc(
        doc(
          db,
          "Listings",
          selectedListingIdForEnquiry,
          "enquiries",
          selectedEnquiryId,
        ),
      );

      // Retrieve the user document to update the enquiries array
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // Filter out the enquiry to be deleted
        const updatedEnquiries = userData.enquiries.filter(
          (enquiry) => enquiry.enquiryId !== selectedEnquiryId,
        );
        // Update the user document with the new enquiries array
        await updateDoc(userDocRef, { enquiries: updatedEnquiries });
      }

      // Optionally, update the local state to reflect the deletion
      // For example, setSublets or a similar state variable to reflect the current state of enquiries
    } catch (error) {
      console.error("Error deleting enquiry:", error);
    }

    setIsEnquiryDeleteModalOpen(false);
    setIsLoading(false);
  };

  if (loading) {
    return (
      <div className="mt-40 py-6 text-center text-2xl font-semibold text-gray-700">
        Loading...
      </div>
    ); // Show loading state
  }

  if (!user) {
    return (
      <div className="mx-auto mb-64 mt-16 w-[90%] md:mt-20 md:w-[80%]">
        {" "}
        <h2 className="text-3xl font-semibold">My Enquiries</h2>
        <div className="mt-24 py-6 text-center text-2xl font-semibold text-gray-700">
          Please sign in to see your enquiries
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto mb-24 mt-16 grid w-[90%] grid-cols-1 gap-4 md:mt-20 md:w-[80%] md:grid-cols-1 lg:grid-cols-1">
      <h2 className="text-3xl font-semibold">My Enquiries</h2>
      {isLoading ? (
        <div className="mt-24 py-6 text-center text-2xl font-semibold text-gray-700">
          Loading...
        </div> // Your loading icon or component
      ) : enquiries.length === 0 ? (
        <div className="mt-24 text-center text-lg">
          <p>You currently have no enquiries</p>
          <p>Go to the browse page to request a stay</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6">
          {enquiries.map(
            ({
              enquiryId,
              enquiryData,
              listingData,
              listingId,
              unreadMessagesCount,
            }) => (
              <div
                key={enquiryId}
                className="card relative rounded-lg border border-gray-200 bg-white shadow-lg"
              >
                <div className="hidden md:block">
                  <button
                    className="absolute right-0 top-0 m-2 p-2 text-gray-500 hover:text-red-500"
                    onClick={() =>
                      handleEnquiryDeleteClick(listingId, enquiryId)
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>

                  <div className="flex ">
                    <div className=" mx-2 mt-2 w-1/3 items-center overflow-hidden p-2">
                      <ImageCarousel images={listingData?.imageUrls || []} />
                    </div>
                    <div className="mx-8 w-2/3 p-4">
                      <div className="mb-4 flex flex-row">
                        <h3 className="text-3xl font-bold">
                          {listingData?.title}
                        </h3>
                        {enquiryData?.offerSent && (
                          <span className="ml-2 mr-2 rounded bg-green-500 px-3 py-1 font-semibold text-white">
                            Ready to Accept
                          </span>
                        )}
                      </div>
                      <div className="">
                        <p className="text-xl">
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="mr-2 mt-2 w-6 text-xl text-gray-500"
                          />{" "}
                          {listingData?.streetAddress}, {listingData?.city},{" "}
                          {listingData?.county}, {listingData?.postalCode}
                        </p>
                        <div className="mx-8 mt-2 grid grid-cols-2 text-lg">
                          <p className="mb-2">
                            <FontAwesomeIcon
                              icon={faHouse}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            {listingData?.propertyType}
                          </p>
                          <p className="mb-2">
                            {" "}
                            <FontAwesomeIcon
                              icon={faBed}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            {listingData?.roomType}
                          </p>
                          <p className="mb-2">
                            <FontAwesomeIcon
                              icon={faMoneyBill}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            €{enquiryData?.rent}/month
                          </p>
                          <p className="mb-2">
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            {enquiryData?.startDate &&
                              new Date(
                                enquiryData.startDate,
                              ).toLocaleDateString("en-US", {
                                month: "short",
                                day: "2-digit",
                              })}{" "}
                            -{" "}
                            {enquiryData?.endDate &&
                              new Date(enquiryData.endDate).toLocaleDateString(
                                "en-US",
                                { month: "short", day: "2-digit" },
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="mt-1 flex justify-center">
                        <Link
                          to={`/myenquirydetails/${listingId}/${listingData?.listerId}/${enquiryId}`}
                          className="relative flex w-full justify-center rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
                        >
                          View Enquiry
                          {unreadMessagesCount > 0 && (
                            <span className="absolute right-0 top-0 inline-flex h-6 w-6 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none">
                              {unreadMessagesCount}
                            </span>
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-1 md:hidden">
                  <button
                    className="absolute right-0 top-0 m-2 text-gray-500 hover:text-red-500"
                    onClick={() =>
                      handleEnquiryDeleteClick(listingId, enquiryId)
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <div className="flex flex-row">
                    <h3 className="ml-2 text-2xl font-bold">
                      {listingData?.title}
                    </h3>
                    {enquiryData?.offerSent && (
                      <span className="ml-2 rounded bg-green-500 px-3 py-1 font-semibold text-white">
                        Ready to Accept
                      </span>
                    )}
                  </div>
                  <div className="mt-2 items-center overflow-hidden">
                    <ImageCarousel images={listingData?.imageUrls || []} />
                  </div>
                  <div className="p-4 pt-2">
                    <div className="">
                      <p className="text-lg">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="w-4 text-lg text-gray-500"
                        />{" "}
                        {listingData?.streetAddress}, {listingData?.city},{" "}
                        {listingData?.county}, {listingData?.postalCode}
                      </p>
                      <div className="text-md mt-2 grid grid-cols-2">
                        <p className="mb-2">
                          <FontAwesomeIcon
                            icon={faHouse}
                            className="mr-1 mt-2 w-4 text-gray-500"
                          />{" "}
                          {listingData?.propertyType}
                        </p>
                        <p className="mb-2">
                          {" "}
                          <FontAwesomeIcon
                            icon={faBed}
                            className="mr-1 mt-2 w-4 text-gray-500"
                          />{" "}
                          {listingData?.roomType}
                        </p>
                        <p className="mb-2">
                          <FontAwesomeIcon
                            icon={faMoneyBill}
                            className="mr-1 mt-2 w-4 text-gray-500"
                          />{" "}
                          €{enquiryData?.rent}/month
                        </p>
                        <p className="mb-2">
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="mr-1 mt-2 w-4 text-gray-500"
                          />{" "}
                          {enquiryData?.startDate &&
                            new Date(enquiryData.startDate).toLocaleDateString(
                              "en-US",
                              { month: "short", day: "2-digit" },
                            )}{" "}
                          -{" "}
                          {enquiryData?.endDate &&
                            new Date(enquiryData.endDate).toLocaleDateString(
                              "en-US",
                              { month: "short", day: "2-digit" },
                            )}
                        </p>
                      </div>
                    </div>
                    <div className="mt-1 flex justify-center">
                      <Link
                        to={`/myenquirydetails/${listingId}/${listingData?.listerId}/${enquiryId}`}
                        className="relative flex w-full justify-center rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
                      >
                        View Enquiry
                        {unreadMessagesCount > 0 && (
                          <span className="absolute right-0 top-0 inline-flex h-6 w-6 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none">
                            {unreadMessagesCount}
                          </span>
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      )}
      {isEnquiryDeleteModalOpen && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="rounded bg-white p-4">
            <p>Are you sure you want to delete this enquiry?</p>
            <div className="mt-4 flex justify-between">
              <button
                onClick={() => setIsEnquiryDeleteModalOpen(false)}
                className="rounded bg-gray-300 px-4 py-2 text-black"
              >
                Cancel
              </button>
              <button
                onClick={deleteEnquiry}
                className="rounded bg-red-500 px-4 py-2 text-white"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserEnquiries;
