import React from "react";
import ModalTemplate from "./modalTemplate";

const ProfileUpdateModal = ({ isModalOpen, closeModal }) => {
  if (!isModalOpen) return null;
  const modalContent = (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Profile Updated</h2>
      <p>You're profile has been updated!</p>
      <div className="flex flex-row justify-end">
        <button
          onClick={closeModal}
          className="mt-4 rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
        >
          Close
        </button>
      </div>
    </div>
  );

  return (
    <ModalTemplate
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      content={modalContent}
    />
  );
};

export default ProfileUpdateModal;
