import React from "react";
import ModalTemplate from "./modalTemplate";
import { useNavigate } from "react-router-dom";

const UnverifiedUserModal = ({ isModalOpen, closeModal }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigate = () => {
    navigate("/profile"); // Navigate to the /profile page
  };

  if (!isModalOpen) return null;
  const modalContent = (
    <div>
      <h2 className="mb-2 text-lg font-semibold">Profile Completion Needed</h2>
      <p>You must complete your profile to add a listing or request a stay</p>
      <p>
        {" "}
        Press the button below or click the profile icon on the top right of
        your screen to go to your profile page so you can complete your profile
      </p>
      <div className="flex flex-row justify-end">
        <button
          onClick={handleNavigate}
          className="mt-4 rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
        >
          Go to profile
        </button>
      </div>
    </div>
  );

  return (
    <ModalTemplate
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      content={modalContent}
      width="w-[90%] md:w-[400px]"
    />
  );
};

export default UnverifiedUserModal;
