import React from "react";
import ModalTemplate from "./modalTemplate";

const AlreadySentModal = ({ isModalOpen, closeModal }) => {
  const modalContent = (
    <div>
      <h2 className="mb-2 text-lg font-semibold">
        You have already sent a request to this listing
      </h2>
      <p>
        If you want to change something, here's what you can do: Go to my
        enquiries and message the lister to change your move in and move out
        dates or you can delete the enquiry in my enquiries and send a new one
      </p>
      <div className="flex flex-row justify-end">
        <button
          onClick={closeModal}
          className="mt-4 rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
        >
          Close
        </button>
      </div>
    </div>
  );

  return (
    <ModalTemplate
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      content={modalContent}
      width="w-[90%] md:w-[400px]"
    />
  );
};

export default AlreadySentModal;
