import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Root from "./routes/root";
import Listings from "./routes/listings";
import LoginPage from "./routes/login";
import SignupPage from "./routes/signup";
import UserListings from "./routes/mylistings";
import AccommodationDetails from "./routes/details";
import UserEnquiries from "./routes/enquiries";
import EnquirerDetailsComponent from "./routes/enquirydetails";
import ProfilePage from "./routes/profile";
import MyEnquiry from "./routes/myenquiry";
import CurrentStay from "./routes/currentstay";
import Practicepage from "./routes/practicepage";
import { Navigate } from "react-router-dom";
import MySublets from "./routes/mySublets";
import CurrentLet from "./routes/currentlet";
import AdminPage from "./routes/adminpage";
import UpdateCustomerCard from "./routes/updateCard";
import ContactUs from "./routes/contactus";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    children: [
      {
        index: true, // This makes it the default route for the parent path
        element: <Navigate to="/search" replace />, // Redirect to /search
      },
      {
        path: "search",
        element: <Listings />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "signup",
        element: <SignupPage />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "mylistings",
        element: <UserListings />,
      },
      {
        path: "details/:id",
        element: <AccommodationDetails />,
      },
      {
        path: "enquiries",
        element: <UserEnquiries />,
      },
      {
        path: "enquirydetails/:listingId/:userId/:enquiryId",
        element: <EnquirerDetailsComponent />,
      },
      {
        path: "myenquirydetails/:listingId/:listerId/:enquiryId",
        element: <MyEnquiry />,
      },
      {
        path: "currentstay/:subletId/:listerId/:userId",
        element: <CurrentStay />,
      },
      {
        path: "currentlet/:subletId/:listerId/:userId",
        element: <CurrentLet />,
      },
      {
        path: "practicepage",
        element: <Practicepage />,
      },
      {
        path: "mysublets",
        element: <MySublets />,
      },
      {
        path: "adminpage",
        element: <AdminPage />,
      },
      {
        path: "updatecarddetails",
        element: <UpdateCustomerCard />,
      },
      {
        path: "contactus",
        element: <ContactUs />,
      },
      {
        path: "updateCard",
        element: <UpdateCustomerCard />,
      },
    ],
  },
]);

const stripePromise = loadStripe(
  "pk_live_51O3czXKxgc2qiaBtzDdZYraPGjrlr3ZhXqftgBbecWYXqRCMUqq3sTbq6QpPvuWXv0broipiluG2FIDyULoi0FHi00bcNJFnQ7",
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Elements stripe={stripePromise}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Elements>,
);