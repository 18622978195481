import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";
import ImageCarousel from "../components/imagecarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCalendar, faUser, faHouse, faBed } from "@fortawesome/free-solid-svg-icons";
import PersonalProfile from "../components/profilecard";
import UnverifiedUserModal from "../components/modals/unverifiedmodal";
import MapWithMarker from "../components/map";
import SuccessModal from "../components/modals/requestsentmodal";
import MustLoginModal from "../components/modals/mustLoginModdal";
import CantSendYourselfModal from "../components/modals/cantSendYourselfModal";
import AlreadySentModal from "../components/modals/alreadySentModal";
import InquiryForm from "../components/inquiryform";
import { getFunctions, httpsCallable } from "firebase/functions";

const ListingDetails = () => {
  const [listing, setListing] = useState(null);
  const [user] = useAuthState(auth);
  const { id } = useParams();
  const [modals, setModals] = useState({
    unverified: false,
    success: false,
    mustLogin: false,
    cantSendYourself: false,
    alreadySent: false,
  });
  const [userDetails, setUserDetails] = useState(null);
  const [loggedinUserData, setLoggedInUserData] = useState(null);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [inquiryData, setInquiryData] = useState({
    startDate: new Date(),
    endDate: new Date(),
    rent: "",
    requests: "",
    isActive: false,
  });

  useEffect(() => {
    const fetchData = async (docRef, setDataCallback) => {
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) setDataCallback(docSnap.data());
        else console.error("Document not found");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (user) fetchData(doc(db, "users", user.uid), setLoggedInUserData);

    fetchData(doc(db, "Listings", id), (listingData) => {
      setListing(listingData);
      setInquiryData((prev) => ({
        ...prev,
        rent: listingData.rent,
        startDate: new Date(listingData.startDate),
        endDate: new Date(listingData.endDate),
      }));
      if (listingData.listerId)
        fetchData(doc(db, "users", listingData.listerId), setUserDetails);
    });
  }, [user, id]);

  const sendEmail = async () => {
    const functions = getFunctions();
    const sendEnquiryEmail = httpsCallable(functions, "sendSendEnquiryEmail");
    try {
      await sendEnquiryEmail({
        email: userDetails.email,
        receivingUser: userDetails.firstName,
        sendingUser: `${loggedinUserData?.firstName || "User"} ${loggedinUserData?.lastName || ""}`,
      });
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const sendInquiry = async (e) => {
    e.preventDefault();

    // Check if user is logged in
    if (!user) {
      setModals((prev) => ({ ...prev, mustLogin: true }));
      return;
    }

    if (!listing || !loggedinUserData) {
      console.error("Missing listing or loggedinUserData");
      return;
    }

    if (user.uid === listing.listerId) {
      setModals((prev) => ({ ...prev, cantSendYourself: true }));
      return;
    }

    if (!loggedinUserData.isProfileComplete) {
      setModals((prev) => ({ ...prev, unverified: true }));
      return;
    }

    if (loggedinUserData.enquiries?.some((e) => e.listingId === id)) {
      setModals((prev) => ({ ...prev, alreadySent: true }));
      return;
    }

    setIsSendingRequest(true);
    try {
      const inquiryPayload = {
        ...inquiryData,
        userId: user.uid,
        userName: user.displayName || user.email,
        rent: listing.rent,
        startDate: inquiryData.startDate.toISOString().split("T")[0],
        endDate: inquiryData.endDate.toISOString().split("T")[0],
      };
      const enquiryRef = doc(collection(db, "Listings", id, "enquiries"));
      await setDoc(enquiryRef, inquiryPayload);

      await addDoc(collection(db, "Listings", id, "enquiries", enquiryRef.id, "messages"), {
        text: inquiryData.requests.trim() || "Hi, I am interested in staying at your sublet!",
        userId: user.uid,
        photoURL: loggedinUserData.photoURL,
        read: false,
        timestamp: serverTimestamp(),
      });

      await setDoc(
        doc(db, "users", user.uid),
        { enquiries: [...(loggedinUserData.enquiries || []), { listingId: id, enquiryId: enquiryRef.id }] },
        { merge: true }
      );

      await sendEmail();
      setTimeout(() => setModals((prev) => ({ ...prev, success: true })), 3000);
    } catch (error) {
      console.error("Error sending enquiry:", error);
    } finally {
      setIsSendingRequest(false);
    }
  };
  
  return (
    <div className="mx-auto mb-24 mt-16 w-[90%] md:mt-20">
      {listing ? (
        <div>
          <div className="justify-left flex">
            <h1 className="mb-1 ml-[1%] text-2xl font-bold md:mb-4 md:text-3xl">{listing.title}</h1>
          </div>
          <div className="mx-auto mb-4 flex flex-row items-center space-x-2 md:space-x-8">
            <div className="w-[100%] rounded-lg shadow-md lg:w-[60%]">
              <ImageCarousel images={listing.imageUrls || []} />
            </div>
            <div className="flex hidden w-[40%] items-center lg:block">
              <div className="rounded-lg border border-gray-200 bg-white px-2 py-1 shadow-lg md:px-6 md:py-5">
                <h1 className="text-xs font-semibold md:mb-4 md:text-2xl">Request To Stay</h1>
                <InquiryForm
                  listing={listing}
                  sendInquiry={sendInquiry}
                  isSendingRequest={isSendingRequest}
                  inquiryData={inquiryData}
                  setInquiryData={setInquiryData}
                />
              </div>
            </div>
          </div>
          <div className="mb-28 space-y-2 md:mb-64 md:space-y-6">
            <p className="mb-4 text-xl font-semibold md:mb-8 md:text-2xl">
              <FontAwesomeIcon icon={faLocationDot} /> {`${listing.streetAddress}, ${listing.city}, ${listing.county}, ${listing.postalCode}`}
            </p>
            <div className="rounded-lg border border-gray-500 bg-white px-3 py-3 shadow-xl md:px-6 md:py-5 lg:hidden">
              <h1 className="mb-4 text-xl font-extrabold md:mb-4 md:text-2xl">Request To Stay</h1>
              <InquiryForm
                listing={listing}
                sendInquiry={sendInquiry}
                isSendingRequest={isSendingRequest}
                inquiryData={inquiryData}
                setInquiryData={setInquiryData}
              />
            </div>
            <div className="grid gap-4 lg:grid-cols-2">
              <div className="space-y-2">
                <p className="mt-4 text-xl font-semibold">Dates Available</p>
                <div className="mx-auto mb-2 flex flex-row items-center justify-center space-x-2 text-lg">
                  <FontAwesomeIcon icon={faCalendar} className="w-6 text-gray-500" />
                  <p>{listing?.startDate && new Date(listing.startDate).toLocaleDateString("en-US", { month: "short", day: "2-digit" })}</p>
                  <p className="px-4">-</p>
                  <FontAwesomeIcon icon={faCalendar} className="w-6 text-gray-500" />
                  <p>{listing?.endDate && new Date(listing.endDate).toLocaleDateString("en-US", { month: "short", day: "2-digit" })}</p>
                </div>
                <hr className="border-t border-gray-200" />
                <p className="text-xl font-semibold">Property Details</p>
                <div className="ml-[5%] grid grid-cols-2 items-center justify-between text-lg md:ml-[25%]">
                  <p className="mb-2">
                    <FontAwesomeIcon icon={faHouse} className="mr-2 mt-2 w-6 text-gray-500" />
                    {listing.propertyType}
                  </p>
                  <p className="mb-2">
                    <FontAwesomeIcon icon={faBed} className="mr-2 mt-2 w-6 text-gray-500" />
                    {listing.roomType}
                  </p>
                </div>
                <hr className="border-t border-gray-200" />
                <p className="text-xl font-semibold">Other Tenants</p>
                <div className="ml-[5%] grid grid-cols-2 items-center text-lg md:ml-[25%]">
                  <p className="mb-2">
                    <FontAwesomeIcon icon={faUser} className="mr-2 mt-2 w-6 text-gray-500" />
                    {listing.maleTenants} Males
                  </p>
                  <p className="mb-2">
                    <FontAwesomeIcon icon={faUser} className="mr-2 mt-2 w-6 text-gray-500" />
                    {listing.femaleTenants} Females
                  </p>
                </div>
              </div>
              <hr className="border-t border-gray-200 lg:hidden" />
              <p className="text text-xl font-semibold lg:hidden">Lister Details</p>
              <div className="flex items-center">
                <div className="mx-auto mb-2 flex w-[90%] items-center md:mt-4">{userDetails && <PersonalProfile user={userDetails} />}</div>
              </div>
            </div>
            <hr className="border-t border-gray-200" />
            <h3 className="text-xl font-semibold">Description</h3>
            <p className="text-[16px]">{listing.description}</p>
            <hr className="border-t border-gray-200" />
            <div>
              <h3 className="mb-4 text-xl font-semibold">Map</h3>
              <div className="flex h-48 items-center justify-center md:h-[300px]">
                <MapWithMarker eircode={listing.postalCode} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <SuccessModal isModalOpen={modals.success} closeModal={() => setModals((prev) => ({ ...prev, success: false }))} />
      <MustLoginModal isModalOpen={modals.mustLogin} closeModal={() => setModals((prev) => ({ ...prev, mustLogin: false }))} />
      <UnverifiedUserModal isModalOpen={modals.unverified} closeModal={() => setModals((prev) => ({ ...prev, unverified: false }))} />
      <CantSendYourselfModal isModalOpen={modals.cantSendYourself} closeModal={() => setModals((prev) => ({ ...prev, cantSendYourself: false }))} />
      <AlreadySentModal isModalOpen={modals.alreadySent} closeModal={() => setModals((prev) => ({ ...prev, alreadySent: false }))} />
    </div>
  );
};

export default ListingDetails;
