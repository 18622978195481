import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import SubletModal from "../components/modals/subletModal";
import PaymentForm from "../components/unused/transferfunds";
import PaymentComponent from "../components/unused/chargecustomer";
import { useNavigate } from "react-router-dom";
import UserModal from "../components/modals/adminpagemodals/usermodal";

const AdminPage = () => {
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("customers");
  const [users, setUsers] = useState([]);
  const [sublets, setSublets] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [lastTransactionId, setLastTransactionId] = useState(null); // To keep track of the last transaction ID
  const [hasMore, setHasMore] = useState(true); // To check if more data is available
  const [showSubletModal, setShowSubletModal] = useState(false);
  const [selectedSublet, setSelectedSublet] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [expandedListings, setExpandedListings] = useState({});
  const [listings, setListings] = useState([]);
  const [payments, setPayments] = useState([]);
  const [failedPayments, setFailedPayments] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedDates, setExpandedDates] = useState({}); // To track expanded date groups
  const [accountDetails, setAccountDetails] = useState(null);
  const navigate = useNavigate();
  const functions = getFunctions();

  const fetchStripeAccountInfo = async (accountId) => {
    const getStripeAccountInfo = httpsCallable(
      functions,
      "getStripeAccountInfo",
    );

    try {
      const result = await getStripeAccountInfo({ accountId });
      setAccountDetails(result.data); // Store the fetched data in state
      console.log(result.data); // Or handle the data as needed
    } catch (error) {
      console.error("Error fetching Stripe account information:", error);
      // Handle the error appropriately in your UI
    }
  };

  // Example utility function to group payments by date
  const groupPaymentsByDate = (payments) => {
    return payments.reduce((acc, payment) => {
      const { date } = payment;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(payment);
      return acc;
    }, {});
  };

  const toggleDateGroup = (date) => {
    setExpandedDates((prevState) => ({
      ...prevState,
      [date]: !prevState[date],
    }));
  };

  const toggleListingEnquiries = async (listingId) => {
    if (expandedListings[listingId]) {
      // If already expanded, simply toggle visibility off
      setExpandedListings({ ...expandedListings, [listingId]: undefined });
    } else {
      // Fetch enquiries and expand
      const enquiriesSubcolRef = collection(
        db,
        "Listings",
        listingId,
        "enquiries",
      );
      const enquiriesSnapshot = await getDocs(enquiriesSubcolRef);
      const enquiriesData = enquiriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setExpandedListings({ ...expandedListings, [listingId]: enquiriesData });
    }
  };

  useEffect(() => {
    const fetchSummaries = async () => {
      const summariesColRef = query(collection(db, "paymentsSummary"));
      const querySnapshot = await getDocs(summariesColRef);
      const summariesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        paymentResults: doc.data().paymentResults || [],
      }));
      setSummaries(summariesData);
    };

    fetchSummaries();
  }, []);

  const toggleExpandRow = (id) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const fetchPayments = async () => {
      // Adjusted query to include orderBy for the date field
      const paymentsColRef = query(
        collection(db, "payments"),
        orderBy("date", "asc"),
      ); // 'desc' for descending order, use 'asc' for ascending
      const querySnapshot = await getDocs(paymentsColRef);
      const paymentsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPayments(paymentsData);
    };

    fetchPayments();
  }, []);

  useEffect(() => {
    const fetchFailedPayments = async () => {
      // Adjusted query to include orderBy for the date field
      const failedPaymentsColRef = query(
        collection(db, "paymentsFailed"),
        orderBy("date", "asc"),
      ); // 'desc' for descending order, use 'asc' for ascending
      const querySnapshot = await getDocs(failedPaymentsColRef);
      const failedPaymentsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFailedPayments(failedPaymentsData);
    };

    fetchFailedPayments();
  }, []);

  useEffect(() => {
    const fetchListings = async () => {
      // Adjusted query to include orderBy for the date field
      const listingsColRef = query(collection(db, "Listings")); // 'desc' for descending order, use 'asc' for ascending
      const querySnapshot = await getDocs(listingsColRef);
      const listingsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setListings(listingsData);
    };

    fetchListings();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      // Adjusted query to include orderBy for the date field
      const usersColRef = query(collection(db, "users"));
      const querySnapshot = await getDocs(usersColRef);
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchSublets = async () => {
      const subletsQuery = query(collection(db, "Sublets"));
      const subletsSnapshot = await getDocs(subletsQuery);
      const subletsData = subletsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSublets(subletsData);

      const userIds = subletsData.map((sublet) => sublet.userId);
      const listerIds = subletsData.map((sublet) => sublet.listerId);

      const uniqueUserIds = [...new Set([...userIds, ...listerIds])];
      const usersQuery = query(
        collection(db, "users"),
        where("__name__", "in", uniqueUserIds),
      );
      const usersSnapshot = await getDocs(usersQuery);
      const users = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const customersData = users.filter((user) => userIds.includes(user.id));
      const accountsData = users.filter((user) => listerIds.includes(user.id));

      setCustomers(customersData);
      setAccounts(accountsData);
    };

    fetchSublets();
  }, []);

  const fetchTransactions = async () => {
    const functions = getFunctions();
    const listStripeTransactions = httpsCallable(
      functions,
      "listStripeTransactions",
    );

    try {
      const result = await listStripeTransactions({
        limit: 10, // Adjust as needed
        starting_after: lastTransactionId, // Pass the last transaction ID for pagination
      });

      console.log(result); // Check the structure of returned data

      // Append new transactions to the existing ones
      setTransactions((prevTransactions) => [
        ...prevTransactions,
        ...result.data.data,
      ]);
      setLastTransactionId(result.data.data[result.data.data.length - 1].id); // Update the last transaction ID

      // Check if more data is available
      if (result.data.has_more) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setHasMore(false); // In case of error, assume no more data is available
    }
  };

  const openUserModal = (sublet) => {
    setSelectedUser(sublet);
    setShowUserModal(true);
  };

  const formatDateString = (dateString) => {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "users":
        return (
          <div className="my-4 overflow-x-auto rounded-lg border border-gray-200 shadow-xl ">
            <table className="min-w-full divide-y divide-gray-300">
              <tbody className="divide-y divide-gray-200 bg-white">
                {users.map((user) => (
                  <tr key={user.id} className="hover:bg-blue-50">
                    <td className="w-16 py-4 pl-8">
                      {user.photoURL ? (
                        <img
                          src={user.photoURL}
                          alt="Profile"
                          className="h-10 w-10 rounded-full"
                        />
                      ) : (
                        <div className="text-gray-400">No Image</div>
                      )}
                    </td>
                    <td className="w-48 px-6 py-4">
                      <div className="text-sm text-gray-500">Name</div>
                      <div className="text-gray-900">
                        {user.firstName} {user.lastName}
                      </div>
                    </td>
                    <td className="w-48 px-6 py-4">
                      <div className="text-sm text-gray-500">Email</div>
                      <div className="text-gray-900">{user.email}</div>
                    </td>
                    <td className="w-[10%] px-6 py-4">
                      <div className="text-sm text-gray-500">Listings</div>
                      <div className="text-gray-900">
                        {user.listings ? user.listings.length : 0}
                      </div>
                    </td>
                    <td className="w-[10%] px-6 py-4">
                      <div className="text-sm text-gray-500">Enquiries</div>
                      <div className="text-gray-900">
                        {user.enquiries ? user.enquiries.length : 0}
                      </div>
                    </td>
                    <td className="w-[10%] px-6 py-4">
                      <div className="text-sm text-gray-500">Sublets</div>
                      <div className="text-gray-900">
                        {user.sublets ? user.sublets.length : 0}
                      </div>
                    </td>
                    <td className="w-1/6 px-6 py-4">
                      <button
                        onClick={() => openUserModal(user)}
                        className="rounded bg-purple-500 px-4 py-2 font-semibold text-white transition duration-150 ease-in-out hover:bg-purple-700"
                      >
                        View User
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      case "listings":
        return (
          <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-xl ">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gradient-to-r from-blue-800 to-blue-600">
                <tr>
                  <th className="rounded-tl-lg px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white">
                    Title
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white">
                    Lister
                  </th>
                  <th className="rounded-tr-lg px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {listings.map((listing) => (
                  <React.Fragment key={listing.id}>
                    <tr className="hover:bg-blue-50">
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                        {listing.title}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                        {listing.listerName}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm">
                        <button
                          onClick={() => toggleListingEnquiries(listing.id)}
                          className="rounded bg-purple-500 px-4 py-2 font-semibold text-white transition duration-150 ease-in-out hover:bg-purple-700"
                        >
                          {expandedListings[listing.id]
                            ? "Hide Enquiries"
                            : "Show Enquiries"}
                        </button>
                      </td>
                    </tr>
                    {expandedListings[listing.id] &&
                      expandedListings[listing.id].map((enquiry) => (
                        <tr key={enquiry.id} className="bg-gray-50">
                          <td className="px-6 py-4" colSpan="3">
                            <div className="italic text-gray-800">
                              Enquiry ID: {enquiry.id}, Message:{" "}
                              {enquiry.message}, ...
                            </div>
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        );
      case "stripe customers":
        return (
          <div>
            <table className="w-full table-fixed text-left text-sm rtl:text-right">
              <tbody>
                {customers.map((customer) => (
                  <tr key={customer.id} className="border-b bg-white">
                    <td className="w-16 py-4 pl-8">
                      {customer.photoURL ? (
                        <img
                          src={customer.photoURL}
                          alt="Profile"
                          className="h-10 w-10 rounded-full"
                        />
                      ) : (
                        <div>No Image</div>
                      )}
                    </td>
                    <td className="w-48 px-6 py-4">
                      <div className="text-sm text-gray-500">Name</div>
                      {customer.firstName} {customer.lastName}
                    </td>
                    <td className="w-48 px-6 py-4">
                      <div className="text-sm text-gray-500 ">
                        Dates requested
                      </div>
                      {customer.customerId}
                    </td>
                    <td className="w-1/6 px-6 py-4">
                      <button className="relative rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700">
                        View Customer
                      </button>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      case "stripe accounts":
        return (
          <div>
            <table className="w-full table-fixed text-left text-sm rtl:text-right">
              <tbody>
                {accounts.map((account) => (
                  <tr key={account.id} className="border-b bg-white">
                    <td className="w-16 py-4 pl-8">
                      {account.photoURL ? (
                        <img
                          src={account.photoURL}
                          alt="Profile"
                          className="h-10 w-10 rounded-full"
                        />
                      ) : (
                        <div>No Image</div>
                      )}
                    </td>
                    <td className="w-48 px-6 py-4">
                      <div className="text-sm text-gray-500">Name</div>
                      {account.firstName} {account.lastName}
                    </td>
                    <td className="w-48 px-6 py-4">
                      <div className="text-sm text-gray-500 ">
                        stripe accountID
                      </div>
                      {account.accountId}
                    </td>
                    <td className="w-1/6 px-6 py-4">
                      <button
                        onClick={() =>
                          fetchStripeAccountInfo(account.accountId)
                        } // Assuming each account has a `stripeAccountId`
                        className="relative rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
                      >
                        View Account
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {accountDetails && (
              <div className="mt-4 rounded bg-white p-4 shadow-lg">
                <h3 className="text-lg font-semibold">
                  Stripe Account Details:
                </h3>
                <p>Account ID: {accountDetails.id}</p>
                <p>Email: {accountDetails.email}</p>
                {/* Display other details as needed */}
              </div>
            )}
          </div>
        );
      case "sublets":
        return (
          <div className="my-4 overflow-x-auto rounded-lg border border-gray-200 shadow-xl ">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gradient-to-r from-blue-800 to-blue-600">
                <tr>
                  <th className="rounded-tl-lg px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white">
                    Title
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white">
                    Sublet ID
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white">
                    Tenant
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white">
                    Lister
                  </th>
                  <th className="rounded-tr-lg px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sublets.map((sublet) => (
                  <tr key={sublet.id} className="hover:bg-blue-50">
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-900">
                      {sublet.title}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {sublet.id}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {sublet.userName} ({sublet.customerId})
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {sublet.listerName} ({sublet.accountId})
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium">
                      <button
                        onClick={() =>
                          navigate(
                            `/currentlet/${sublet.id}/${sublet.listerId}/${sublet.userId}`,
                          )
                        }
                        className="inline-flex items-center rounded-md border border-transparent bg-purple-500 px-4 py-2 font-semibold text-white transition duration-150 ease-in-out hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                      >
                        View Sublet
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );

      case "stripe payments":
        return (
          <div>
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    ID
                  </th>
                  <th className="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Amount
                  </th>
                  {/* Add more headers as needed */}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(transactions) &&
                  transactions.map((transaction) => (
                    <tr key={transaction.id}>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        {transaction.id}
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        {transaction.amount} {/* Format as needed */}
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        {transaction.source.customer}
                      </td>
                      {/* Add more data cells as needed */}
                    </tr>
                  ))}
              </tbody>
            </table>
            <button onClick={fetchTransactions} disabled={!hasMore}>
              Load More Transactions
            </button>
          </div>
        );
      case "Upcoming Payments":
        const groupedPayments = groupPaymentsByDate(payments); // Group payments by date
        return (
          <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-xl ">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gradient-to-r from-blue-800 to-blue-600">
                <tr>
                  <th
                    className="rounded-tl-lg px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white"
                    colSpan="5"
                  >
                    Date
                  </th>
                  <th
                    className="px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white"
                    colSpan="1"
                  >
                    Entries
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {Object.entries(groupedPayments).map(
                  ([date, paymentsForDate], groupIndex) => (
                    <React.Fragment key={date}>
                      <tr
                        className="cursor-pointer hover:bg-blue-50"
                        onClick={() => toggleDateGroup(date)}
                      >
                        <td
                          className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          colSpan="5"
                        >
                          {formatDateString(date)}
                        </td>
                        <td
                          className="whitespace-nowrap px-6 py-4 text-xl font-medium text-gray-900"
                          colSpan="1"
                        >
                          {paymentsForDate.length}
                        </td>
                      </tr>
                      {expandedDates[date] && (
                        <>
                          <tr className="bg-blue-100 text-gray-600">
                            <td className="px-6 py-2 text-sm font-bold">
                              Collector Name
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Account ID
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Customer Name
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Customer ID
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Rent
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Sublet ID
                            </td>
                          </tr>
                          {paymentsForDate.map((payment, index) => (
                            <tr
                              key={`${groupIndex}-${index}`}
                              className="hover:bg-blue-50"
                            >
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.collectorName}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.accountId}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.customerName}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.customerId}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                €{payment.rent}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.subletId}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </React.Fragment>
                  ),
                )}
              </tbody>
            </table>
          </div>
        );
      case "FailedPayments":
        const groupedFailedPayments = groupPaymentsByDate(failedPayments); // Group payments by date
        return (
          <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-xl ">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gradient-to-r from-blue-800 to-blue-600">
                <tr>
                  <th
                    className="rounded-tl-lg px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white"
                    colSpan="7"
                  >
                    Date
                  </th>
                  <th
                    className="px-6 py-3 text-left text-sm font-bold uppercase tracking-wider text-white"
                    colSpan="1"
                  >
                    Entries
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {Object.entries(groupedFailedPayments).map(
                  ([date, paymentsForDate], groupIndex) => (
                    <React.Fragment key={date}>
                      <tr
                        className="cursor-pointer hover:bg-blue-50"
                        onClick={() => toggleDateGroup(date)}
                      >
                        <td
                          className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          colSpan="7"
                        >
                          {formatDateString(date)}
                        </td>
                        <td
                          className="whitespace-nowrap px-6 py-4 text-xl font-medium text-gray-900"
                          colSpan="1"
                        >
                          {paymentsForDate.length}
                        </td>
                      </tr>
                      {expandedDates[date] && (
                        <>
                          <tr className="bg-blue-100 text-gray-600">
                            <td className="px-6 py-2 text-sm font-bold">
                              Collector Name
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Account ID
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Customer Name
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Customer ID
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Rent
                            </td>
                            <td className="px-6 py-2 text-sm font-bold">
                              Sublet ID
                            </td>
                            <td
                              className="px-6 py-2 text-sm font-bold"
                              colSpan="2"
                            >
                              Error
                            </td>
                          </tr>
                          {paymentsForDate.map((payment, index) => (
                            <tr
                              key={`${groupIndex}-${index}`}
                              className="hover:bg-blue-50"
                            >
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.collectorName}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.accountId}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.customerName}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.customerId}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                €{payment.rent}
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-700">
                                {payment.subletId}
                              </td>
                              <td
                                className="px-6 py-2 text-sm text-gray-700"
                                colSpan="2"
                              >
                                {payment.error}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </React.Fragment>
                  ),
                )}
              </tbody>
            </table>
          </div>
        );

      case "Payments Summary":
        return (
          <div className="my-4 overflow-x-auto rounded-lg border border-gray-200 shadow-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gradient-to-r from-blue-500 to-blue-700">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider text-white">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider text-white">
                    Entries Count
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider text-white">
                    Success
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider text-white">
                    Failure
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300 bg-white">
                {summaries.map((summary) => {
                  const successCount = summary.paymentResults.filter(
                    (result) => result.success === true,
                  ).length;
                  const failureCount = summary.paymentResults.filter(
                    (result) => result.success === false,
                  ).length;
                  const isExpanded = expandedRows[summary.id];
                  // Extract and format the date
                  const summaryDate = summary.id.replace("summary_", "");
                  const dateObject = new Date(summaryDate);
                  const formattedDate = dateObject.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  });

                  return (
                    <React.Fragment key={summary.id}>
                      <tr
                        className="cursor-pointer hover:bg-blue-50"
                        onClick={() => toggleExpandRow(summary.id)}
                      >
                        <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                          {formattedDate}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                          {summary.paymentResults.length}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                          <span className="inline-flex rounded-full px-2 text-center font-bold leading-5 text-green-500">
                            {successCount}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                          <span className="inline-flex rounded-full px-2 text-center font-bold leading-5 text-red-500">
                            {failureCount}
                          </span>
                        </td>
                      </tr>
                      {isExpanded && (
                        <tr className="bg-blue-50">
                          <td className="px-6 py-4" colSpan={4}>
                            <div className="rounded-lg bg-white shadow-inner">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                  <tr className="text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                                    <th className="px-2 py-2">
                                      Payment Intent ID
                                    </th>
                                    <th className="px-2 py-2">Account ID</th>
                                    <th className="px-2 py-2">Customer ID</th>
                                    <th className="px-2 py-2">Amount</th>
                                    <th className="px-2 py-2">Success</th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 text-sm">
                                  {summary.paymentResults.map(
                                    (entry, index) => (
                                      <tr key={index}>
                                        <td className="px-2 py-2 text-gray-900">
                                          {entry.paymentIntentId}
                                        </td>
                                        <td className="px-2 py-2 text-gray-900">
                                          {entry.accountId}
                                        </td>
                                        <td className="px-2 py-2 text-gray-900">
                                          {entry.customerId}
                                        </td>
                                        <td className="px-2 py-2 text-gray-900">
                                          €{entry.amount}
                                        </td>
                                        <td className="px-2 py-2">
                                          <span
                                            className={`font-semibold ${
                                              entry.success
                                                ? "text-green-600"
                                                : "text-red-600"
                                            }`}
                                          >
                                            {entry.success ? "True" : "False"}
                                          </span>
                                        </td>
                                      </tr>
                                    ),
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        );

      default:
        return <div>This will eventuallly be a dashboard</div>;
    }
  };

  if (user && user.email === "liam12crowley@gmail.com") {
    return (
      <div className="mt-24 px-8 py-4">
        <h1 className="mb-6 text-3xl font-semibold text-gray-800">
          Admin Dashboard
        </h1>
        <div className="mb-8 flex flex-wrap gap-2">
          {[
            "users",
            "listings",
            "sublets",
            "Upcoming Payments",
            "FailedPayments",
            "Payments Summary",
            "stripe customers",
            "stripe accounts",
            "stripe payments",
          ].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`rounded-lg px-4 py-2 transition duration-150 ease-in-out ${
                activeTab === tab
                  ? "bg-purple-600 text-white shadow-lg"
                  : "bg-gray-200 text-gray-800 hover:bg-gray-300"
              } font-medium`}
            >
              {tab.charAt(0).toUpperCase() +
                tab
                  .slice(1)
                  .replace(/([A-Z])/g, " $1")
                  .trim()}
            </button>
          ))}
        </div>
        <div className="tab-content rounded-lg bg-white p-6 shadow-md">
          {renderTabContent()}
          {selectedUser && (
            <UserModal
              isModalOpen={showUserModal}
              closeModal={() => setShowUserModal(false)}
              user={selectedUser}
            />
          )}
        </div>
        {/* More admin features here */}
      </div>
    );
  } else {
    return <div className="ml-64 mt-64">Access Denied</div>;
  }
};

export default AdminPage;
