import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  faHouse,
  faMoneyBill,
  faLocationDot,
  faBed,
  faCalendar,
  faChevronDown,
  faChevronUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ImageCarousel from "../components/imagecarousel";
import { useNavigate } from "react-router-dom";
import UnverifiedUserModal from "../components/modals/unverifiedmodal";
import AddListing from "../components/modals/addlistingmodal";

const UserListings = () => {
  const [listings, setListings] = useState([]);
  const [enquiries, setEnquiries] = useState({}); // Store enquiries for each listing
  const [user, loading, error] = useAuthState(auth); // Modified to include loading and error
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnverifiedModalOpen, setUnverifiedModalOpen] = useState(false);
  const [userData, setuserData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(null);

  // Seems like a perfect fetch for the get current user (check about unsubscribe, compare to profile one)
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            setuserData(userDocSnap.data());
          } else {
            console.log("No such user in Firestore.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  // Opens the add listing modal
  const handleOpenModal = () => {
    console.log("Is profile complete:", userData?.isProfileComplete);
    if (user && userData.isProfileComplete) {
      setModalOpen(true);
    } else {
      setUnverifiedModalOpen(true);
    }
  };

  // Get the listings and their relevant enquiries
  useEffect(() => {
    setIsLoading(true);
    const fetchListings = async () => {
      if (user) {
        const q = query(
          collection(db, "Listings"),
          where("listerId", "==", user.uid),
        );
        const querySnapshot = await getDocs(q);
        const fetchedListings = [];
        for (const listingDoc of querySnapshot.docs) {
          const listing = { id: listingDoc.id, ...listingDoc.data() };
          fetchedListings.push(listing);
          const enquiriesSnapshot = await getDocs(
            collection(db, "Listings", listingDoc.id, "enquiries"),
          );
          const enquiriesWithUser = [];
          for (const enquiryDoc of enquiriesSnapshot.docs) {
            const enquiry = { id: enquiryDoc.id, ...enquiryDoc.data() };

            // Debugging logs
            console.log("db:", db);
            console.log("User ID:", enquiry.userId);

            if (enquiry.userId) {
              // Check if userId is defined
              const userDocRef = doc(db, "users", enquiry.userId);
              const userDoc = await getDoc(userDocRef);
              const userDetails = userDoc.exists()
                ? userDoc.data()
                : { firstName: "Unknown", lastName: "" };
              enquiriesWithUser.push({
                ...enquiry,
                user: userDetails,
              });
            } else {
              console.log("Undefined userId for enquiry:", enquiry);
            }
          }
          for (const enquiry of enquiriesWithUser) {
            const messagesSnapshot = await getDocs(
              collection(
                db,
                "Listings",
                listingDoc.id,
                "enquiries",
                enquiry.id,
                "messages",
              ),
            );
            const unreadCount = messagesSnapshot.docs.filter(
              (doc) => !doc.data().read && doc.data().userId !== user.uid,
            ).length;
            enquiry.unreadMessagesCount = unreadCount;
          }

          setEnquiries((prevEnquiries) => ({
            ...prevEnquiries,
            [listingDoc.id]: enquiriesWithUser,
          }));
        }
        setListings(fetchedListings);
        setIsLoading(false);
      }
    };

    fetchListings();
  }, [user]);

  const navigate = useNavigate();

  const handleNavigate = (listingId) => {
    navigate(`/details/${listingId}`);
  };

  const toggleAccordion = (listingId) => {
    const newEnquiries = { id: doc.id, ...enquiries };
    newEnquiries[listingId].isOpen = !newEnquiries[listingId].isOpen;
    setEnquiries(newEnquiries);
  };

  const formatDateRange = (start, end) => {
    const options = { month: "short", day: "numeric" };

    // Convert string dates to Date objects
    const startDate = new Date(start);
    const endDate = new Date(end);

    return `${startDate.toLocaleDateString(
      "en-US",
      options,
    )} - ${endDate.toLocaleDateString("en-US", options)}`;
  };

  const handleDeleteClick = (listingId) => {
    setSelectedListingId(listingId);
    window.scrollTo(0, 0);
    setIsDeleteModalOpen(true);
  };

  const deleteListing = async (listingId) => {
    setIsLoading(true);

    try {
      // Get all enquiries for the listing
      const enquiriesSnapshot = await getDocs(
        collection(db, "Listings", listingId, "enquiries"),
      );
      for (const enquiryDoc of enquiriesSnapshot.docs) {
        // Delete all messages in each enquiry
        const messagesSnapshot = await getDocs(
          collection(
            db,
            "Listings",
            listingId,
            "enquiries",
            enquiryDoc.id,
            "messages",
          ),
        );
        for (const messageDoc of messagesSnapshot.docs) {
          await deleteDoc(
            doc(
              db,
              "Listings",
              listingId,
              "enquiries",
              enquiryDoc.id,
              "messages",
              messageDoc.id,
            ),
          );
        }

        // Delete the enquiry document
        await deleteDoc(
          doc(db, "Listings", listingId, "enquiries", enquiryDoc.id),
        );
      }

      // Delete the listing document
      await deleteDoc(doc(db, "Listings", listingId));

      // Update the local state to reflect the deletion
      setListings(listings.filter((listing) => listing.id !== listingId));
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
    setIsDeleteModalOpen(false); // Close the modal
    setIsLoading(false);
  };

  if (loading) {
    return (
      <div className="mt-40 py-6 text-center text-2xl font-semibold text-gray-700">
        Loading...
      </div>
    ); // Show loading state
  }

  if (!user) {
    return (
      <div className="mx-auto mb-64 mt-16 w-[90%] md:mt-20 md:w-[80%]">
        {" "}
        <h2 className="text-3xl font-semibold">My Listings</h2>
        <div className="mt-24 py-6 text-center text-2xl font-semibold text-gray-700">
          Please sign in to be able to post and see your listings
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto mb-64 mt-16 w-[90%] items-center md:mt-20 md:w-[80%]">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-3xl font-semibold">My Listings</h2>
        <button
          className="rounded bg-purple-500 px-2 py-2 text-[15px] font-bold text-white hover:bg-purple-700 md:px-4"
          onClick={handleOpenModal}
        >
          <FontAwesomeIcon icon={faPlus} /> Post a Room
        </button>
      </div>

      {isLoading ? (
        <div className="mt-24 py-6 text-center text-2xl font-semibold text-gray-700">
          Loading...
        </div> // Your loading icon or component
      ) : listings.length === 0 ? (
        <div className="mt-24 text-center text-lg">
          <p>You currently have no listing</p>
          <p>Press the post a room button to begin</p>
        </div>
      ) : (
        <div className="space-y-6">
          {listings.map((listing) => (
            <div
              key={listing.id}
              className="card relative rounded-lg border border-gray-200 bg-white shadow-lg"
            >
              <div className="hidden md:block">
                <div className="flex ">
                  <button
                    className="absolute right-2 top-2 rounded-full p-2 font-bold text-gray-500 hover:text-gray-700"
                    onClick={() => handleDeleteClick(listing.id)}
                    title="Delete Listing"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <div className="mx-2 mt-2 items-center overflow-hidden p-2 md:w-1/2 lg:w-2/5">
                    <ImageCarousel images={listing?.imageUrls || []} />
                  </div>
                  <div className="p-2 md:w-1/2 lg:mx-4 lg:w-3/5 lg:p-4">
                    <h3
                      className="mb-4 cursor-pointer text-3xl font-bold underline"
                      onClick={() => handleNavigate(listing.id)}
                    >
                      {listing.title}
                    </h3>
                    <div className="">
                      <p className="text-[15px] lg:text-xl">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="mr-2 mt-2 w-6 text-[15px] text-gray-500 lg:text-xl"
                        />{" "}
                        {listing?.streetAddress}, {listing.city},{" "}
                        {listing.county}, {listing.postalCode}
                      </p>
                      <div className="mt-2 grid grid-cols-2 text-[15px] lg:mx-8 lg:text-xl">
                        <p className="mb-2">
                          <FontAwesomeIcon
                            icon={faHouse}
                            className="mr-2 mt-4 w-6 text-gray-500"
                          />{" "}
                          {listing.propertyType}
                        </p>
                        <p className="mb-2">
                          {" "}
                          <FontAwesomeIcon
                            icon={faBed}
                            className="mr-2 mt-4 w-6 text-gray-500"
                          />{" "}
                          {listing.roomType}
                        </p>
                        <p className="mb-2">
                          <FontAwesomeIcon
                            icon={faMoneyBill}
                            className="mr-2 mt-4 w-6 text-gray-500"
                          />{" "}
                          €{listing.rent}/month
                        </p>
                        <p className="mb-2">
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="mr-2 mt-4 w-6 text-gray-500"
                          />{" "}
                          {listing?.startDate &&
                            new Date(listing.startDate).toLocaleDateString(
                              "en-US",
                              { month: "short", day: "2-digit" },
                            )}{" "}
                          -{" "}
                          {listing?.endDate &&
                            new Date(listing.endDate).toLocaleDateString(
                              "en-US",
                              { month: "short", day: "2-digit" },
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:hidden">
                <div className="flex flex-col">
                  <button
                    className="absolute right-2 top-2 rounded-full font-bold text-gray-500 hover:text-gray-700"
                    onClick={() => handleDeleteClick(listing.id)}
                    title="Delete Listing"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <h3
                    className="ml-2 cursor-pointer text-2xl font-bold underline"
                    onClick={() => handleNavigate(listing.id)}
                  >
                    {listing.title}
                  </h3>
                  <div className=" items-center overflow-hidden pt-1">
                    <ImageCarousel images={listing?.imageUrls || []} />
                  </div>
                  <div className="p-4 pt-2">
                    <div className="">
                      <p className="text-lg">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="w-4 text-lg text-gray-500"
                        />{" "}
                        {listing?.streetAddress}, {listing?.city},{" "}
                        {listing?.county}, {listing?.postalCode}
                      </p>
                      <div className="text-md mt-2 grid grid-cols-2">
                        <p className="mb-2">
                          <FontAwesomeIcon
                            icon={faHouse}
                            className="mr-1 mt-2 w-4 text-gray-500"
                          />{" "}
                          {listing?.propertyType}
                        </p>
                        <p className="mb-2">
                          {" "}
                          <FontAwesomeIcon
                            icon={faBed}
                            className="mr-1 mt-2 w-4 text-gray-500"
                          />{" "}
                          {listing?.roomType}
                        </p>
                        <p className="mb-2">
                          <FontAwesomeIcon
                            icon={faMoneyBill}
                            className="mr-1 mt-2 w-4 text-gray-500"
                          />{" "}
                          €{listing?.rent}/month
                        </p>
                        <p className="mb-2">
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="mr-1 mt-2 w-4 text-gray-500"
                          />{" "}
                          {listing?.startDate &&
                            new Date(listing.startDate).toLocaleDateString(
                              "en-US",
                              { month: "short", day: "2-digit" },
                            )}{" "}
                          -{" "}
                          {listing?.endDate &&
                            new Date(listing.endDate).toLocaleDateString(
                              "en-US",
                              { month: "short", day: "2-digit" },
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={() => toggleAccordion(listing.id)}
                className="relative flex w-full items-center justify-between rounded-b bg-purple-500 py-2 text-lg font-semibold text-white transition duration-300 hover:bg-purple-600"
              >
                <span className="mx-auto">
                  Requests ({(enquiries[listing.id] || []).length})
                </span>
                {(enquiries[listing.id] || []).reduce(
                  (sum, enquiry) => sum + (enquiry.unreadMessagesCount || 0),
                  0,
                ) > 0 && (
                  <span className="absolute right-0 top-0 inline-flex h-5 w-5 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white">
                    {(enquiries[listing.id] || []).reduce(
                      (sum, enquiry) =>
                        sum + (enquiry.unreadMessagesCount || 0),
                      0,
                    )}
                  </span>
                )}
                <span className="mr-8">
                  {enquiries[listing.id]?.isOpen ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </span>
              </button>
              {enquiries[listing.id]?.isOpen && (
                <div className="rounded p-1">
                  {(enquiries[listing.id] || []).length === 0 ? (
                    <div className="p-4 text-center text-lg">
                      You don't have any requests yet.
                    </div>
                  ) : (
                    <div className="relative">
                      <table className="w-full table-fixed text-left text-sm rtl:text-right">
                        <tbody>
                          {(enquiries[listing.id] || []).map(
                            (enquiry, index) => (
                              <tr key={index} className="border-b bg-white">
                                <td className="mt-2 w-[15%] sm:p-2 md:w-[10%] lg:py-4 lg:pl-8">
                                  {enquiry.user.photoURL ? (
                                    <img
                                      src={enquiry.user.photoURL}
                                      alt="Profile"
                                      className="ml-2 w-[60%] rounded-full md:w-[80%]"
                                      style={{
                                        aspectRatio: "1 / 1",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    <div>No Image</div>
                                  )}
                                </td>
                                <td className="text-overflow-ellipsis w-[30%] overflow-hidden whitespace-nowrap py-1 md:w-[25%] md:py-4 lg:w-[20%] lg:px-6">
                                  <div className="pr-1 text-[12px] text-gray-500 md:text-sm">
                                    Name
                                  </div>
                                  <p className="text-[14px]">
                                    {enquiry.user.firstName}{" "}
                                    {enquiry.user.lastName}
                                  </p>
                                </td>
                                <td className="w-[30%] md:px-6 md:py-4 lg:w-[20%]">
                                  <div className="text-[12px] text-gray-500 md:text-sm ">
                                    Dates requested
                                  </div>
                                  <p className="text-[13px]">
                                    {formatDateRange(
                                      enquiry.startDate,
                                      enquiry.endDate,
                                    )}
                                  </p>
                                </td>
                                <td
                                  className="md:px-6 md:py-4 lg:flex lg:w-full lg:items-center lg:justify-between"
                                  colSpan="2"
                                >
                                  {/* This div will contain the message status and only shows on large screens */}
                                  <div className="hidden lg:mr-4 lg:flex lg:flex-col">
                                    <div className="text-[12px] text-gray-500 md:text-sm">
                                      Messages
                                    </div>
                                    <div>
                                      {enquiry.unreadMessagesCount > 0 ? (
                                        <strong>
                                          {enquiry.unreadMessagesCount} unread
                                          message(s)
                                        </strong>
                                      ) : (
                                        "No unread messages"
                                      )}
                                    </div>
                                  </div>
                                  {/* This is your View link/button */}
                                  <Link
                                    to={`/enquirydetails/${listing.id}/${enquiry.userId}/${enquiry.id}`}
                                    className="relative flex items-center justify-center rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700 lg:px-6"
                                  >
                                    View
                                    {enquiry.unreadMessagesCount > 0 && (
                                      <span className="absolute right-0 top-0 inline-flex h-5 w-5 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white">
                                        {enquiry.unreadMessagesCount}
                                      </span>
                                    )}
                                  </Link>
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {isDeleteModalOpen && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="rounded bg-white p-4">
            <p>Are you sure you want to delete this listing?</p>
            <div className="mt-4 flex justify-between">
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="rounded bg-gray-300 px-4 py-2 text-black"
              >
                Cancel
              </button>
              <button
                onClick={() => deleteListing(selectedListingId)}
                className="rounded bg-red-500 px-4 py-2 text-white"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <AddListing
        isModalOpen={isModalOpen}
        closeModal={() => setModalOpen(false)}
      />
      <UnverifiedUserModal
        isModalOpen={isUnverifiedModalOpen}
        closeModal={() => setUnverifiedModalOpen(false)}
      />
    </div>
  );
};

export default UserListings;
