import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const LocationDropdown = ({ locationInput, setLocationInput, filteredOptions, handleLocationSelect }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 text-sm md:text-[15px]">
      <div className="relative mb-4">
        <FontAwesomeIcon
          icon={faSearch}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"
        />
        <input
          type="text"
          placeholder="Search county"
          className="w-full pl-12 pr-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-200"
          value={locationInput}
          onChange={(e) => setLocationInput(e.target.value)} // Update state directly
        />
      </div>
      <div className="h-48 overflow-y-auto border-t border-b border-gray-200">
        <ul className="list-none">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              className="cursor-pointer py-2 px-4 border-b last:border-none hover:bg-blue-100 transition-colors duration-150"
              onClick={() => handleLocationSelect(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
      <div className="border-t border-gray-300 py-3 text-center">
        <button
          className="px-4 py-2 flex items-center justify-center text-sm font-semibold text-red-500 hover:text-red-700 transition-colors duration-200"
          onClick={() => handleLocationSelect("Anywhere")}
        >
          <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
          Clear Location
        </button>
      </div>
    </div>
  );
};

export default LocationDropdown;
