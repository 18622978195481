import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase-config";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Input from "../components/input";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [isSignupComplete, setIsSignupComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // List of allowed college email domains and their corresponding university names and emblems
  const collegeData = {
    "@gmail.com": {
      name: "Gmail univeristy",
      emblem: "tcd-emblem.png",
      shortName: "GU",
    },
    "@universityofgalway.ie": {
      name: "NUI Galway",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2Fnuig.png?alt=media&token=8a5c13f9-d054-4f4f-8ed9-4bfcd2d7c7c3",
      shortName: "NUIG",
    },
    "@studentmail.ul.ie": {
      name: "University of Limerick",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FUL.png?alt=media&token=45a6e825-5668-42db-b1d7-00ef58973c4a",
      shortName: "UL",
    },
    "mumail.ie": {
      name: "Maynooth University",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FMU.png?alt=media&token=b4e01b90-2cdf-47c9-abe8-e5462f137ce0",
      shortName: "MU",
    },
    "@mytudublin.ie": {
      name: "Technological University Dublin",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FTUD.png?alt=media&token=b547e543-3906-4d47-973e-77f3100590f1",
      shortName: "TUD",
    },
    "@atu.ie": {
      name: "Atlantic Technological University",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FATU.png?alt=media&token=61f8c8b2-c8c4-4a56-bf53-6c57d645bbfe",
      shortName: "ATU",
    },
    "@student.iadt.ie": {
      name: "Institute of Art, Design and Technology",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FIADT.png?alt=media&token=7f0ac7ab-df59-4226-9d87-0ed5dc60840c",
      shortName: "IADT",
    },
    "@micstudent.mic.ul.ie": {
      name: "Mary Immaculate College",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FMIC.png?alt=media&token=552f1948-de2e-4c26-8186-b1013530d986",
      shortName: "MIC",
    },
    "@rcsi.com": {
      name: "Royal College of Surgeons Ireland",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FRCSI.png?alt=media&token=7624bb87-538b-4b5f-92cd-b2c666247d35",
      shortName: "RCSI",
    },
    "@student.ncad.ie": {
      name: "National College of Art and Design",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2Fncad.png?alt=media&token=679641e1-5ae7-498d-846e-c5e38b61f142",
      shortName: "NCAD",
    },
    "@student.griffith.ie": {
      name: "Griffith College Dublin",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FGriffith.png?alt=media&token=164326ac-4eaa-4672-94f3-db5286779515",
      shortName: "GCD",
    },
    "@mymtu.ie": {
      name: "Munster Technological University",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FMTU.png?alt=media&token=0463bfd6-958f-4065-8283-21603fa3952b",
      shortName: "MTU",
    },
    "@tcd.ie": {
      name: "Trinity College Dublin",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FTCD.png?alt=media&token=d2c2ee26-aa6a-4000-ac72-bfbebdf4ce87",
      shortName: "TCD",
    },
    "@ucdconnect.ie": {
      name: "University College Dublin",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FUCD.png?alt=media&token=c265a552-cac3-44b6-98bc-9b195bb36ad2",
      shortName: "UCD",
    },
    "@mail.dcu.ie": {
      name: "Dublin City University",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2FDCU.png?alt=media&token=951a1952-54dd-446d-bb93-bb9adee72d43",
      shortName: "DCU",
    },
    "@umail.ucc.ie": {
      name: "University College Cork",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-cd03c.appspot.com/o/university_emblems%2Fucc.png?alt=media&token=f65948cc-8dca-4a85-8c90-c43ae3e63254",
      shortName: "UCC",
    },
  };

  // Create a new user with email and password
  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== reenterPassword) {
      setError("Passwords do not match.");
      return;
    }

    const domain = Object.keys(collegeData).find((d) => email.endsWith(d));
    if (!domain) {
      setError(
        "Sign up is restricted to certain college email addresses only.",
      );
      return;
    }

    try {
      setIsLoading(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;

      const formattedFirstName = capitalizeFirstLetter(firstName);
      const formattedLastName = capitalizeFirstLetter(lastName);

      await updateProfile(user, {
        displayName: `${formattedFirstName} ${formattedLastName}`,
      });
      await sendEmailVerification(user);

      // Additional user data including university name and emblem
      const userData = {
        email: email,
        firstName: formattedFirstName,
        lastName: formattedLastName,
        university: collegeData[domain].name,
        universityEmblem: collegeData[domain].emblem,
        shortName: collegeData[domain].shortName,
        cardDetails: false,
      };

      await setDoc(doc(db, "users", user.uid), userData);

      await signOut(auth);

      console.log("User created and signed out successfully");
      setIsSignupComplete(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (isSignupComplete) {
    return (
      <div className="flex min-h-screen items-center justify-center px-2">
        <div className="w-full max-w-md rounded-lg bg-white p-6 px-4 shadow-md sm:px-6 lg:px-8">
          <h2 className="mb-4 text-2xl font-extrabold text-gray-900">
            Signup Successful!
          </h2>
          <p>
            Please check your email <strong>{email}</strong> for a verification
            link to activate your account.
          </p>
          <p className="mt-4">
            <Link
              to="/login"
              className="font-medium text-purple-600 hover:text-purple-500"
            >
              Return to Login
            </Link>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-6 flex min-h-screen items-center justify-center bg-gray-100 px-2 pb-10">
      <div className="mt-10 w-full max-w-md rounded-lg bg-white p-6 pt-4 shadow-md">
        <h2 className="mb-6 text-center text-2xl font-extrabold text-gray-900">
          Sign up for an account
        </h2>
        {error && (
          <p className="mb-2 text-center text-sm text-red-500">{error}</p>
        )}
        <form onSubmit={handleSignup}>
        <div className="space-y-3">
            <div className="grid grid-cols-2 gap-2">
              <Input
                name="firstName"
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                colour="purple-500"
                htmlFor="firstName"
                label="First Name"
              />
              <Input
                name="lastName"
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                colour="purple-500"
                htmlFor="lastName"
                label="Last Name"
              />
            </div>
            <Input
              name="email"
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              colour="purple-500"
              htmlFor="email"
              label="Email"
            />
            <Input
              name="password"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              colour="purple-500"
              htmlFor="password"
              label="Password"
            />
            <Input
              name="reenterPassword"
              id="reenterPassword"
              type="password"
              value={reenterPassword}
              onChange={(e) => setReenterPassword(e.target.value)}
              colour="purple-500"
              htmlFor="reenterPassword"
              label="Re-enter Password"
            />

            <div className="mt-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox cursor-pointer h-4 w-4"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  required
                />
                <span className="ml-2 text-xs">
                  I accept the{" "}
                  <Link to="/terms" className="text-purple-500 hover:text-purple-500">Terms and Conditions</Link> and{" "}
                  <Link to="/privacy" className="text-purple-500 hover:text-purple-500">Privacy Policy</Link>.
                </span>
              </label>
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              disabled={isLoading}
              className="hover:bg-purple-500-700 bg-purple-500 group relative flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {isLoading ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin" />{" "}
                  Signing up...
                </>
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
        </form>
        <div className="mt-4 text-center">
          <p className="text-sm">
            Already have an account?{" "}
            <Link
              to="/login"
              className="font-medium text-purple-600 hover:text-purple-500"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
