import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "../../firebase-config";
import { collection, addDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faBed,
  faHouse,
  faCalendar,
  faMoneyBill,
  faUser,
  faU,
  faTrash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { functions } from "../../firebase-config";
import { httpsCallable } from "firebase/functions";
import ModalTemplate from "./modalTemplate";
import Tooltip from "../tooltip";
import Input from "../input";
const AddListing = ({ isModalOpen, closeModal }) => {
  const ModalContent = ({ closeModal }) => {
    const [user] = useAuthState(auth);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const totalPages = 5;
    const [tenantCounts, setTenantCounts] = useState({
      maleTenant: 0,
      femaleTenant: 0,
    });
    const [errors, setErrors] = useState({});
    const [imageFiles, setImageFiles] = useState([]); // Store the image files
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [rentSchedule, setRentSchedule] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [formData, setFormData] = useState({
      streetAddress: "",
      city: "",
      county: "",
      postalCode: "",
      roomType: "",
      rent: "",
      description: "",
      title: "",
      propertyType: "",
      listerId: user ? user.uid : null,
      startDate: [],
      endDate: [],
    });
    const prevInputsRef = useRef({ startDate, endDate, rent: formData.rent });

    const validatePageFields = () => {
      let newErrors = {};
      switch (currentPage) {
        case 0:
          if (!formData.streetAddress) newErrors.streetAddress = true;
          if (!formData.city) newErrors.city = true;
          if (!formData.county) newErrors.county = true;
          if (!formData.postalCode) newErrors.postalCode = true;
          break;
        case 1:
          if (!formData.roomType) newErrors.roomType = true;
          if (!formData.rent) newErrors.rent = true;
          break;
        case 2:
          if (!formData.propertyType) newErrors.propertyType = true;
          if (!formData.description) newErrors.description = true;
          break;
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
      if (userFirstName) {
        setFormData({ ...formData, title: `${userFirstName}'s Room` });
      }
    }, [userFirstName]);

    const fetchRentSchedule = async () => {
      setIsLoading(true);
      const calculateSchedule = httpsCallable(
        functions,
        "calculateschduleaccommform"
      );

      try {
        const response = await calculateSchedule({
          startingDate: startDate.toISOString().split("T")[0],
          endingDate: endDate.toISOString().split("T")[0],
          mthRnt: parseFloat(formData.rent),
        });

        setRentSchedule(response.data.schdule);
        prevInputsRef.current = { startDate, endDate, rent: formData.rent }; // Update previous values
      } catch (error) {
        console.error("Error fetching rent schedule:", error);
      }
      setIsLoading(false);
    };

    useEffect(() => {
      const intervalId = setInterval(() => {
        const {
          startDate: prevStartDate,
          endDate: prevEndDate,
          rent: prevRent,
        } = prevInputsRef.current;
        if (
          startDate !== prevStartDate ||
          endDate !== prevEndDate ||
          formData.rent !== prevRent
        ) {
          fetchRentSchedule(); // Call only if inputs have changed
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }, [startDate, endDate, formData.rent, formData]);

    useEffect(() => {
      const fetchUserData = async () => {
        if (user && db) {
          try {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              if (userData.firstName && userData.lastName) {
                setUserFirstName(userData.firstName);
                setUserLastName(userData.lastName);
              } else {
                console.log("First name not found in user data");
              }
            } else {
              console.log("No user data found for this userID");
            }
          } catch (error) {
            console.error("Error fetching user data: ", error);
          }
        }
      };

      fetchUserData();
    }, [user, db]);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
    };

    const incrementCount = (tenant) => {
      setTenantCounts((prevCounts) => ({
        ...prevCounts,
        [tenant]: prevCounts[tenant] + 1,
      }));
    };

    const decrementCount = (tenant) => {
      setTenantCounts((prevCounts) => ({
        ...prevCounts,
        [tenant]: Math.max(prevCounts[tenant] - 1, 0), // prevents going below 0
      }));
    };

    const nextPage = () => {
      if (validatePageFields()) {
        setCurrentPage(currentPage + 1);
        setErrors({}); // Reset errors when moving to next page
      }
    };

    const inputClass = (fieldName) =>
      `px-3 py-2 border bg-white border-2 ${
        errors[fieldName] ? "border-red-500" : "border-gray-300"
      } rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500`;

    const handleChange = (e) => {
      const { name, value } = e.target;

      // Check if the input is for the postalCode
      if (name === "postalCode") {
        // Remove any spaces and convert to uppercase
        const formattedValue = value
          .replace(/\s/g, "")
          .toUpperCase()
          .slice(0, 7);
        setFormData({ ...formData, [name]: formattedValue });
      } else {
        // Handle other inputs normally
        setFormData({ ...formData, [name]: value });
      }
    };

    const handleImageChange = (e) => {
      const files = Array.from(e.target.files);
      const totalImages = imageFiles.length + files.length;

      if (totalImages > 6) {
        alert("You can only upload up to 6 images.");
        return;
      }

      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
              const img = new Image();
              img.src = e.target.result;
              img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Target dimensions and aspect ratio
                const desiredWidth = 600; // Adjust to your needs
                const desiredHeight = 400; // Adjust to your needs
                const targetAspectRatio = desiredWidth / desiredHeight;

                // Calculate image aspect ratio
                const imageAspectRatio = img.width / img.height;

                // Determine scale factor based on aspect ratio difference
                let scaleFactor;
                const aspectRatioDifference = Math.abs(
                  targetAspectRatio - imageAspectRatio
                );

                if (aspectRatioDifference < 0.5) {
                  // Adjust this value to control cropping
                  // Minor differences: Scale to completely fill the target dimensions
                  scaleFactor = Math.max(
                    desiredWidth / img.width,
                    desiredHeight / img.height
                  );
                } else {
                  // Major differences: Scale less aggressively to avoid excessive cropping
                  scaleFactor =
                    Math.min(
                      desiredWidth / img.width,
                      desiredHeight / img.height
                    ) * 1.25; // Adjust scaling factor to control cropping
                }

                const newWidth = img.width * scaleFactor;
                const newHeight = img.height * scaleFactor;

                // Set canvas size
                canvas.width = desiredWidth;
                canvas.height = desiredHeight;

                // Calculate offsets for centering the image
                const xOffset = (desiredWidth - newWidth) / 2;
                const yOffset = (desiredHeight - newHeight) / 2;

                // Fill with a background color to handle transparency or excess space
                ctx.fillStyle = "#FFFFFF"; // Change to desired background color
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                // Draw the image on the canvas
                ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);

                canvas.toBlob((blob) => {
                  const newFile = new File([blob], file.name, {
                    type: "image/jpeg",
                    lastModified: Date.now(),
                  });
                  resolve({
                    file: newFile,
                    preview: URL.createObjectURL(newFile),
                  });
                }, "image/jpeg");
              };
            };
            reader.onerror = reject;
          });
        })
      )
        .then((results) => {
          // Update state with new files and previews
          const newFiles = results.map((result) => result.file);
          const newPreviews = results.map((result) => result.preview);
          setImageFiles([...imageFiles, ...newFiles]);
          setImagePreviews([...imagePreviews, ...newPreviews]);
        })
        .catch((error) => {
          console.error("Error processing files: ", error);
        });
    };

    const moveImage = (dragIndex, hoverIndex, e) => {
      e.preventDefault();
      const dragImage = imageFiles[dragIndex];
      const newImageFiles = [...imageFiles];
      newImageFiles.splice(dragIndex, 1);
      newImageFiles.splice(hoverIndex, 0, dragImage);

      const dragPreview = imagePreviews[dragIndex];
      const newImagePreviews = [...imagePreviews];
      newImagePreviews.splice(dragIndex, 1);
      newImagePreviews.splice(hoverIndex, 0, dragPreview);

      setImageFiles(newImageFiles);
      setImagePreviews(newImagePreviews);
    };

    const removeImage = (index, e) => {
      e.preventDefault();
      const newImageFiles = [...imageFiles];
      newImageFiles.splice(index, 1);
      setImageFiles(newImageFiles);

      const newImagePreviews = [...imagePreviews];
      newImagePreviews.splice(index, 1);
      setImagePreviews(newImagePreviews);
    };

    const uploadImagesAndGetURLs = async (files) => {
      const uploadPromises = files.map((file) => {
        const storageRef = ref(storage, `images/${file.name}`);
        return uploadBytes(storageRef, file).then((snapshot) =>
          getDownloadURL(snapshot.ref)
        );
      });
      return Promise.all(uploadPromises);
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitLoading(true);
      try {
        if (!user) {
          throw new Error("User must be logged in to submit a listing");
        }

        const imageUrls = await uploadImagesAndGetURLs(imageFiles);

        const docRef = await addDoc(collection(db, "Listings"), {
          ...formData,
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
          listerId: user.uid,
          imageUrls: imageUrls,
          maleTenants: tenantCounts.maleTenant,
          femaleTenants: tenantCounts.femaleTenant,
          listerName: userFirstName + " " + userLastName,
        });
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userDocData = userDocSnap.data();
        const updatedListings = userDocData.listings
          ? [...userDocData.listings, docRef.id]
          : [docRef.id];
        await updateDoc(userDocRef, {
          listings: updatedListings,
        });
        setIsSubmitLoading(false);
        closeModal();
        window.location.reload();
        console.log("Document written with ID: ", docRef.id);
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    };

    const renderProgressBar = () => {
      let pageHeader = "";
      switch (currentPage) {
        case 0:
          pageHeader = "Location";
          break;
        case 1:
          pageHeader = "Rent Details";
          break;
        case 2:
          pageHeader = "House Details";
          break;
        case 3:
          pageHeader = "Images";
          break;
        case 4:
          pageHeader = "Review";
          break;
        default:
          pageHeader = "";
          break;
      }
      return (
        <div className="mb-4">
          <div className="mb-1 text-center text-lg font-semibold text-gray-700">
            {pageHeader}
          </div>
          <div className="h-2 w-full rounded-full bg-gray-200">
            <div
              className="h-2 rounded-full bg-purple-600"
              style={{ width: `${((currentPage + 1) / totalPages) * 100}%` }}
            ></div>
          </div>
        </div>
      );
    };

    const tenantTypes = [
      { key: "maleTenant", label: "Male" },
      { key: "femaleTenant", label: "Female" },
    ];

    const handleStartDateChange = (date) => {
      setStartDate(date);
      if (date > endDate) {
        setEndDate(date);
      }
    };

    const handleEndDateChange = (date) => {
      setEndDate(date);
      if (date < startDate) {
        setStartDate(date);
      }
    };

    const prevPage = () => {
      setCurrentPage(currentPage - 1);
    };

    const renderPage = () => {
      switch (currentPage) {
        case 0:
          return (
            <>
              <div className="space-y-4 mt-10 md:px-4">
                <Input
                  name="streetAddress"
                  id="streetAddress"
                  type="text"
                  value={formData.streetAddress}
                  onChange={handleChange}
                  colour="purple-500"
                  htmlFor="streetAddress"
                  label="Street Address"
                />
                <Input
                  name="city"
                  id="city"
                  type="text"
                  value={formData.city}
                  onChange={handleChange}
                  colour="purple-500"
                  htmlFor="city"
                  label="Town/City"
                />
                <div className="items-left flex flex-col">
                  <select
                    required
                    name="county"
                    className={`${inputClass("county")} cursor-pointer`}
                    value={formData.county}
                    onChange={handleChange}
                  >
                    <option value="">Select County...</option>
                    <option value="Antrim">Antrim</option>
                    <option value="Armagh">Armagh</option>
                    <option value="Carlow">Carlow</option>
                    <option value="Cavan">Cavan</option>
                    <option value="Clare">Clare</option>
                    <option value="Cork">Cork</option>
                    <option value="Derry">Derry</option>
                    <option value="Donegal">Donegal</option>
                    <option value="Down">Down</option>
                    <option value="Dublin">Dublin</option>
                    <option value="Fermanagh">Fermanagh</option>
                    <option value="Galway">Galway</option>
                    <option value="Kerry">Kerry</option>
                    <option value="Kildare">Kildare</option>
                    <option value="Kilkenny">Kilkenny</option>
                    <option value="Laois">Laois</option>
                    <option value="Leitrim">Leitrim</option>
                    <option value="Limerick">Limerick</option>
                    <option value="Longford">Longford</option>
                    <option value="Louth">Louth</option>
                    <option value="Mayo">Mayo</option>
                    <option value="Meath">Meath</option>
                    <option value="Monaghan">Monaghan</option>
                    <option value="Offaly">Offaly</option>
                    <option value="Roscommon">Roscommon</option>
                    <option value="Sligo">Sligo</option>
                    <option value="Tipperary">Tipperary</option>
                    <option value="Tyrone">Tyrone</option>
                    <option value="Waterford">Waterford</option>
                    <option value="Westmeath">Westmeath</option>
                    <option value="Wexford">Wexford</option>
                    <option value="Wicklow">Wicklow</option>
                  </select>
                </div>
                <Input
                  name="postalCode"
                  id="postalCode"
                  type="text"
                  value={formData.postalCode}
                  onChange={handleChange}
                  colour="purple-500"
                  htmlFor="postalCode"
                  label="Eircode"
                />
              </div>
            </>
          );
        case 1:
          return (
            <>
              <div className="space-y-8 md:px-4">
                <div className="grid grid-cols-2 items-center gap-4 md:gap-8">
                  <div className="items-left flex flex-col">
                 
                    <div>
                      <select
                        name="roomType"
                        className={`${inputClass(
                          "roomType"
                        )} w-full cursor-pointer`}
                        value={formData.roomType}
                        onChange={handleChange}
                      >
                        <option value="">Select Room Type...</option>
                        <option value="Single Room">Single Room</option>
                        <option value="Double Room">Double Room</option>
                      </select>
                    </div>
                  </div>
                  <Input
                    name="rent"
                    id="rent"
                    type="number"
                    value={formData.rent}
                    onChange={handleChange}
                    colour="purple-500"
                    htmlFor="rent"
                    label="Monthly Rent"
                  />
                  <div className="items-left flex flex-col">
                    <p className="text-sm font-semibold text-gray-700">
                      Start Date
                    </p>
                    <DatePicker
                      minDate={new Date()}
                      showPopperArrow={false}
                      dateFormat={"dd/MM/yyyy"}
                      selected={startDate}
                      onChange={handleStartDateChange}
                      className="w-full cursor-pointer rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="items-left flex flex-col">
                    <p className="text-sm font-semibold text-gray-700">
                      End Date
                    </p>
                    <DatePicker
                      minDate={new Date()}
                      showPopperArrow={false}
                      dateFormat={"dd/MM/yyyy"}
                      selected={endDate}
                      onChange={handleEndDateChange}
                      className="w-full cursor-pointer rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                </div>
                <div className="">
                  <h3 className="text-md mb-2 font-semibold">
                    Rent Collection{" "}
                    <Tooltip tooltipText="This shows the day each month that rent will be collected and transferred to you. The transfer process may take a few days. There will be a small 5% charge on each transaction. The rent for the first and last months are calculated as a proportion of the days stayed during the month" />
                  </h3>
                  {isLoading ? (
                    <div>Loading...</div>
                  ) : rentSchedule.length > 0 ? (
                    <div
                      style={{
                        overflowX: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <ul className="flex flex-row justify-start space-x-4 py-1">
                        {rentSchedule.map((item, index) => (
                          <li
                            key={index}
                            style={{ flex: "0 0 auto" }}
                            className="flex flex-col items-center overflow-hidden rounded border border-gray-300 shadow-lg"
                          >
                            <p className="flex w-full items-center justify-center bg-purple-500 p-2 text-sm text-white">
                              {formatDate(item.date)}
                            </p>
                            <p className="flex w-full items-center justify-center p-2 text-sm">
                              €{item.rent.toFixed(0)}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <p>
                      No schedule available. Please enter all required details.
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        case 2:
          return (
            <>
              <div className="space-y-4 md:px-4">
                <div className="items-left flex w-full flex-col">
                  <p className="text-sm font-semibold text-gray-700">
                    Property Type
                  </p>
                  <select
                    name="propertyType"
                    className={`${inputClass("propertyType")} cursor-pointer `}
                    value={formData.propertyType}
                    onChange={handleChange}
                  >
                    <option value="">Select Property Type...</option>
                    <option value="Apartment">Apartment</option>
                    <option value="House">House</option>
                  </select>
                </div>
                <div>
                  <div className="items-left flex flex-col">
                    <p className="text-sm font-semibold text-gray-700">
                      Other Tenants
                    </p>
                    <div className="flex flex-row items-center justify-center space-x-24">
                      {tenantTypes.map((tenantType) => (
                        <div
                          key={tenantType.key}
                          className="flex flex-col items-center"
                        >
                          <label className="mb-1">{tenantType.label}</label>
                          <div className="flex h-10 flex-row items-center rounded-lg border">
                            <button
                              type="button"
                              className="text-md h-full w-4 rounded-l bg-gray-200 text-gray-700 hover:bg-gray-400"
                              onClick={() => decrementCount(tenantType.key)}
                            >
                              -
                            </button>
                            <div className="text-md mx-1">
                              <FontAwesomeIcon
                                icon={faUser}
                                className="mr-1 text-gray-600"
                              />
                              {tenantCounts[tenantType.key]}
                            </div>
                            <button
                              type="button"
                              className="text-md h-full  w-4 rounded-r bg-gray-200 text-gray-700 hover:bg-gray-400"
                              onClick={() => incrementCount(tenantType.key)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <Input
                  name="description"
                  id="description"
                  type="textarea"
                  value={formData.description}
                  onChange={handleChange}
                  colour="purple-500"
                  htmlFor="description"
                  label="Description"
                />
              </div>
            </>
          );
        case 3:
          return (
            <>
              <div className="h-[300px] overflow-y-scroll px-2">
                <p>Upload photos of your sublet here!</p>
                <div className="mt-1 grid grid-cols-1 gap-2 md:grid-cols-2">
                  {Array.from({
                    length: Math.min(imagePreviews.length + 1, 6),
                  }).map((_, index) => (
                    <div
                      key={index}
                      className="relative overflow-hidden rounded-md border border-gray-300"
                    >
                      {imagePreviews[index] ? (
                        <>
                          <img
                            src={imagePreviews[index]}
                            alt={`Preview ${index + 1}`}
                            className="h-auto w-auto"
                          />
                          <button
                            onClick={(e) => removeImage(index, e)}
                            className="absolute right-1 top-0 rounded-full p-1 text-red-500 hover:text-red-700"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <div className="absolute left-1 top-1 h-6 w-6 rounded-full border border-gray-400 bg-white text-center font-bold">
                            {index + 1}
                          </div>
                          <div className="">
                            <button
                              onClick={(e) => moveImage(index, index - 1, e)}
                              disabled={index === 0}
                              className="text-bold absolute left-1 top-16 rounded bg-gray-500 px-3 pb-1 text-2xl text-white disabled:hidden"
                            >
                              -
                            </button>
                            <button
                              onClick={(e) => moveImage(index, index + 1, e)}
                              disabled={index === imagePreviews.length - 1}
                              className="text-bold absolute right-1 top-16 rounded bg-gray-500 px-2.5 py-1 text-xl text-white  disabled:hidden"
                            >
                              +
                            </button>
                          </div>
                        </>
                      ) : (
                        <label className="flex h-40 w-auto cursor-pointer flex-col items-center justify-center rounded-md  border-2 border-gray-300 p-8 text-gray-500 hover:bg-gray-100">
                          <div className="text-center">
                            <p className="text-lg">Add Image</p>
                            <p className="text-2xl">+</p>
                          </div>
                          <input
                            type="file"
                            onChange={handleImageChange}
                            multiple
                            disabled={imagePreviews.length >= 6}
                            className="hidden"
                          />
                        </label>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          );
        case 4:
          return (
            <>
              <div className="h-[300px] overflow-y-scroll">
                <div className="mb-2 text-2xl font-extrabold">
                  {formData.title}
                </div>
                <div>
                  <p className="mb-2 text-lg font-semibold">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="mr-2 mt-2 w-6 text-gray-500"
                    />
                    {formData.streetAddress}, {formData.city}, {formData.county}
                    , {formData.postalCode}
                  </p>
                </div>
                <div className="mb-2 text-lg">
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="mr-2 mt-2 w-6 text-gray-500"
                  />{" "}
                  {formatDate(startDate)} - {formatDate(endDate)}
                </div>
                <div className="mb-2 grid grid-cols-2 text-lg">
                  <div>
                    <FontAwesomeIcon
                      icon={faBed}
                      className="mr-2 mt-2 w-6 text-gray-500"
                    />{" "}
                    {formData.roomType}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      className="mr-2 mt-2 w-6 text-gray-500"
                    />{" "}
                    €{formData.rent}/month
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faHouse}
                      className="mr-2 mt-2 w-6 text-gray-500"
                    />{" "}
                    {formData.propertyType}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faUser}
                      className="mr-2 mt-2 w-6 text-gray-500"
                    />{" "}
                    {tenantCounts.maleTenant} Male, {tenantCounts.femaleTenant}{" "}
                    Female
                  </div>
                </div>
                <div className="mb-6">
                  <strong>Description -</strong> {formData.description}
                </div>
                <div className="">
                  <div className="mt-2 grid grid-cols-2 gap-2">
                    {imagePreviews.map((src, index) => (
                      <img
                        key={index}
                        src={src}
                        alt={`Preview ${index}`}
                        className="h-auto w-full border border-gray-300"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          );
      }
    };

    return (
      <div className="relative h-[420px] pb-16 md:w-[500px]">
        {renderProgressBar()}
        <form onSubmit={handleSubmit}>
          {renderPage()}
          <div className="absolute bottom-0 w-full">
            <div className="flex items-center  justify-between">
              {currentPage > 0 ? (
                <button
                  type="button"
                  onClick={prevPage}
                  className="rounded bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300"
                >
                  Previous
                </button>
              ) : (
                <div className="px-4 py-2"></div>
              )}
              {currentPage < 4 && (
                <button
                  type="button"
                  onClick={nextPage}
                  className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
                >
                  Next
                </button>
              )}
              {currentPage === 4 && (
                <button
                  type="submit"
                  className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? (
                    <>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="mr-2 animate-spin"
                      />{" "}
                      Submitting Listing...
                    </>
                  ) : (
                    "Submit Listing"
                  )}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  };
  return (
    <ModalTemplate
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      content={<ModalContent closeModal={closeModal} />}
      width="md:w-auto w-[95%]"
    />
  );
};

export default AddListing;
