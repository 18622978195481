import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db, storage } from "../firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PersonalProfile from "../components/profilecard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import ProfileUpdateModal from "../components/modals/profileUpdateModal";
import { useNavigate } from "react-router-dom";

export default function ProfilePage() {
  const [imageUpload, setImageUpload] = useState(null);
  const [user] = useAuthState(auth);
  const [isProfileUpdateModalOpen, setProfileUpdateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const DEFAULT_IMAGE_URL =
    "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    university: "",
    gender: "",
    instagram: "",
    nationality: "",
    email: "",
    photoURL: "",
  });
  const [editData, setEditData] = useState({}); // State to hold edits

  // fetch the logged in user details
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
          setEditData(userDocSnap.data());
        } else {
          console.log("No user data found!");
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const isProfileComplete = () => {
    const fieldsToCheck = [
      "firstName",
      "lastName",
      "age",
      "university",
      "gender",
      "nationality",
    ];
    return fieldsToCheck.every(
      (field) => userData[field] && userData[field].trim() !== "",
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Function to capitalize the first letter of a string
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // Check if the input change is for firstName or lastName and capitalize
    if (name === "firstName" || name === "lastName") {
      setEditData({ ...editData, [name]: capitalizeFirstLetter(value) });
    } else {
      setEditData({ ...editData, [name]: value });
    }
  };

  // Update the users profile
  const saveChanges = async (e) => {
    e.preventDefault(); // Prevents the form from submitting in the traditional way
    setIsLoading(true);

    if (auth.currentUser) {
      // Prepare the data to update
      const updatedData = {
        ...editData,
        isProfileComplete: true,
        photoURL: userData.photoURL, // Keep the current photoURL
      };

      // If an image is selected, upload it
      if (imageUpload != null) {
        const imageRef = ref(storage, `profile_images/${auth.currentUser.uid}`);
        await uploadBytes(imageRef, imageUpload);
        const photoURL = await getDownloadURL(imageRef);
        updatedData.photoURL = photoURL; // Update photoURL to new uploaded image
      } else if (!userData.photoURL) {
        updatedData.photoURL = DEFAULT_IMAGE_URL; // Use default image if no image is uploaded and no existing URL
      }

      // Save the updated data back to Firestore
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userDocRef, updatedData);

      // Update userData to reflect the new changes
      setUserData(updatedData);
      setProfileUpdateModalOpen(true);
      setIsLoading(false);
    } else {
      console.log("Invalid submission");
      setIsLoading(false);
    }
  };

  return (
    <section>
      <div className="mx-auto mb-24 mt-16 w-[90%] md:mt-24 md:w-[80%] md:px-4">
        <h1 className="mb-2 text-3xl font-semibold md:mb-5">My Profile</h1>
        <div className="flex flex-wrap">
          {/* Left Column */}
          <div className="mb-4 md:w-2/3 md:px-4">
            <PersonalProfile user={userData} />

            {/* User Card */}
          </div>
          <div className="mb-4 w-full md:w-1/3 md:px-4">
            <div className="mb-4 h-full space-y-4 rounded-lg border border-gray-200 bg-white px-5 py-6 shadow">
              <h5 className="mb-4 text-lg font-medium">Profile Verification</h5>
              <p
                className={
                  isProfileComplete()
                    ? "flex items-center text-green-600"
                    : "flex items-center text-red-600"
                }
              >
                <FontAwesomeIcon
                  icon={isProfileComplete() ? faCircleCheck : faCircleXmark}
                  className="mr-2"
                />
                {isProfileComplete()
                  ? "Your profile is complete."
                  : "Your profile is incomplete. Please fill out all fields."}
              </p>
              {userData.updateCard && (
                <button
                  type="button"
                  onClick={() => navigate("/updatecard")}
                  className="mt-4 rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
                >
                  Update Card Details
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="md:px-4">
          <div className="mb-4 rounded-lg border border-gray-200 bg-white px-5 py-4 shadow">
            <form onSubmit={saveChanges}>
              <div className="mb-4 flex items-center justify-between">
                <h5 className="text-xl font-medium">Edit Information</h5>

                <button
                  type="submit"
                  className="rounded bg-purple-500 px-4 py-2 font-semibold text-white hover:bg-purple-600"
                >
                  {isLoading ? (
                    <>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="mr-2 animate-spin"
                      />{" "}
                      Saving Changes...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>

              <div className="mb-4 flex flex-row items-center space-x-12"></div>
              <div className="grid grid-cols-8 gap-x-8 gap-y-4">
                {/* Editable fields */}
                <label className="col-span-3 md:col-span-1">
                  Profile Picture*
                </label>
                <input
                  type="file"
                  onChange={(e) => setImageUpload(e.target.files[0])}
                  className="col-span-5 md:col-span-7 md:mt-2"
                />
                <label className="col-span-3 md:col-span-1">First Name*</label>
                <input
                  type="text"
                  name="firstName"
                  required
                  value={editData.firstName}
                  onChange={handleInputChange}
                  className="col-span-5 rounded border border-gray-300 px-2 py-1 md:col-span-3 "
                  placeholder="First Name"
                />
                <label className="col-span-3 md:col-span-1">Last Name*</label>
                <input
                  type="text"
                  name="lastName"
                  required
                  value={editData.lastName}
                  onChange={handleInputChange}
                  className="col-span-5 rounded border border-gray-300 px-2 py-1 md:col-span-3 "
                  placeholder="Last Name"
                />
                <label className="col-span-3 md:col-span-1">Gender*</label>
                <select
                  name="gender"
                  required
                  value={editData.gender}
                  onChange={handleInputChange}
                  className="col-span-5 cursor-pointer rounded border border-gray-300 bg-white px-2 py-1 md:col-span-3"
                >
                  <option value="" className="text-gray-400">
                    Select Gender...
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>

                <label className="col-span-3 md:col-span-1">Age*</label>
                <input
                  type="number"
                  required
                  name="age"
                  value={editData.age}
                  onChange={handleInputChange}
                  className="col-span-5 rounded border border-gray-300 px-2 py-1 md:col-span-3 "
                  placeholder="age"
                />
                <label className="col-span-3 md:col-span-1">Nationality*</label>
                <select
                  name="nationality"
                  value={editData.nationality}
                  onChange={handleInputChange}
                  className="col-span-5 cursor-pointer rounded border border-gray-300 bg-white px-2 py-1 md:col-span-3"
                  required
                >
                  {" "}
                  <option value="" className="text-gray-400">
                    Select Nationality...
                  </option>
                  <option value="Afghan">Afghan</option>
                  <option value="Albanian">Albanian</option>
                  <option value="Algerian">Algerian</option>
                  <option value="American">American</option>
                  <option value="Andorran">Andorran</option>
                  <option value="Angolan">Angolan</option>
                  <option value="Antiguans">Antiguans</option>
                  <option value="Argentinean">Argentinean</option>
                  <option value="Armenian">Armenian</option>
                  <option value="Australian">Australian</option>
                  <option value="Austrian">Austrian</option>
                  <option value="Azerbaijani">Azerbaijani</option>
                  <option value="Bahamian">Bahamian</option>
                  <option value="Bahraini">Bahraini</option>
                  <option value="Bangladeshi">Bangladeshi</option>
                  <option value="Barbadian">Barbadian</option>
                  <option value="Barbudans">Barbudans</option>
                  <option value="Batswana">Batswana</option>
                  <option value="Belarusian">Belarusian</option>
                  <option value="Belgian">Belgian</option>
                  <option value="Belizean">Belizean</option>
                  <option value="Beninese">Beninese</option>
                  <option value="Bhutanese">Bhutanese</option>
                  <option value="Bolivian">Bolivian</option>
                  <option value="Bosnian">Bosnian</option>
                  <option value="Brazilian">Brazilian</option>
                  <option value="British">British</option>
                  <option value="Bruneian">Bruneian</option>
                  <option value="Bulgarian">Bulgarian</option>
                  <option value="Burkinabe">Burkinabe</option>
                  <option value="Burmese">Burmese</option>
                  <option value="Burundian">Burundian</option>
                  <option value="Bambodian">Cambodian</option>
                  <option value="Cameroonian">Cameroonian</option>
                  <option value="Canadian">Canadian</option>
                  <option value="Cape verdean">Cape Verdean</option>
                  <option value="Central african">Central African</option>
                  <option value="Chadian">Chadian</option>
                  <option value="Chilean">Chilean</option>
                  <option value="Chinese">Chinese</option>
                  <option value="Colombian">Colombian</option>
                  <option value="Comoran">Comoran</option>
                  <option value="Congolese">Congolese</option>
                  <option value="Costa rican">Costa Rican</option>
                  <option value="Croatian">Croatian</option>
                  <option value="Cuban">Cuban</option>
                  <option value="Cypriot">Cypriot</option>
                  <option value="Czech">Czech</option>
                  <option value="Danish">Danish</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominican">Dominican</option>
                  <option value="Dutch">Dutch</option>
                  <option value="East timorese">East Timorese</option>
                  <option value="Ecuadorean">Ecuadorean</option>
                  <option value="Egyptian">Egyptian</option>
                  <option value="Emirian">Emirian</option>
                  <option value="Equatorial guinean">Equatorial Guinean</option>
                  <option value="Eritrean">Eritrean</option>
                  <option value="Estonian">Estonian</option>
                  <option value="Ethiopian">Ethiopian</option>
                  <option value="Fijian">Fijian</option>
                  <option value="Filipino">Filipino</option>
                  <option value="Finnish">Finnish</option>
                  <option value="French">French</option>
                  <option value="Gabonese">Gabonese</option>
                  <option value="Gambian">Gambian</option>
                  <option value="Georgian">Georgian</option>
                  <option value="German">German</option>
                  <option value="Ghanaian">Ghanaian</option>
                  <option value="Greek">Greek</option>
                  <option value="Grenadian">Grenadian</option>
                  <option value="Guatemalan">Guatemalan</option>
                  <option value="Guinea-bissauan">Guinea-Bissauan</option>
                  <option value="Guinean">Guinean</option>
                  <option value="Guyanese">Guyanese</option>
                  <option value="Haitian">Haitian</option>
                  <option value="Herzegovinian">Herzegovinian</option>
                  <option value="Honduran">Honduran</option>
                  <option value="Hungarian">Hungarian</option>
                  <option value="Icelander">Icelander</option>
                  <option value="Indian">Indian</option>
                  <option value="Indonesian">Indonesian</option>
                  <option value="Iranian">Iranian</option>
                  <option value="Iraqi">Iraqi</option>
                  <option value="Irish">Irish</option>
                  <option value="Israeli">Israeli</option>
                  <option value="Italian">Italian</option>
                  <option value="Ivorian">Ivorian</option>
                  <option value="Jamaican">Jamaican</option>
                  <option value="Japanese">Japanese</option>
                  <option value="Jordanian">Jordanian</option>
                  <option value="Kazakhstani">Kazakhstani</option>
                  <option value="Kenyan">Kenyan</option>
                  <option value="Kittian and nevisian">
                    Kittian and Nevisian
                  </option>
                  <option value="Kuwaiti">Kuwaiti</option>
                  <option value="Kyrgyz">Kyrgyz</option>
                  <option value="Laotian">Laotian</option>
                  <option value="Latvian">Latvian</option>
                  <option value="Lebanese">Lebanese</option>
                  <option value="Liberian">Liberian</option>
                  <option value="Libyan">Libyan</option>
                  <option value="Liechtensteiner">Liechtensteiner</option>
                  <option value="Lithuanian">Lithuanian</option>
                  <option value="Luxembourger">Luxembourger</option>
                  <option value="Macedonian">Macedonian</option>
                  <option value="Malagasy">Malagasy</option>
                  <option value="Malawian">Malawian</option>
                  <option value="Malaysian">Malaysian</option>
                  <option value="Maldivan">Maldivan</option>
                  <option value="Malian">Malian</option>
                  <option value="Maltese">Maltese</option>
                  <option value="Marshallese">Marshallese</option>
                  <option value="Mauritanian">Mauritanian</option>
                  <option value="Mauritian">Mauritian</option>
                  <option value="Mexican">Mexican</option>
                  <option value="Micronesian">Micronesian</option>
                  <option value="Moldovan">Moldovan</option>
                  <option value="Monacan">Monacan</option>
                  <option value="Mongolian">Mongolian</option>
                  <option value="Moroccan">Moroccan</option>
                  <option value="Mosotho">Mosotho</option>
                  <option value="Motswana">Motswana</option>
                  <option value="Mozambican">Mozambican</option>
                  <option value="Namibian">Namibian</option>
                  <option value="Nauruan">Nauruan</option>
                  <option value="Nepalese">Nepalese</option>
                  <option value="New zealander">New Zealander</option>
                  <option value="Ni-vanuatu">Ni-Vanuatu</option>
                  <option value="Nicaraguan">Nicaraguan</option>
                  <option value="Nigerien">Nigerien</option>
                  <option value="North korean">North Korean</option>
                  <option value="Northern irish">Northern Irish</option>
                  <option value="Norwegian">Norwegian</option>
                  <option value="Omani">Omani</option>
                  <option value="Pakistani">Pakistani</option>
                  <option value="Palauan">Palauan</option>
                  <option value="Panamanian">Panamanian</option>
                  <option value="Papua new guinean">Papua New Guinean</option>
                  <option value="Paraguayan">Paraguayan</option>
                  <option value="Peruvian">Peruvian</option>
                  <option value="Polish">Polish</option>
                  <option value="Portuguese">Portuguese</option>
                  <option value="Qatari">Qatari</option>
                  <option value="Romanian">Romanian</option>
                  <option value="Russian">Russian</option>
                  <option value="Rwandan">Rwandan</option>
                  <option value="Saint lucian">Saint Lucian</option>
                  <option value="Salvadoran">Salvadoran</option>
                  <option value="Samoan">Samoan</option>
                  <option value="San marinese">San Marinese</option>
                  <option value="Sao tomean">Sao Tomean</option>
                  <option value="Saudi">Saudi</option>
                  <option value="Scottish">Scottish</option>
                  <option value="Senegalese">Senegalese</option>
                  <option value="Serbian">Serbian</option>
                  <option value="Seychellois">Seychellois</option>
                  <option value="Sierra leonean">Sierra Leonean</option>
                  <option value="Singaporean">Singaporean</option>
                  <option value="Slovakian">Slovakian</option>
                  <option value="Slovenian">Slovenian</option>
                  <option value="Solomon islander">Solomon Islander</option>
                  <option value="Somali">Somali</option>
                  <option value="South african">South African</option>
                  <option value="South korean">South Korean</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Sri lankan">Sri Lankan</option>
                  <option value="Sudanese">Sudanese</option>
                  <option value="Surinamer">Surinamer</option>
                  <option value="Swazi">Swazi</option>
                  <option value="Swedish">Swedish</option>
                  <option value="Swiss">Swiss</option>
                  <option value="Syrian">Syrian</option>
                  <option value="Taiwanese">Taiwanese</option>
                  <option value="Tajik">Tajik</option>
                  <option value="Tanzanian">Tanzanian</option>
                  <option value="Thai">Thai</option>
                  <option value="Togolese">Togolese</option>
                  <option value="Tongan">Tongan</option>
                  <option value="Trinidadian or tobagonian">
                    Trinidadian or Tobagonian
                  </option>
                  <option value="Tunisian">Tunisian</option>
                  <option value="Turkish">Turkish</option>
                  <option value="Tuvaluan">Tuvaluan</option>
                  <option value="Ugandan">Ugandan</option>
                  <option value="Ukrainian">Ukrainian</option>
                  <option value="Uruguayan">Uruguayan</option>
                  <option value="Uzbekistani">Uzbekistani</option>
                  <option value="Venezuelan">Venezuelan</option>
                  <option value="Vietnamese">Vietnamese</option>
                  <option value="Welsh">Welsh</option>
                  <option value="Yemenite">Yemenite</option>
                  <option value="Zambian">Zambian</option>
                  <option value="Zimbabwean">Zimbabwean</option>
                </select>
                <label className="col-span-3  md:col-span-1">Instagram</label>
                <input
                  type="text"
                  name="instagram"
                  value={editData.instagram}
                  onChange={handleInputChange}
                  className="col-span-5 rounded border border-gray-300 px-2 py-1 md:col-span-3 "
                  placeholder="Instagram (recommended)"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ProfileUpdateModal
        isModalOpen={isProfileUpdateModalOpen}
        closeModal={() => setProfileUpdateModalOpen(false)}
      />
    </section>
  );
}
