import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faLocationDot, faBed, faCalendar } from "@fortawesome/free-solid-svg-icons";
import ImageCarousel from "../components/imagecarousel";

const ListingCard = ({ listing, users, handleCardClick }) => {
  const placeholderImageUrl = "https://via.placeholder.com/150";
  const formatDateRange = (startStr, endStr) => {
    const options = { month: "short", day: "numeric" };
    const startDate = startStr ? new Date(startStr) : null;
    const endDate = endStr ? new Date(endStr) : null;

    return startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())
      ? `${startDate.toLocaleDateString("en-US", options)} - ${endDate.toLocaleDateString("en-US", options)}`
      : "Any Dates";
  };

  return (
    <div
      key={listing.id}
      className="relative cursor-pointer overflow-hidden rounded-lg bg-white shadow-lg"
      onClick={() => handleCardClick(listing.id)}
    >
      <div className="absolute left-2 top-2 z-10 rounded bg-white p-1 pr-2 text-[15px] text-gray-700 md:text-[15px]">
        <FontAwesomeIcon icon={faMoneyBill} className="mr-2 w-6 text-gray-500" />
        €{listing.rent}/month
      </div>
      <div className="absolute right-2 top-2 z-10 rounded bg-white p-1 pr-2 text-[15px] text-gray-700 md:text-[15px]">
        <FontAwesomeIcon icon={faCalendar} className="mr-2 w-6 text-gray-500" />
        {formatDateRange(listing.startDate, listing.endDate)}
      </div>
      <div className="w-full overflow-hidden">
        <ImageCarousel images={listing.imageUrls || []} />
      </div>
      <div className="flex pb-2">
        <div className="flex w-1/4 flex-col items-center justify-center">
          <div className="relative ml-4 mt-2 h-16 w-16">
            <div className="flex-shrink-0 overflow-hidden rounded-full bg-gray-200">
              <img
                src={users[listing.listerId]?.photoURL || placeholderImageUrl}
                alt="User"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <img
                src={users[listing.listerId]?.universityEmblem}
                alt="University Emblem"
                className="absolute bottom-0 right-0"
                style={{
                  width: "40%",
                  height: "auto",
                  borderRadius: "5px 0 0 0",
                  bottom: "-5px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="ml-8 mt-1 flex-grow text-[15px] text-gray-700">
          <p className="text-xl font-extrabold text-black md:mb-2">{listing.title}</p>
          <hr className="border-1 mr-2 border"></hr>
          <div className="ml-4 lg:grid lg:grid-cols-[3fr,2fr]">
            <div>
              <p className="lg:mb-4"><FontAwesomeIcon icon={faLocationDot} className="mr-2 mt-2 w-6 text-gray-500" />{listing.city}, {listing.county}</p>
            </div>
            <div>
              <p><FontAwesomeIcon icon={faBed} className="mr-2 mt-2 w-6 text-gray-500" />{listing.roomType}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
