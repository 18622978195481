import React from "react";

export default function ContactUs() {
  return (
    <section>
      <div className="mx-auto mb-24 mt-16 w-[90%] md:mt-20 md:px-4">
        <h1 className="mb-2 text-xl font-semibold md:mb-5 md:text-3xl">
          Contact Us
        </h1>
        <div>
          <p>
            If you have any queries please don't hesitate to contact us at{" "}
            <strong>info@lettz.ie</strong>
          </p>
        </div>
      </div>
    </section>
  );
}
