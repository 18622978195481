import React, { useState } from "react";
import { functions } from "../../firebase-config";
import { httpsCallable } from "firebase/functions";

const PaymentComponent = () => {
  const [customerId, setCustomerId] = useState(""); // Replace with actual customer ID
  const [amount, setAmount] = useState(""); // Replace with the amount to be charged

  const handlePayment = () => {
    const chargeCustomer = httpsCallable(functions, "chargeCustomer");
    chargeCustomer({ customerId: customerId, amount: parseInt(amount, 10) })
      .then((result) => {
        if (result.data.success) {
          console.log("Payment Intent created:", result.data);
          // Handle successful payment here
        }
      })
      .catch((error) => {
        console.error("Error charging customer:", error);
        // Handle errors here
      });
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-md">
      <div className="space-y-4">
        <input
          className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
          type="text"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
          placeholder="Customer ID"
        />
        <input
          className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Amount in cents"
        />
        <button
          onClick={handlePayment}
          className="focus:shadow-outline w-full rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-600 focus:outline-none"
        >
          Charge Customer
        </button>
      </div>
    </div>
  );
};

export default PaymentComponent;
