import React from "react";
import ModalTemplate from "./modalTemplate";

const SuccessModal = ({ isModalOpen, closeModal }) => {
  const reloader = () => {
    window.location.reload();
  };

  const modalContent = (
    <div>
      <h2 className="mb-2 text-lg font-semibold">Success!</h2>
      <p>
        Your enquiry has been sent successfully. Check My Enquiries for updates
      </p>
      <div className="flex flex-row justify-end">
        <button
          onClick={reloader}
          className="mt-4 rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
        >
          Close
        </button>
      </div>
    </div>
  );

  return (
    <ModalTemplate
      isModalOpen={isModalOpen}
      closeModal={reloader}
      content={modalContent}
      width="w-[90%] md:w-auto"
    />
  );
};

export default SuccessModal;
