import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import PersonalProfile from "../components/profilecard";
import FinalOfferModal from "../components/modals/finaloffermodal"; // Import FinalOfferModal
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faHouse,
  faBed,
  faMoneyBill,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import Chat from "../components/chat";
import ImageCarousel from "../components/imagecarousel";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";

const MyEnquiry = () => {
  const { listerId, listingId, enquiryId } = useParams();
  const [user] = useAuthState(auth);
  const [enquiryDetails, setEnquiryDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [listingDetails, setListingDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log({ listerId, listingId, enquiryId });

  // Get the listing details, lister details and enquiry details from the URL params
  useEffect(() => {
    const fetchEnquiryDetails = async () => {
      if (listingId && enquiryId) {
        const enquiryDocRef = doc(
          db,
          "Listings",
          listingId,
          "enquiries",
          enquiryId,
        );
        const enquirySnapshot = await getDoc(enquiryDocRef);
        const listingDocRef = doc(db, "Listings", listingId);
        const listingSnapshot = await getDoc(listingDocRef);
        if (enquirySnapshot.exists()) {
          setEnquiryDetails(enquirySnapshot.data());
        } else {
          console.log("No such enquiry!");
        }
        if (listingSnapshot.exists()) {
          setListingDetails(listingSnapshot.data());
        } else {
          console.log("No such listing!");
        }
      }

      if (listerId) {
        const userDocRef = doc(db, "users", listerId);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          setUserDetails({ id: userSnapshot.id, ...userSnapshot.data() });
        } else {
          console.log("No such user!");
        }
      }
    };

    console.log(enquiryDetails);
    fetchEnquiryDetails();
  }, [listerId, listingId, enquiryId]);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const navigate = useNavigate();

  const handleNavigate = (listingId) => {
    navigate(`/details/${listingId}`);
  };

  if (!userDetails || !enquiryDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-auto mb-32 mt-16 flex h-full w-[90%] flex-col md:mt-20 md:w-[80%]">
      <h1 className="mb-2 text-3xl font-semibold md:mb-4">Enquiry Details</h1>
      <div
        key={enquiryId}
        className="card mb-4 rounded-lg border border-gray-400 bg-white shadow-md"
      >
        <div className="hidden md:block">
          <div className="flex">
            <div className=" mx-2 mt-2 w-1/3 items-center overflow-hidden p-2">
              <ImageCarousel images={listingDetails?.imageUrls || []} />
            </div>
            <div className="mx-8 w-2/3 p-4">
              <h3
                className="mb-4 cursor-pointer text-3xl font-bold underline"
                onClick={() => handleNavigate(listingId)}
              >
                {listingDetails?.title}
              </h3>
              <div className="">
                <p className="text-lg">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="mr-2 mt-2 w-6 text-xl text-gray-500"
                  />{" "}
                  {listingDetails?.streetAddress}, {listingDetails.city},{" "}
                  {listingDetails.county}, {listingDetails.postalCode}
                </p>
                <div className="mx-8 mt-2 grid grid-cols-2 text-lg">
                  <p className="mb-2">
                    <FontAwesomeIcon
                      icon={faHouse}
                      className="mr-2 mt-2 w-6 text-gray-500"
                    />{" "}
                    {listingDetails.propertyType}
                  </p>
                  <p className="mb-2">
                    {" "}
                    <FontAwesomeIcon
                      icon={faBed}
                      className="mr-2 mt-2 w-6 text-gray-500"
                    />{" "}
                    {listingDetails.roomType}
                  </p>
                  <p className="mb-2">
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      className="mr-2 mt-2 w-6 text-gray-500"
                    />{" "}
                    €{enquiryDetails.rent}/month
                  </p>
                  <p className="mb-2">
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="mr-2 mt-2 w-6 text-gray-500"
                    />{" "}
                    {enquiryDetails?.startDate &&
                      new Date(enquiryDetails.startDate).toLocaleDateString(
                        "en-US",
                        { month: "short", day: "2-digit" },
                      )}{" "}
                    -{" "}
                    {enquiryDetails?.endDate &&
                      new Date(enquiryDetails.endDate).toLocaleDateString(
                        "en-US",
                        { month: "short", day: "2-digit" },
                      )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-1 md:hidden">
          <h3
            className="ml-2 cursor-pointer text-3xl font-bold underline"
            onClick={() => handleNavigate(listingId)}
          >
            {listingDetails?.title}
          </h3>
          <div className="mt-2 items-center overflow-hidden">
            <ImageCarousel images={listingDetails?.imageUrls || []} />
          </div>
          <div className="p-4 pt-2">
            <div className="">
              <p className="text-lg">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="w-4 text-lg text-gray-500"
                />{" "}
                {listingDetails?.streetAddress}, {listingDetails?.city},{" "}
                {listingDetails?.county}, {listingDetails?.postalCode}
              </p>
              <div className="text-md mt-2 grid grid-cols-2">
                <p className="mb-2">
                  <FontAwesomeIcon
                    icon={faHouse}
                    className="mr-1 mt-2 w-4 text-gray-500"
                  />{" "}
                  {listingDetails?.propertyType}
                </p>
                <p className="mb-2">
                  {" "}
                  <FontAwesomeIcon
                    icon={faBed}
                    className="mr-1 mt-2 w-4 text-gray-500"
                  />{" "}
                  {listingDetails?.roomType}
                </p>
                <p className="mb-2">
                  <FontAwesomeIcon
                    icon={faMoneyBill}
                    className="mr-1 mt-2 w-4 text-gray-500"
                  />{" "}
                  €{enquiryDetails?.rent}/month
                </p>
                <p className="mb-2">
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="mr-1 mt-2 w-4 text-gray-500"
                  />{" "}
                  {enquiryDetails?.startDate &&
                    new Date(enquiryDetails.startDate).toLocaleDateString(
                      "en-US",
                      { month: "short", day: "2-digit" },
                    )}{" "}
                  -{" "}
                  {enquiryDetails?.endDate &&
                    new Date(enquiryDetails.endDate).toLocaleDateString(
                      "en-US",
                      { month: "short", day: "2-digit" },
                    )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5 flex flex-col gap-5 md:flex-row">
        <div className="md:w-1/2">
          <PersonalProfile user={userDetails} />
        </div>

        <div className="relative flex-1 rounded-lg border border-gray-400 bg-white p-4 pb-12 shadow-md">
          <p className="text-2xl font-extrabold">Offer Details</p>

          {enquiryDetails.isActive ? (
            <div className="flex-2">
              <div className="mx-4 mb-4 grid grid-cols-2">
                <div>
                  <p className="mt-2 text-sm font-semibold text-gray-700">
                    Room
                  </p>
                  <p className="text-lg font-semibold">
                    {" "}
                    {enquiryDetails?.roomType}
                  </p>
                </div>
                <div>
                  <p className="mt-2 text-sm font-semibold text-gray-700">
                    Rent
                  </p>
                  <p className="text-lg font-semibold">
                    {" "}
                    €{enquiryDetails?.rent}/month
                  </p>
                </div>

                <div>
                  <p className="mt-2 text-sm font-semibold text-gray-700">
                    Move in
                  </p>
                  <p className="mb-2 text-lg font-semibold">
                    {" "}
                    {enquiryDetails?.startDate &&
                      new Date(enquiryDetails.startDate).toLocaleDateString(
                        "en-US",
                        { month: "short", day: "2-digit" },
                      )}
                  </p>
                </div>
                <div>
                  <p className="mt-2 text-sm font-semibold text-gray-700">
                    Move out
                  </p>
                  <p className="mb-2 text-lg font-semibold">
                    {" "}
                    {enquiryDetails?.endDate &&
                      new Date(enquiryDetails.endDate).toLocaleDateString(
                        "en-US",
                        { month: "short", day: "2-digit" },
                      )}
                  </p>
                </div>
              </div>
              {/* Button at the bottom-right */}
              <div className="absolute bottom-4 right-4">
                <button
                  className="flex rounded bg-purple-500 px-4 py-2 font-semibold text-white hover:bg-purple-600"
                  onClick={handleModalToggle}
                >
                  Accept offer and set up rent payments
                </button>
              </div>
            </div>
          ) : (
            <div className="py-6 text-center">
              <p className="text-gray-700">
                No offer has been sent yet. Once {userDetails.firstName} sends
                an offer, it will appear here, you will be notified and be able
                to accept the offer and set up the rent payments
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Chat Box */}
      <div>
        <Chat
          listingId={listingId}
          enquiryId={enquiryId}
          currentUser={user.uid}
          otherUser={userDetails}
        />
        {/* Chat functionality can be expanded here */}
      </div>

      <FinalOfferModal
        isModalOpen={isModalOpen}
        closeModal={handleModalToggle}
        rentSchedule={enquiryDetails.rentSchedule}
        listerId={listerId}
        listingId={listingId}
        enquiryId={enquiryId}
        startDate={enquiryDetails.startDate}
        endDate={enquiryDetails.endDate}
      />
    </div>
  );
};

export default MyEnquiry;
