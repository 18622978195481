import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faPerson,
  faBirthdayCake,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function PersonalProfile({ user }) {
  console.log("Photo URL:", user);

  const getInstagramUrl = (username) => {
    return `https://www.instagram.com/${username}`;
  };
  const placeholderImageUrl =
    "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";
  const universityEmblemUrl = user.universityEmblem || placeholderImageUrl; // Assuming `user.universityEmblem` holds the URL for the emblem

  return (
    <div className="flex h-auto w-auto items-center justify-center ">
      <div className="flex h-full w-full flex-row overflow-hidden rounded-lg border border-gray-400 bg-white shadow-md">
        <div className="w-[30%] bg-gradient-to-b from-purple-500 to-purple-700 p-2 text-center text-white">
          <div className="mx-auto mb-4 mt-2 w-[80%] overflow-hidden rounded-full">
            <img
              src={user.photoURL || placeholderImageUrl}
              alt="User"
              style={{
                width: "100%",
                aspectRatio: "1 / 1",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="mx-auto my-2 flex w-[90%] items-center justify-center rounded bg-white text-center">
            {user.instagram ? (
              <a
                href={getInstagramUrl(user.instagram)}
                target="_blank"
                rel="noopener noreferrer"
                className=" items-center p-1 text-center text-[11px] font-semibold text-purple-500 sm:text-[15px]"
              >
                <FontAwesomeIcon icon={faInstagram} /> Instagram
              </a>
            ) : (
              <div className="my-2">
                <p className="items-center bg-gray-300 p-1 text-center text-[11px] font-semibold text-purple-500 opacity-70 sm:text-[15px]">
                  <FontAwesomeIcon icon={faInstagram} /> Instagram
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="w-[70%] pb-3 ">
          <div className="flex items-center justify-between pl-3 pr-2">
            <h1 className="text-2xl font-bold">
              {user.firstName} {user.lastName}
            </h1>
            <img
              src={universityEmblemUrl}
              alt="University Emblem"
              style={{
                width: "45px",
                height: "auto",
                margin: "5px 0px",
                borderRadius: "5px",
              }} // Set size as needed
            />
          </div>
          <hr className="mb-4" />
          <div className="">
            <div className="lg:text[xl] mr-2 grid grid-cols-5 gap-4 pl-6">
              <div className="col-span-2 flex flex-row items-center gap-2">
                <h6 className="text-gray-500">
                  <FontAwesomeIcon icon={faPerson} />
                </h6>
                <p className="">{user.gender}</p>
              </div>
              <div className="col-span-3 flex flex-row items-center gap-2">
                <h6 className="text-gray-500">
                  <FontAwesomeIcon icon={faGraduationCap} />
                </h6>
                <p className="">{user.shortName}</p>
              </div>
              <div className="col-span-2 flex flex-row items-center gap-2">
                <h6 className="text-gray-500">
                  <FontAwesomeIcon icon={faBirthdayCake} />
                </h6>
                <p className="">{user.age}</p>
              </div>
              <div className="col-span-3 flex flex-row items-center gap-2">
                <h6 className="text-gray-500">
                  <FontAwesomeIcon icon={faFlag} />
                </h6>
                <p className="">{user.nationality}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
