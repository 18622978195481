import React, { useState, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../DatePickerStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "./tooltip"; // Adjust the import based on your file structure

const InquiryForm = ({ listing, sendInquiry, isSendingRequest, inquiryData, setInquiryData }) => {
  const handleDateChange = useCallback(
    (field, date) => {
      setInquiryData((prev) => ({
        ...prev,
        [field]: date,
        ...(field === "startDate" && { endDate: Math.max(date, prev.endDate) }),
        ...(field === "endDate" && { startDate: Math.min(date, prev.startDate) }),
      }));
    },
    [setInquiryData]
  );

  const handleInputChange = (e) => {
    setInquiryData({ ...inquiryData, [e.target.name]: e.target.value });
  };

  const renderDatePicker = (label, dateField, startDateField, endDateField) => (
    <div className="flex flex-col">
      <label className="block text-[7px] font-medium text-gray-700 md:text-sm">{label}</label>
      <DatePicker
        minDate={new Date()}
        selected={inquiryData[dateField]}
        onChange={(date) => handleDateChange(dateField, date)}
        selectsStart={dateField === startDateField}
        selectsEnd={dateField === endDateField}
        startDate={inquiryData[startDateField]}
        endDate={inquiryData[endDateField]}
        className="form-input w-full cursor-pointer rounded-md border border-gray-300 p-1 text-[7px] font-semibold shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 md:p-2 md:text-[15px]"
        dateFormat="MMM d, yyyy"
      />
    </div>
  );

  return (
    <form onSubmit={sendInquiry} className="mx-auto w-[90%] md:w-[80%] md:space-y-3">
      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col">
          <p className="text-[7px] font-medium text-gray-700 md:text-sm">Room </p>
          <p className="text-[7px] font-semibold md:text-xl">{listing.roomType}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-[7px] font-medium text-gray-700 md:text-sm">
            Monthly Rent
            <Tooltip
              tooltipText="This is the rent for a full month. The rent for the first and last months are calculated as a proportion of the days stayed during the month"
              position="left"
            />
          </p>
          <p className="text-[7px] font-semibold md:text-xl">€{listing.rent}</p>
        </div>
        {renderDatePicker("Move In", "startDate", "startDate", "endDate")}
        {renderDatePicker("Move Out", "endDate", "startDate", "endDate")}
      </div>
      <div>
        <label htmlFor="requests" className="block text-[7px] font-medium text-gray-700 md:text-sm">
          Message
        </label>
        <textarea
          id="requests"
          name="requests"
          placeholder="Message"
          className="block w-full resize-none rounded border border-gray-300 p-2 text-[15px] shadow-sm focus:border-blue-500 focus:ring-blue-500 md:mt-1 md:h-28"
          onChange={handleInputChange}
        ></textarea>
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className="rounded bg-gradient-to-r from-purple-500 to-purple-600 p-1 text-[7px] text-white shadow hover:shadow-lg md:p-2 md:text-[15px]"
          disabled={isSendingRequest}
        >
          {isSendingRequest ? (
            <>
              <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin" />
              Sending...
            </>
          ) : (
            "Send Request"
          )}
        </button>
      </div>
    </form>
  );
};

export default InquiryForm;
