import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const ModalTemplate = ({ isModalOpen, closeModal, content, height, width }) => {
  useEffect(() => {
    // Lock body scroll when modal is open
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    }
    // Re-enable body scroll when modal is closed
    return () => {
      document.body.style.overflow = "";
    };
  }, [isModalOpen]); // Dependency array ensures effect runs only when `isModalOpen` changes

  if (!isModalOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className={`rounded-lg bg-white shadow-lg ${height} ${width}`}>
        <div className="relative">
          <button
            onClick={closeModal}
            className="absolute right-4 top-0 text-lg text-gray-500"
          >
            <FontAwesomeIcon icon={faX} />
          </button>
          <div className="m-8 my-4 md:my-8 md:mt-4">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalTemplate;
