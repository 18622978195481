import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";
import { Link } from "react-router-dom";
import ImageCarousel from "../components/imagecarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faHouse,
  faBed,
  faMoneyBill,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const MySublets = () => {
  const [sublets, setSublets] = useState([]);
  const [user, loading, error] = useAuthState(auth); // Modified to include loading and error
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("lister"); // 'lister' or 'tenant'
  const navigate = useNavigate(); // Initialize useNavigate

  // Get sublets of the logged in user and unread messages of these sublets
  useEffect(() => {
    const fetchSublets = async () => {
      setIsLoading(true);
      if (!user) return;

      try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        const userSublets = userDoc.exists()
          ? userDoc.data().sublets || []
          : [];

        const SubletsWithListingDetails = await Promise.all(
          userSublets.map(async (sublet) => {
            const subletDocRef = doc(db, "Sublets", sublet.subletId);
            const subletDoc = await getDoc(subletDocRef);

            return {
              subletId: sublet.subletId,
              subletData: subletDoc.exists() ? subletDoc.data() : null,
            };
          }),
        );
        for (const sublet of SubletsWithListingDetails) {
          const messagesSnapshot = await getDocs(
            collection(db, "Sublets", sublet.subletId, "messages"),
          );
          const unreadCount = messagesSnapshot.docs.filter(
            (doc) => !doc.data().read && doc.data().userId !== user.uid,
          ).length;
          sublet.unreadMessagesCount = unreadCount; // Add unread message count to each enquiry
        }
        setSublets(SubletsWithListingDetails);
        setIsLoading(false);
        // Additional logic to handle direct navigation for a single sublet
        if (SubletsWithListingDetails.length === 1) {
          const singleSublet = SubletsWithListingDetails[0];
          if (
            activeTab === "lister" &&
            singleSublet.subletData.listerId === user.uid
          ) {
            navigate(
              `/currentlet/${singleSublet.subletId}/${singleSublet.subletData.listerId}/${singleSublet.subletData.userId}`,
            );
          } else if (
            activeTab === "tenant" &&
            singleSublet.subletData.userId === user.uid
          ) {
            navigate(
              `/currentstay/${singleSublet.subletId}/${singleSublet.subletData.listerId}/${singleSublet.subletData.userId}`,
            );
          }
        }
      } catch (error) {
        console.error("Error fetching sublets: ", error);
      }
    };

    fetchSublets();
  }, [user, navigate, activeTab]);

  const switchTab = (tab) => {
    setActiveTab(tab);
  };

  const filteredSublets = sublets.filter((sublet) =>
    activeTab === "lister"
      ? sublet.subletData.listerId === user.uid
      : sublet.subletData.userId === user.uid,
  );

  if (loading) {
    return (
      <div className="mt-40 py-6 text-center text-2xl font-semibold text-gray-700">
        Loading...
      </div>
    ); // Show loading state
  }

  if (!user) {
    return (
      <div className="mx-auto mb-64 mt-16 w-[90%] md:mt-20 md:w-[80%]">
        {" "}
        <h2 className="text-3xl font-semibold">My Sublets</h2>
        <div className="mt-24 py-6 text-center text-2xl font-semibold text-gray-700">
          Please sign in to see your sublets
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto mb-24 mt-16 grid w-[90%] grid-cols-1 gap-4 md:mt-20 md:w-[80%]">
      <div className="flex justify-between">
        <h2 className="text-3xl font-semibold">My Sublets</h2>

        {sublets.length > 0 && (
          <div className="flex">
            <button
              onClick={() => switchTab("lister")}
              className={`rounded-l p-1 md:px-4 md:py-2 ${activeTab === "lister" ? "bg-purple-500 text-white" : "bg-gray-200"}`}
            >
              Letting
            </button>
            <button
              onClick={() => switchTab("tenant")}
              className={`rounded-r p-1 md:px-4 md:py-2 ${activeTab === "tenant" ? "bg-purple-500 text-white" : "bg-gray-200"}`}
            >
              Staying
            </button>
          </div>
        )}
      </div>
      {isLoading ? (
        <div className="mt-24 py-6 text-center text-2xl font-semibold text-gray-700">
          Loading...
        </div>
      ) : filteredSublets.length === 0 ? (
        <div className="mt-24 text-center text-lg">
          <p>No sublets found</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6">
          {/* Map over filteredSublets instead of sublets */}
          {filteredSublets.map(
            ({ subletId, subletData, unreadMessagesCount }) => (
              <div
                key={subletId}
                className="card rounded-lg border border-gray-200 bg-white shadow-lg"
              >
                <div className="hidden md:block">
                  <div className="flex">
                    <div className=" mx-2 mt-2 w-1/3 items-center overflow-hidden p-2">
                      <ImageCarousel images={subletData?.imageUrls || []} />
                    </div>
                    <div className="mx-8 w-2/3 p-4">
                      <h3 className="mb-4 text-3xl font-bold">
                        {subletData?.title}
                      </h3>
                      <div className="">
                        <p className="text-xl">
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="mr-2 mt-2 w-6 text-xl text-gray-500"
                          />{" "}
                          {subletData?.streetAddress}, {subletData.city},{" "}
                          {subletData.county}, {subletData.postalCode}
                        </p>
                        <div className="mx-8 mt-2 grid grid-cols-2 text-lg">
                          <p className="mb-2">
                            <FontAwesomeIcon
                              icon={faHouse}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            {subletData.propertyType}
                          </p>
                          <p className="mb-2">
                            {" "}
                            <FontAwesomeIcon
                              icon={faBed}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            {subletData.roomType}
                          </p>
                          <p className="mb-2">
                            <FontAwesomeIcon
                              icon={faMoneyBill}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            €{subletData.rent}/month
                          </p>
                          <p className="mb-2">
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            {subletData?.startDate &&
                              new Date(subletData.startDate).toLocaleDateString(
                                "en-US",
                                { month: "short", day: "2-digit" },
                              )}{" "}
                            -{" "}
                            {subletData?.endDate &&
                              new Date(subletData.endDate).toLocaleDateString(
                                "en-US",
                                { month: "short", day: "2-digit" },
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="mt-1 flex justify-center">
                        {activeTab === "lister" ? (
                          <Link
                            to={`/currentlet/${subletId}/${subletData?.listerId}/${subletData?.userId}`} // Your lister specific route
                            className="relative flex w-full justify-center rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
                          >
                            View Sublet
                            {unreadMessagesCount > 0 && (
                              <span className="absolute right-0 top-0 inline-flex h-6 w-6 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none">
                                {unreadMessagesCount}
                              </span>
                            )}
                          </Link>
                        ) : (
                          <Link
                            to={`/currentstay/${subletId}/${subletData?.listerId}/${subletData?.userId}`} // Your tenant specific route
                            className="relative flex w-full justify-center rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
                          >
                            View Sublet
                            {unreadMessagesCount > 0 && (
                              <span className="absolute right-0 top-0 inline-flex h-6 w-6 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none">
                                {unreadMessagesCount}
                              </span>
                            )}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:hidden">
                  <div className="flex flex-col">
                    <h3 className="ml-2 mt-2 text-xl font-bold">
                      {subletData?.title}
                    </h3>
                    <div className=" w-full items-center overflow-hidden p-2">
                      <ImageCarousel images={subletData?.imageUrls || []} />
                    </div>
                    <div className="w-full px-2 pb-2">
                      <div className="">
                        <p className="text-lg">
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="mr-2 mt-2 w-6 text-xl text-gray-500"
                          />{" "}
                          {subletData?.streetAddress}, {subletData.city},{" "}
                          {subletData.county}, {subletData.postalCode}
                        </p>
                        <div className="mt-2 grid grid-cols-2">
                          <p className="mb-2">
                            <FontAwesomeIcon
                              icon={faHouse}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            {subletData.propertyType}
                          </p>
                          <p className="mb-2">
                            {" "}
                            <FontAwesomeIcon
                              icon={faBed}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            {subletData.roomType}
                          </p>
                          <p className="mb-2">
                            <FontAwesomeIcon
                              icon={faMoneyBill}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            €{subletData.rent}/month
                          </p>
                          <p className="mb-2">
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className="mr-2 mt-2 w-6 text-gray-500"
                            />{" "}
                            {subletData?.startDate &&
                              new Date(subletData.startDate).toLocaleDateString(
                                "en-US",
                                { month: "short", day: "2-digit" },
                              )}{" "}
                            -{" "}
                            {subletData?.endDate &&
                              new Date(subletData.endDate).toLocaleDateString(
                                "en-US",
                                { month: "short", day: "2-digit" },
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="mt-1 flex justify-center">
                        {activeTab === "lister" ? (
                          <Link
                            to={`/currentlet/${subletId}/${subletData?.listerId}/${subletData?.userId}`} // Your lister specific route
                            className="relative flex w-full justify-center rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
                          >
                            View Sublet
                            {unreadMessagesCount > 0 && (
                              <span className="absolute right-0 top-0 inline-flex h-6 w-6 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none">
                                {unreadMessagesCount}
                              </span>
                            )}
                          </Link>
                        ) : (
                          <Link
                            to={`/currentstay/${subletId}/${subletData?.listerId}/${subletData?.userId}`} // Your tenant specific route
                            className="relative flex w-full justify-center rounded bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
                          >
                            View Sublet
                            {unreadMessagesCount > 0 && (
                              <span className="absolute right-0 top-0 inline-flex h-6 w-6 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none">
                                {unreadMessagesCount}
                              </span>
                            )}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default MySublets;
