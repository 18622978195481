// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDP017pgtfwjDh5wg-QgtRWT-plC346bU4",
  authDomain: "lettz-cd03c.firebaseapp.com",
  projectId: "lettz-cd03c",
  storageBucket: "lettz-cd03c.appspot.com",
  messagingSenderId: "605508077519",
  appId: "1:605508077519:web:bcde7c4c1a41f270a834ba",
  measurementId: "G-1091D64L56",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
