import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase-config";
import {
  doc,
  getDoc,
  addDoc,
  collection,
  writeBatch,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import ModalTemplate from "./modalTemplate";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { httpsCallable, getFunctions } from "firebase/functions";
import { functions } from "../../firebase-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../tooltip";

const FinalOfferModal = ({
  isModalOpen,
  closeModal,
  rentSchedule,
  listerId,
  listingId,
  enquiryId,
  startDate,
  endDate,
}) => {
  const ModalContent = ({
    rentSchedule,
    listerId,
    listingId,
    enquiryId,
    startDate,
    endDate,
  }) => {
    const navigate = useNavigate(); // Hook for navigation
    const [user] = useAuthState(auth);
    const [userData, setUserData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const totalPages = 3;
    const [errors, setErrors] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [customerId, setCustomerId] = useState("");
    const [listerData, setListerData] = useState(null); // State for the lister's data
    const [subletData, setSubletData] = useState(null);

    console.log("rentSchedule in FinalOfferModal:", rentSchedule);
    useEffect(() => {
      const fetchUserData = async () => {
        if (user && db) {
          try {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              console.log("User data:", userData); // Debugging
              setUserData(userData);
            } else {
              console.log("No user data found for this userID");
            }
          } catch (error) {
            console.error("Error fetching user data: ", error);
          }
        }
      };

      fetchUserData();
    }, [user, db]);

    useEffect(() => {
      const fetchListerData = async () => {
        if (listerId) {
          try {
            const listerDocRef = doc(db, "users", listerId);
            const listerDocSnap = await getDoc(listerDocRef);

            if (listerDocSnap.exists()) {
              setListerData(listerDocSnap.data());
            } else {
              console.log("No lister data found for this listerId");
            }
          } catch (error) {
            console.error("Error fetching lister data: ", error);
          }
        }
      };

      fetchListerData();
    }, [listerId]); // Dependency array to re-run useEffect when listerId changes

    const nextPage = () => {
      setCurrentPage(currentPage + 1);
      setErrors(""); // Reset errors when moving to next page
      console.log("this is user data", userData.email);
    };
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    };

    const renderProgressBar = () => {
      let pageHeader = "";
      switch (currentPage) {
        case 0:
          pageHeader = "Review of Rent Details";
          break;
        case 1:
          pageHeader = "Payment Details";
          break;
        case 2:
          pageHeader = "Confirmation";
          break;
        default:
          pageHeader = "";
          break;
      }
      return (
        <div className="mb-4">
          <div className="mb-1 text-center text-lg">{pageHeader}</div>
          <div className="h-2 w-full rounded-full bg-gray-200">
            <div
              className="h-2 rounded-full bg-purple-600"
              style={{ width: `${((currentPage + 1) / totalPages) * 100}%` }}
            ></div>
          </div>
        </div>
      );
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      setErrors("");
      setIsLoading(true);

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: userData.email,
        },
      });

      if (error) {
        console.log("[error]", error);
        setIsLoading(false);
        setErrors("error try again");
        return;
      }
      // Include hasCustomerId to indicate whether userData includes customerId
      const hasCustomerId = !!userData && !!userData.customerId;
      console.log(hasCustomerId);

      // Call Firebase function with additional hasCustomerId field
      const processPayment = httpsCallable(functions, "processPayment");
      processPayment({
        email: userData.email,
        paymentMethodId: paymentMethod.id,
        name: userData.firstName + " " + userData.lastName,
        hasCustomerId: hasCustomerId, // Pass hasCustomerId to your Firebase function
        customerId: userData.customerId || null, // Pass existing customerId if present
      })
        .then(async (result) => {
          console.log(result);
          const newCustomerId = result.data.customerId;
          setCustomerId(newCustomerId);

          // Update user data with the new customerId
          if (user) {
            const userDocRef = doc(db, "users", user.uid);
            await updateDoc(userDocRef, {
              customerId: newCustomerId,
              updateCard: true,
            });
          }

          updateFirestore(newCustomerId);
          setIsLoading(false);
          console.log(subletData);

          setErrors(""); // Clear any existing error messages
          nextPage();
        })
        .catch((error) => {
          // Handle errors
          setErrors("error try again");
          console.error("Error processing payment", error);
          setIsLoading(false);
        });
    };

    const sendTenantEmail = async (subletData) => {
      console.log(userData.email);
      const functions = getFunctions();
      const sendTenantStayEmail = httpsCallable(
        functions,
        "sendTenantStayEmail",
      );

      try {
        const result = await sendTenantStayEmail({
          email: userData.email,
          receivingUser: userData.firstName,
          sendingUser: listerData.firstName + " " + listerData.lastName,
          subletData: subletData,
        });
        console.log(result.data);
        console.log(result.data);
      } catch (error) {
        console.error(error);
      }
    };

    const sendListerEmail = async (subletData) => {
      console.log(listerData.email);
      const functions = getFunctions();
      const sendListerStayEmail = httpsCallable(
        functions,
        "sendListerStayEmail",
      );

      try {
        const result = await sendListerStayEmail({
          email: listerData.email,
          receivingUser: listerData.firstName,
          sendingUser: userData.firstName + " " + listerData.lastName,
          subletData: subletData,
        });
        console.log(result.data);
        console.log(result.data);
      } catch (error) {
        console.error(error);
      }
    };

    const updateFirestore = async (customerId) => {
      // Firestore operations for payment and sublets
      try {
        console.log("Rent Schedule:", rentSchedule);
        // Add a new key-value pair to each entry of the rentSchedule array
        const updatedRentSchedule = rentSchedule.map((payment, index) => ({
          ...payment,
          index: index + 1, // Add an index number starting from 1
          status: "upcoming", // Add a new key 'status' with value 'upcoming'
        }));
        console.log("Updated Rent Schedule:", updatedRentSchedule);

        // Assuming you have the listingId and enquiryId
        const listingDocRef = doc(db, "Listings", listingId);
        console.log("listingDocRef:", listingDocRef);
        const listingDoc = await getDoc(listingDocRef);
        console.log("listingDoc:", listingDoc);
        let listingData = listingDoc.exists() ? listingDoc.data() : {};
        console.log("listingData:", listingData);

        const enquiryDocRef = doc(
          db,
          "Listings",
          listingId,
          "enquiries",
          enquiryId,
        );
        const enquiryDoc = await getDoc(enquiryDocRef);
        let enquiryData = enquiryDoc.exists() ? enquiryDoc.data() : {};
        console.log("enquiryData:", enquiryData);

        // Merge data, giving preference to enquiryData
        const subletData = {
          ...listingData,
          ...enquiryData,
          customerId: customerId,
          rentSchedule: updatedRentSchedule,
        };
        setSubletData(subletData);

        console.log("subletData:", subletData);
        sendListerEmail(subletData);
        sendTenantEmail(subletData);
        // Add to Sublets collection
        const subletDocRef = await addDoc(
          collection(db, "Sublets"),
          subletData,
        ); // Add sublet and capture the document reference
        const subletId = subletDocRef.id; // Capture the new sublet ID

        // Add to Payments collection
        if (
          Array.isArray(updatedRentSchedule) &&
          updatedRentSchedule.length > 0
        ) {
          updatedRentSchedule.forEach(async (payment) => {
            const paymentDoc = {
              index: payment.index,
              customerEmail: userData.email,
              customerId: customerId,
              date: payment.date, // Format: YYYY-MM-DD
              rent: payment.rent,
              accountId: enquiryData.accountId,
              customerName: userData.firstName + " " + userData.lastName,
              subletId: subletId,
              collectorName: subletData.listerName,
              // Add any other necessary fields
            };

            await addDoc(collection(db, "payments"), paymentDoc);
          });
        } else {
          console.log("rentSchedule is undefined or not an array");
        }
        // Transfer Messages from Enquiries to Sublets
        const messagesSnapshot = await getDocs(
          collection(
            db,
            "Listings",
            listingId,
            "enquiries",
            enquiryId,
            "messages",
          ),
        );
        const messages = [];
        messagesSnapshot.forEach((doc) => {
          messages.push({ id: doc.id, ...doc.data() });
        });

        // Add each message to the new Sublets collection
        const messagesCollectionRef = collection(
          db,
          "Sublets",
          subletId,
          "messages",
        );
        messages.forEach(async (msg) => {
          await addDoc(messagesCollectionRef, { ...msg });
        });

        // Delete original listing and its enquiries
        const batch = writeBatch(db);

        // Fetch and update user data to remove enquiry from enquiries array
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const updatedEnquiries = userData.enquiries.filter(
            (enquiry) => enquiry.listingId !== listingId,
          );
          batch.update(userDocRef, { enquiries: updatedEnquiries });
        }

        const enquiriesSnapshot = await getDocs(
          collection(db, "Listings", listingId, "enquiries"),
        );
        for (const enquiryDoc of enquiriesSnapshot.docs) {
          const messagesSnapshot = await getDocs(
            collection(
              db,
              "Listings",
              listingId,
              "enquiries",
              enquiryDoc.id,
              "messages",
            ),
          );
          messagesSnapshot.forEach((msgDoc) => {
            batch.delete(msgDoc.ref); // Delete each message
          });
          batch.delete(enquiryDoc.ref); // Then delete the enquiry itself
        }
        // Finally, delete the listing
        batch.delete(doc(db, "Listings", listingId));
        await batch.commit();

        try {
          const listerId = subletData.listerId;

          // Function to update sublets array in user document
          const updateSubletsArray = async (userId) => {
            const userDocRef = doc(db, "users", userId);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              let sublets = userData.sublets || [];
              sublets.push({ subletId, listerId, userId: user.uid });

              await updateDoc(userDocRef, { sublets: sublets });
            } else {
              console.log(`No user data found for userID: ${userId}`);
            }
          };

          // Update sublets array for the current user
          await updateSubletsArray(user.uid);

          // Update sublets array for the lister
          if (listerId !== user.uid) {
            // Avoid duplicate update if the lister is the same as the current user
            await updateSubletsArray(listerId);
          }
        } catch (error) {
          console.error("Error in updateFirestore function: ", error);
        }
      } catch (error) {
        console.error("Error handling Firestore operations: ", error);
      }
    };

    const handleBeginStay = () => {
      navigate("/mySublets"); // Redirect to mysublet page
    };
    const prevPage = () => {
      setCurrentPage(currentPage - 1);
    };

    const renderPage = () => {
      switch (currentPage) {
        case 0:
          return (
            <>
              <div className="mx-4">
                <div className="my-6">
                  <div className="grid grid-cols-2 gap-4 md:mx-16">
                    <div className="items-left flex flex-col">
                      <p className=" font-semibold text-gray-700">Move In </p>
                      <p className="text-2xl font-semibold">
                        {formatDate(startDate)}
                      </p>
                    </div>
                    <div className="items-left ml-8 flex flex-col">
                      <p className=" font-semibold text-gray-700">Move Out </p>
                      <p className="text-2xl font-semibold">
                        {formatDate(endDate)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="">
                  <p className="mb-4 text-lg font-semibold text-gray-700">
                    Rent Collection{" "}
                    <Tooltip tooltipText="Rent will be charged to your card on the following dates for the specified amount. Ensure your card has enough funds on these days for each transfer." />
                  </p>
                  <div
                    style={{
                      overflowX: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <ul className="flex flex-row justify-start space-x-4 py-1">
                      {rentSchedule.map((item, index) => (
                        <li
                          key={index}
                          style={{ flex: "0 0 auto" }}
                          className="flex flex-col items-center overflow-hidden rounded border border-gray-300 shadow-lg"
                        >
                          <p className="flex w-full items-center justify-center bg-purple-500 p-2 text-sm text-white">
                            {formatDate(item.date)}
                          </p>
                          <p className="flex w-full items-center justify-center p-2 text-sm">
                            €{item.rent.toFixed(0)}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          );
        case 1:
          if (userData && userData.customerId) {
            return (
              <>
                <div
                  style={{
                    boxShadow: "inset 0 -6px 6px -1px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div className="h-[250px] overflow-y-auto">
                    <div className="mb-4 flex flex-row">
                      <div className="ml-4 w-[95%] text-[15px] md:ml-4">
                        <p>
                          You already have a card linked to this account. The
                          card details you enter below will overwrite your
                          previous card and be used to pay the rent on the dates
                          specified on the previous page and also in all other
                          rent transactions linked to this account.
                        </p>
                      </div>
                    </div>
                    <div>
                      <form className="rounded-md border border-gray-300 p-2 shadow-md md:p-4">
                        {errors && (
                          <p className="text-center text-sm text-red-500">
                            {errors}
                          </p>
                        )}
                        <div className="items-left flex flex-col">
                          <p className="text-sm font-semibold text-gray-700">
                            Card Number
                          </p>
                          <div className="rounded-md border border-gray-300 bg-white p-2">
                            <CardNumberElement />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <div className="items-left flex flex-col">
                            <p className="text-sm font-semibold text-gray-700">
                              Expiry Date
                            </p>
                            <div className="rounded-md border border-gray-300 bg-white p-2">
                              <CardExpiryElement />
                            </div>
                          </div>
                          <div className="items-left flex flex-col">
                            <p className="text-sm font-semibold text-gray-700">
                              CVC
                            </p>
                            <div className="rounded-md border border-gray-300 bg-white p-2">
                              <CardCvcElement />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            );
          } else {
            return (
              <>
                {" "}
                <div>
                  <div className="mb-4 flex flex-row">
                    <p className="mt-1 h-4 w-4 rounded-full bg-purple-500 text-center text-xs text-white shadow-md">
                      i
                    </p>
                    <div className="ml-4 w-[95%] text-[15px] md:ml-4">
                      <p>
                        The card details you enter will be securely stored and
                        be used to pay the rent on the dates specified on the
                        previous page
                      </p>
                    </div>
                  </div>
                  <div>
                    <form className="rounded-md border border-gray-300 p-2 shadow-md md:p-4">
                      {errors && (
                        <p className="text-center text-sm text-red-500">
                          {errors}
                        </p>
                      )}
                      <div className="items-left flex flex-col">
                        <p className="text-sm font-semibold text-gray-700">
                          Card Number
                        </p>
                        <div className="rounded-md border border-gray-300 bg-white p-2">
                          <CardNumberElement />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <div className="items-left flex flex-col">
                          <p className="text-sm font-semibold text-gray-700">
                            Expiry Date
                          </p>
                          <div className="rounded-md border border-gray-300 bg-white p-2">
                            <CardExpiryElement />
                          </div>
                        </div>
                        <div className="items-left flex flex-col">
                          <p className="text-sm font-semibold text-gray-700">
                            CVC
                          </p>
                          <div className="rounded-md border border-gray-300 bg-white p-2">
                            <CardCvcElement />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            );
          }
        case 2:
          return (
            <>
              <div className="mx-2 mt-12 text-center text-xl md:mx-8">
                <p className="text-center text-2xl font-semibold text-green-500">
                  Congratulations!
                </p>{" "}
                Your card has been authorised and rent payments have been set
                up. You are now ready to move in on{" "}
                {new Date(startDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                })}
              </div>
            </>
          );
      }
    };

    return (
      <div className="relative h-[350px] md:w-[500px]">
        {renderProgressBar()}
        <div className="space-y-4 ">
          {renderPage()}
          <div className="absolute bottom-0 w-full">
            <div className="mt-6 flex items-center justify-between">
              {currentPage === 1 ? (
                <button
                  type="button"
                  onClick={prevPage}
                  className="rounded bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300"
                >
                  Previous
                </button>
              ) : (
                <div className="px-4 py-2"></div>
              )}{" "}
              {currentPage === 0 && (
                <button
                  type="button"
                  onClick={nextPage}
                  className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
                >
                  Next
                </button>
              )}
              {currentPage === 1 && (
                <button
                  disabled={!stripe || isLoading} // Disable the button if stripe hasn't loaded or if isLoading is true
                  type="button"
                  onClick={() => {
                    setIsLoading(true); // Set isLoading to true right when the button is clicked
                    handleSubmit(); // Call your handleSubmit function
                  }}
                  className="rounded bg-purple-500 px-2 py-3 text-[12px] font-semibold text-white hover:bg-purple-600 md:px-4 md:py-2 md:text-[15px]"
                >
                  {isLoading ? (
                    <>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="mr-2 animate-spin"
                      />{" "}
                      Authorising Card...
                    </>
                  ) : (
                    "Authorise and Save Card"
                  )}
                </button>
              )}
              {currentPage === 2 && (
                <button
                  type="button"
                  onClick={handleBeginStay}
                  className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
                >
                  Begin Stay
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <ModalTemplate
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      content={
        <ModalContent
          rentSchedule={rentSchedule}
          startDate={startDate}
          endDate={endDate}
          listerId={listerId}
          listingId={listingId}
          enquiryId={enquiryId}
        />
      }
      width="md:w-auto w-[95%]"
    />
  );
};

export default FinalOfferModal;
