import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const functions = getFunctions();

const FileUpload = ({ stripeAccountId, nextPage }) => {
  const [uploadStatus, setUploadStatus] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const isUploadedSuccessfully =
    uploadStatus ===
    `ID uploaded successfully! If there are any issues with verification we will contact you via email`;

  console.log(uploadStatus);
  // FILE UPLOAD ************************************************
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true); // Set the uploading flag to true
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result;
        try {
          const uploadFileToStripe = httpsCallable(
            functions,
            "uploadFileToStripe",
          );
          await uploadFileToStripe({
            file: base64String,
            accountId: stripeAccountId,
          });
          setUploadStatus(
            `ID uploaded successfully! If there are any issues with verification we will contact you via email`,
          );
          setUploadedImage(base64String); // Update after ensuring upload is complete
        } catch (error) {
          console.error("Error uploading file:", error);
          setUploadStatus("Error: Could not upload file.");
        } finally {
          setIsUploading(false); // Reset the uploading flag
        }
      };
      reader.readAsDataURL(file);
    }
  };
  // *********************************************************************************

  return (
    <div className="mx-auto mb-2 mt-4 h-[180px] w-[90%] rounded-md border border-dashed border-gray-300 bg-gray-100 text-base shadow-sm hover:bg-gray-300 md:w-[70%]">
      {uploadedImage ? (
        <img
          src={uploadedImage}
          alt="Uploaded ID"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      ) : (
        <div>
          <label htmlFor="file-upload">
            <div className="flex h-[180px] w-[100%] cursor-pointer flex-col pt-[50px] text-center">
              <span className="text-xl">Upload ID</span>
              <span className="text-4xl">+</span>
            </div>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              className="sr-only"
            />
          </label>
        </div>
      )}
      {isUploading && (
        <p className="text-blue-500">
          <FontAwesomeIcon icon={faSpinner} spin /> Uploading...
        </p>
      )}
      {uploadStatus && (
        <p className="text-[12px] md:text-[15px]">{uploadStatus}</p>
      )}
      <button
        type="button"
        onClick={nextPage}
        disabled={!isUploadedSuccessfully} // Disable the button unless the file is uploaded successfully
        className={`absolute bottom-0 right-0 rounded px-4 py-2 ${
          isUploadedSuccessfully
            ? "bg-purple-500 text-white hover:bg-purple-600"
            : "cursor-not-allowed bg-gray-300 text-gray-500"
        }`}
      >
        Next
      </button>
    </div>
  );
};

export default FileUpload;
