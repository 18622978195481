import React, { useState } from "react";

const Tooltip = ({ tooltipText, position = "right" }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  const tooltipStyle = {
    position: "absolute",
    width: "200px",
    bottom: "100%", // Adjusted to ensure the tooltip appears above the info icon
    transform: "translateY(-5px)", // Slight adjustment for visual spacing
    marginBottom: "5px",
    opacity: "0.9",
    padding: "5px",
    backgroundColor: "black",
    color: "white",
    borderRadius: "4px",
    fontSize: "12px",
    zIndex: 1,
  };

  // Adjust the position based on the passed prop
  if (position === "right") {
    tooltipStyle.left = "100%";
    tooltipStyle.transform = "translateX(10px)"; // Adjust as needed for spacing
  } else if (position === "left") {
    tooltipStyle.right = "100%";
    tooltipStyle.transform = "translateX(-10px)"; // Adjust as needed for spacing
  }

  return (
    <div
      style={{ display: "inline-block", position: "relative" }}
      className="cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <p className="flex h-4 w-4 items-center justify-center rounded-full bg-gray-400 text-xs text-white">
        i
      </p>

      {showTooltip && <div style={tooltipStyle}>{tooltipText}</div>}
    </div>
  );
};

export default Tooltip;
