import React from "react";
import LocationDropdown from "./locationDropdown";
import DateDropdown from "./dateDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons";

const Dropdown = ({
  dropdownRef,
  dropdownOpen,
  activeTab,
  startDate,
  endDate,
  startFlexibility,
  endFlexibility,
  setDropdownOpen,
  setStartDate,
  setStartFlexibility,
  setEndDate,
  setEndFlexibility,
  locationInput,
  setLocationInput,
  selectedLocation,
  filteredOptions,
  formatDateRange,
  handleTabChange,
  handleLocationSelect,
  handleDateChange,
  resetDates,
  closeDropdown,
  getSortMethodText,
  handleSortSelection,
}) => {
  return (
    <div
      ref={dropdownRef}
      className="relative z-20 mx-auto mt-16 flex items-center justify-center space-x-2 bg-white md:z-40 md:mt-3 md:w-[60%] md:bg-none"
    >
      <div
        className={`flex w-[90%] rounded-lg border border-gray-300 text-gray-900 shadow-sm hover:shadow-md transition-all duration-500 ease-in-out ${
          dropdownOpen.search ? "w-[90%] bg-gray-100 md:w-[100%]" : "w-[90%] bg-white md:w-96"
        }`}
      >
        <div
          className={`ml-2 flex-grow cursor-pointer rounded-lg p-2 text-left text-[17px] font-semibold ${
            activeTab === "location" ? "bg-white shadow-lg" : ""
          }`}
          onClick={() => handleTabChange("location")}
        >
          {selectedLocation}
        </div>
        <div className="text-md mb-1 flex items-center justify-center text-gray-400 md:my-1 md:text-xl">|</div>
        <div
          className={`mr-2 flex-grow cursor-pointer rounded-lg p-2 text-left text-[17px] ${
            activeTab === "dates" ? "bg-white shadow-lg" : ""
          }`}
          onClick={() => handleTabChange("dates")}
        >
          {formatDateRange(startDate, endDate)}
        </div>
      </div>

      {dropdownOpen.search && (
        <div className="absolute top-full z-10 mt-1 h-auto w-[90%] rounded border border-gray-300 bg-white shadow-lg md:w-full">
          {activeTab === "location" && (
            <LocationDropdown
              locationInput={locationInput}
              setLocationInput={setLocationInput}
              filteredOptions={filteredOptions}
              handleLocationSelect={handleLocationSelect}
            />
          )}
          {activeTab === "dates" && (
            <DateDropdown
              startDate={startDate}
              endDate={endDate}
              startFlexibility={startFlexibility}
              endFlexibility={endFlexibility}
              setStartDate={setStartDate}
              setStartFlexibility={setStartFlexibility}
              setEndDate={setEndDate}
              setEndFlexibility={setEndFlexibility}
              handleDateChange={handleDateChange}
              resetDates={resetDates}
              closeDropdown={closeDropdown}
            />
          )}
        </div>
      )}
      <button
        className={`rounded-lg border border-gray-300 px-2 py-1 text-gray-900 md:rounded-2xl md:px-4 md:py-2 ${
          dropdownOpen.sort ? "bg-gray-100" : "bg-white"
        } shadow-sm transition duration-300 hover:shadow-md`}
        onClick={() => setDropdownOpen((prev) => ({ ...prev, sort: !prev.sort }))}
      >
        {getSortMethodText()} <FontAwesomeIcon icon={faSortAmountDown} />
      </button>
      {dropdownOpen.sort && (
        <div className="absolute right-0 top-7 z-20 mt-1 w-auto rounded border border-gray-300 bg-white text-[15px] shadow-lg md:top-9">
          <div className="cursor-pointer p-2 hover:bg-gray-100" onClick={() => handleSortSelection("lowToHigh")}>
            Rent: Low to High
          </div>
          <div className="cursor-pointer p-2 hover:bg-gray-100" onClick={() => handleSortSelection("highToLow")}>
            Rent: High to Low
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
