import React, { useState, useEffect, useRef } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dropdown from "../components/dropdown";
import ListingCard from "../components/listingCard";

const Listings = () => {
  const [listings, setListings] = useState([]);
  const [users, setUsers] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState({ search: false, sort: false });
  const [activeTab, setActiveTab] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [locationInput, setLocationInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("Anywhere");
  const [sortMethod, setSortMethod] = useState("");
  const [startFlexibility, setStartFlexibility] = useState(2);
  const [endFlexibility, setEndFlexibility] = useState(2);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const locations = [
    "Anywhere", "Antrim", "Armagh", "Carlow", "Cavan", "Clare", "Cork", "Derry", "Donegal", "Down", "Dublin", "Fermanagh",
    "Galway", "Kerry", "Kildare", "Kilkenny", "Laois", "Leitrim", "Limerick", "Longford", "Louth", "Mayo", "Meath",
    "Monaghan", "Offaly", "Roscommon", "Sligo", "Tipperary", "Tyrone", "Waterford", "Westmeath", "Wexford", "Wicklow"
  ];

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Service",
    serviceType: "Room Subletting",
    name: "Lettz",
    url: "https://lettz.ie",
    description: "Discover an easy way to earn money by subletting your room. Find trusted tenants quickly and securely.",
    logo: "http://lettz.ie/Logo.png",
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const querySnapshot = await getDocs(collection(db, "Listings"));
      const fetchedListings = await Promise.all(querySnapshot.docs.map(async (listingDoc) => {
        const data = listingDoc.data();
        if (data.listerId && !users[data.listerId]) {
          const userDocSnap = await getDoc(doc(db, "users", data.listerId));
          if (userDocSnap.exists()) {
            setUsers((prevUsers) => ({ ...prevUsers, [data.listerId]: userDocSnap.data() }));
          }
        }
        return { id: listingDoc.id, ...data };
      }));

      let sortedListings = fetchedListings;
      if (sortMethod) {
        sortedListings = [...fetchedListings].sort((a, b) => (sortMethod === "lowToHigh" ? a.rent - b.rent : b.rent - a.rent));
      }

      if (selectedLocation !== "Anywhere") {
        sortedListings = sortedListings.filter((listing) => listing.county === selectedLocation);
      }

      if (startDate || endDate) {
        const adjustDate = (date, weeks) => {
          const adjustedDate = new Date(date);
          adjustedDate.setDate(adjustedDate.getDate() + weeks * 7);
          return adjustedDate;
        };

        const adjustedStartLower = startDate ? adjustDate(startDate, -startFlexibility) : null;
        const adjustedStartUpper = startDate ? adjustDate(startDate, startFlexibility) : null;
        const adjustedEndLower = endDate ? adjustDate(endDate, -endFlexibility) : null;
        const adjustedEndUpper = endDate ? adjustDate(endDate, endFlexibility) : null;

        sortedListings = sortedListings.filter(({ startDate, endDate }) => {
          const listingStartDate = new Date(startDate);
          const listingEndDate = new Date(endDate);

          const startCondition = adjustedStartLower && adjustedStartUpper
            ? listingStartDate >= adjustedStartLower && listingStartDate <= adjustedStartUpper
            : true;
          const endCondition = adjustedEndLower && adjustedEndUpper
            ? listingEndDate >= adjustedEndLower && listingEndDate <= adjustedEndUpper
            : true;

          return startCondition && endCondition;
        });
      }

      setListings(sortedListings);
      setIsLoading(false);
    };

    fetchData();
  }, [selectedLocation, sortMethod, startDate, endDate, startFlexibility, endFlexibility]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen({ search: false, sort: false });
        setActiveTab("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
    setStartFlexibility(2);
    setEndFlexibility(2);
  };

  const closeDropdown = () => {
    setDropdownOpen({ search: false, sort: false });
    setActiveTab("");
  };

  const handleTabChange = (tab) => {
    if (activeTab === tab) {
      closeDropdown();
    } else {
      setActiveTab(tab);
      setDropdownOpen((prev) => ({ ...prev, search: true }));
    }
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    closeDropdown();
  };

  const handleSortSelection = (method) => {
    setSortMethod(method);
    setDropdownOpen((prev) => ({ ...prev, sort: false }));
  };

  const handleDateChange = (setDate, date) => {
    setDate(date);
    if (setDate === setStartDate && (!endDate || date > endDate)) {
      setEndDate(date);
    }
    if (setDate === setEndDate && (!startDate || date < startDate)) {
      setStartDate(date);
    }
  };

  const handleCardClick = (listingId) => navigate(`/details/${listingId}`);

  const filteredOptions = locationInput
    ? locations.filter((loc) => loc.toLowerCase().includes(locationInput.toLowerCase()))
    : locations;

  const formatDateRange = (startStr, endStr) => {
    const options = { month: "short", day: "numeric" };
    const startDate = startStr ? new Date(startStr) : null;
    const endDate = endStr ? new Date(endStr) : null;

    return startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())
      ? `${startDate.toLocaleDateString("en-US", options)} - ${endDate.toLocaleDateString("en-US", options)}`
      : "Any Dates";
  };

  const getSortMethodText = () => {
    switch (sortMethod) {
      case "lowToHigh":
        return "Low to High";
      case "highToLow":
        return "High to Low";
      default:
        return "Sort";
    }
  };

  return (
    <div>
      <Helmet>
        <title>Lettz | Student Subletting</title>
        <meta
          name="description"
          content="Discover Lettz, the exclusive platform for student subletting in Ireland! Post your room to connect with students looking for rooms, or find affordable rooms offered by other students."
        />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>

      <Dropdown
        dropdownRef={dropdownRef}
        dropdownOpen={dropdownOpen}
        activeTab={activeTab}
        startDate={startDate}
        endDate={endDate}
        startFlexibility={startFlexibility}
        endFlexibility={endFlexibility}
        setDropdownOpen={setDropdownOpen}
        setStartDate={setStartDate}
        setStartFlexibility={setStartFlexibility}
        setEndDate={setEndDate}
        setEndFlexibility={setEndFlexibility}
        locationInput={locationInput}
        setLocationInput={setLocationInput}
        selectedLocation={selectedLocation}
        filteredOptions={filteredOptions}
        formatDateRange={formatDateRange}
        handleTabChange={handleTabChange}
        handleLocationSelect={handleLocationSelect}
        handleDateChange={handleDateChange}
        resetDates={resetDates}
        closeDropdown={closeDropdown}
        getSortMethodText={getSortMethodText}
        handleSortSelection={handleSortSelection}
      />

      <div className="relative mx-auto mb-1 mt-4 flex w-[88%] items-center justify-between text-sm md:mt-6 md:text-[15px]">
        <p>Showing <strong>{listings.length}</strong> results for: <strong>{selectedLocation}</strong></p>
      </div>

      {isLoading ? (
        <div className="py-6 text-center text-2xl font-semibold text-gray-700">Loading...</div>
      ) : listings.length === 0 ? (
        <div className="mt-24 text-center text-lg">
          <p>No rooms have been posted yet.</p>
          <p>Lettz is a brand new website so sign up and</p>
          <p>be the first to post a room!</p>
        </div>
      ) : (
        <div className="mx-auto mb-24 grid w-[90%] grid-cols-1 gap-6 md:grid-cols-2 2xl:grid-cols-3">
          {listings.map((listing) => (
            <ListingCard
              key={listing.id}
              listing={listing}
              users={users}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Listings;
