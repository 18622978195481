import React from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase-config";

export default function ListCustomers() {
  const listStripeCustomers = () => {
    const listCustomersFunction = httpsCallable(
      functions,
      "listStripeCustomers",
    );

    listCustomersFunction()
      .then((result) => {
        console.log("Stripe Customers:", result.data.customers);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return <button onClick={listStripeCustomers}>List Stripe Customers</button>;
}
